import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect, Switch } from 'react-router-dom';
import * as Auth from './components/auth';
import * as User from './components/user';
import * as Ecommerce from './components/ecommerce/';
import * as Marketing from './components/marketing/';
import * as Community from './components/community/';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { token } = useSelector(state => ({
    // logged_in: state.user?.profile?.logged_in,
    token: state.auth.token,
  }));
  return (
    <Route {...rest}
      render={(props) => (
        // logged_in === true && token !== null
        token !== null
          ? <Component {...props} />
          : <Redirect to={{ pathname: '/auth/login', state: { from: props.location } }} />
      )}
    />
  );
};


const BaseRouter = () => (
  <>
    <Switch>
      <Route exact path="/preorder" component={Ecommerce.EcommercePreoderView} />
      <Route exact path="/rent" component={Ecommerce.EcommerceRentalSingleView} />
      <Route exact path="/products" component={Ecommerce.EcommerceProductListView} />
      <Route exact path="/shop" component={Ecommerce.EcommerceShopView} />
      <Route exact path="/" component={Ecommerce.EcommerceShopView} />

      <Route exact path="/p/:product_slug" component={Ecommerce.EcommerceProductView} />
      <Route exact path="/checkout/basket" component={Ecommerce.EcommerceBasketView} />
      <Route exact path="/checkout/user" component={Ecommerce.EcommerceUserView} />
      <Route exact path="/checkout/payment" component={Ecommerce.EcommercePaymentView} />
      <Route exact path="/checkout/success" component={Ecommerce.EcommerceSuccessView} />
      <Route exact path="/checkout/failure" component={Ecommerce.EcommerceFailureView} />

      <Route exact path="/survey/:slug" component={Marketing.MarketingSurveyView} />
      <Route exact path="/crowdfunding" component={Marketing.MarketingCrowdfundingView} />
      <Route exact path="/community/dealership" component={Community.CommunityDealershipView} />

      <Route exact path="/products" component={Ecommerce.EcommerceShopView} />

      <Route exact path="/auth/verify-email/:key" component={Auth.AuthVerifyView} />
      <Route exact path="/auth/invitation/:hash/:key" component={Auth.AuthInvitationAcceptView} />
      <Route exact path="/auth/login" component={Auth.AuthLoginView} />
      <Route exact path="/auth" component={Auth.AuthView} />

      <PrivateRoute exact path="/user/purchase-agreement" component={User.UserPurchaseAgreementView} />
      <PrivateRoute exact path="/user" component={User.UserPurchaseAgreementView} />
    </Switch>
  </>
);

export default BaseRouter;
