import React from 'react';
import { connect } from 'react-redux';
import { authActions } from '../redux/';
import { appActions } from '../../app/redux';
import {
  Button,
  ErrorText,
  ZWrapper,
  ZTitle,
  TextField,
} from '../../../ui-components';
import {
  BeTritonAPI,
} from '../../../utils';
import styled from "styled-components";


// const Description = styled.div`
//   font-size: 14px;
//   line-height: 19px;
//   color: ${({ theme }) => theme.text.secondary};
//   text-align: left;
//   padding: 0 4px;
// `;

const Padding = styled.div`
  /* padding: 8px; */
`;

const REDIRECT_TO = "/auth";

class AuthLoginContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  login = async () => {
    this.props.appRequest({
      domain: "login",
      loading: true,
      error: null,
    })

    try {
      const res = await BeTritonAPI.post(`v1/auth/login/`, {
        email: this.state.email,
        password: this.state.password
      }, {
        headers: {
          'Content-Type': 'application/json',
        }
      })

      this.props.appRequestClear({ domain: "login" });
      this.props.setToken(res.data.key);

      window.location.href = REDIRECT_TO;

    } catch (err) {
      this.props.appRequest({
        domain: "login",
        loading: false,
        error: err,
      })
    }
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    })
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.login();
  }

  render() {
    const error = this.props.error || {}
    return (
      <ZWrapper>
        <Padding>
          <ZTitle>Log In</ZTitle>
          <form
            noValidate
            onSubmit={this.handleSubmit}
            style={{ minWidth: "320px" }}
            onError={errors => console.log(errors)}>



            {error[`non_field_error`] ? <ErrorText>{error[`non_field_error`]}</ErrorText> : null}
            {error[`non_field_errors`] ? <ErrorText>{error[`non_field_errors`]}</ErrorText> : null}

            <TextField
              type="email"
              id="email"
              label="Email"
              name="email"
              hasError={error['email']}
              value={this.state.email}
              onChange={this.handleChange}
              required
            />

            <TextField
              type="password"
              id="password"
              label="Password"
              name="password"
              hasError={error['password']}
              value={this.state.password}
              onChange={this.handleChange}
              required
            />

            {/* <Paragraph style={{ textAlign: "left", margin: "8px 0" }}>
              <SimpleLink to="/auth/recovery">Forgot password?</SimpleLink>
            </Paragraph> */}

            <Button
              color="secondary"
              type="button"
              onClick={this.handleSubmit}
              size="large"
              disabled={this.props.loading}
              fullwidth
            >
              Log in
            </Button>

          </form>
        </Padding>
      </ZWrapper>
    )
  }
}

const mapStateToProps = state => ({
  error: state.app.error?.login || null,
  loading: state.app.loading?.login || false,
})

const mapDispatchToProps = (dispatch) => ({
  setToken: (token) => dispatch(authActions.setToken(token)),
  appRequest: (request) => dispatch(appActions.request(request)),
  appRequestClear: (request) => dispatch(appActions.requestClear(request)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AuthLoginContainer);
