import styled, { css } from "styled-components";
import * as TableStyle from '../../../../ui-components/table/table';


export const ProductLink = styled.button`
  border: 0;
  background: transparent;
  margin: 0;
  padding: 0;

  color: ${({ theme }) => theme.primary.main};
  

  &:focus{
    outline: none;
  }
`

export const Table = styled(TableStyle.Table)``;
export const TableHead = styled(TableStyle.Head)``;
export const TableRow = styled(TableStyle.Row)``;
export const TableFooter = styled(TableStyle.Footer)``;
export const TableCol = styled(TableStyle.Col)`
  ${props => props.col_item && css`

  `}

  ${props => props.col_empty && css`
    font-style: italic;
    color: ${({ theme }) => theme.text.secondary};
  `}

  ${props => props.col_price && css`
    width: 132px;
  `}

  ${props => props.col_quantity && css`
    width: 90px;
  `}

  ${props => props.col_total && css`
    width: 120px;
  `}

  ${props => props.col_action && css`
    width: 32px;
  `}
`;


export const QuantityInput = styled.div`
  padding: 4px 12px;
`;

export const Quantity = styled.div`
  display: flex;
  align-items: center;
  margin: 0 4px;
  justify-content: center;

`;

export const SummaryBlock = styled.div`
  width: 272px;
  float: right;
  margin-bottom: 24px;
`

export const Clear = styled.div`
  clear: both;
`
