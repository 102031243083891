import userActionType from './user.types';
import { appActions } from '../../app/redux';
import {
  BeTritonAPI,
} from '../../../utils';



const getUserData = () => {
  return async (dispatch, getStore) => {
    const { token } = getStore().auth;

    dispatch(appActions.request({
      domain: "user",
      loading: true,
      error: null,
    }));

    try {
      const res = await BeTritonAPI.get('v1/user/', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${token}`,
        }
      })

      console.log(res.data)
      dispatch({
        type: userActionType.PROFILE,
        user: res.data,
      })
      localStorage.setItem('user', JSON.stringify(res.data));

      dispatch(appActions.requestClear({
        domain: "user",
      }));

    } catch (err) {
      dispatch(appActions.request({
        domain: "user",
        loading: false,
        error: err,
      }));
    }
  };
};


const updateUserData = (payload) => {
  return async (dispatch, getStore) => {
    const { token } = getStore().auth;
    dispatch(appActions.request({
      domain: "user",
      loading: true,
      error: null,
    }));

    try {
      const res = await BeTritonAPI.put('v1/user/',
        { ...payload },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${token}`,
          }
        })

      dispatch({
        type: userActionType.PROFILE,
        user: res.data,
      })

      dispatch(appActions.requestClear({
        domain: "user",
      }));

    } catch (err) {
      dispatch(appActions.request({
        domain: "user",
        loading: false,
        error: err,
      }));
    }
  };
};

const clearUserData = () => ({
  type: userActionType.PROFILE_CLEAR
});

export const userActions = {
  get: getUserData,
  update: updateUserData,
  clear: clearUserData,
}

export default userActions;