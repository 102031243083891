import React from 'react';
import { useSelector } from 'react-redux';
import {
  Grid,
  Title,
  // Label,
  Description,
  // CallToAction,
  PriceTag,
  // MetaTable,
} from './product.styled';
import ReactMarkdown from 'react-markdown'
import ProductGallery from './product-gallery';
import {
  AddToBasket,
  BasketSnippet,
} from '../basket';
import {
  history,
} from '../../../../utils';


export const Product = ({ id, slug, name, price, currency, image_primary, image_gallery, description, meta }) => {
  const { items } = useSelector(state => ({
    items: state.ecommerce.items || [],
  }));

  const gallery = image_primary ? [image_primary, ...image_gallery || []] : [];

  const handleSubmit = () => {
    if (items.length > 0) { // because handle submit is triggered before item is successfuly added to the basket
      history.push('/checkout/basket');
    } else {
      history.push('/checkout/user');
    }
  }
  const basketItem = items.find(el => el.price_id === id)

  return (
    <Grid product_container>
      <Grid gallery>
        <ProductGallery
          name={name}
          images={gallery}
          thumbnails="bottom"
        />
      </Grid>

      <Grid text>

        <Title to={`/p/${slug}` || '/'}>{name}</Title>
        <PriceTag container>
          <PriceTag text>Price:</PriceTag>
          <PriceTag price>{price} {currency}</PriceTag>
        </PriceTag>

        <AddToBasket
          id={id}
          defaultQuantity={basketItem ? basketItem.quantity : 1}
          orderItemId={basketItem ? basketItem.order_item_id : null}
          callback={handleSubmit}
          nomargin={true}
        >
          {basketItem ? "Change quantity" : "Add to Basket"}
        </AddToBasket>
        <BasketSnippet id={id} />

      </Grid>

      <Grid description>
        <Description>
          <ReactMarkdown linkTarget="_blank">
            {description}
          </ReactMarkdown>
        </Description>
      </Grid>
    </Grid>
  )
}

export default Product;
