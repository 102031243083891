import React from 'react';
import styled from "styled-components";
import Amex from "../../../../assets/cards/amex.png";
import Discover from "../../../../assets/cards/discover.png";
import Maestro from "../../../../assets/cards/maestro.png";
import MasterCard from "../../../../assets/cards/mastercard.png";
import Visa from "../../../../assets/cards/visa.png";
import VisaElectron from "../../../../assets/cards/visaelectron.png";
import SecureStripe from "../../../../assets/img/stripe-black.svg";


const Container = styled.div`
  margin-top: -16px;
  text-align: right;
  margin-bottom: 32px;
`;

const Card = styled.img`
  height: 26px;
  margin-right: 2px;
`;

const Stripe = styled.img`
  height: 26px;
  margin-right: 2px;
`;

const TrustElements = () => {

  const cardList = [
    { src: Amex, name: 'Amex', },
    { src: Discover, name: 'Discover', },
    { src: Maestro, name: 'Maestro', },
    { src: MasterCard, name: 'MasterCard', },
    { src: Visa, name: 'Visa', },
    { src: VisaElectron, name: 'VisaElectron', },
  ];

  return (
    <Container>{cardList.map((card, index) => (
      <Card
        src={card.src}
        alt={card.name}
        key={index}
      />
    ))}
      <Stripe
        src={SecureStripe}
        alt="Secure Stripe"
      />
    </Container>
  );
};

export default TrustElements;
