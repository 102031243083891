import React, { useState } from 'react';
import {
  Form,
  Footer,
} from '../checkout-wrapper/checkout-wrapper';
import {
  Button,
} from '../../../../ui-components';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import 'regenerator-runtime/runtime';
import styled, { useTheme } from 'styled-components';


// https://linguinecode.com/post/integrate-stripe-payment-form-with-react



const CARD_ERRORS = ['incomplete_number', 'invalid_number'];
const DATE_ERRORS = ['invalid_expiry_year_past', 'incomplete_expiry'];
const CCV_ERRORS = ['incomplete_cvc'];


const FormGroup = styled.div`
  position: relative;
  padding-top: 25px;
  padding-bottom: 10px;
  margin: 8px 0;
`;

const Input = styled.div`
  background: ${({ theme }) => theme.shades.light};
  padding: 8px;
  border-bottom: 1px dashed ${({ theme }) => theme.shades.line};
  border-color: ${({ theme, error }) => error ? theme.error.light : theme.shades.line};  
`;

const Label = styled.label`
  position: absolute;
  top: 0;
  display: block;
  color: ${({ theme }) => theme.text.secondary};
  transition: 0.2s;
  font-size: 13px;

  span{
    color: ${({ theme }) => theme.error.main};
  }
`;

const Error = styled.div`
  margin: 8px auto -8px;
  font-size: 12px;
  color: ${({ theme }) => theme.error.main};
`



export const CheckoutForm = ({ children, clientSecret, billingDetails, onCancel, onSuccess, onError }) => {
  const [stripeProcess, setStripeProcess] = useState(false);
  const [error, setError] = useState({
    code: '',
    message: '',
  })
  const stripe = useStripe();
  const elements = useElements();
  const theme = useTheme();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setStripeProcess(true);

    const result = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardNumberElement),
        billing_details: { ...billingDetails },
      }
    });

    if (result.error) {
      // Show error to your customer (e.g., insufficient funds)

      if (!CARD_ERRORS.includes(result.error.code)
        && !DATE_ERRORS.includes(result.error.code)
        && !CCV_ERRORS.includes(result.error.code)) {
        onError(result.error);

      }

      setError((state) => ({
        ...state,
        code: result.error.code,
        message: result.error.message,
      }))

      setStripeProcess(false);
      // console.log(result.error.message);
    } else {
      setStripeProcess(false);
      // The payment has been processed!
      if (result.paymentIntent.status === 'succeeded') {

        onSuccess();

        // console.log(result.paymentIntent)
        // dispatch(ecommerceActions.form.next());


        // Show a success message to your customer
        // There's a risk of the customer closing the window before callback
        // execution. Set up a webhook or plugin to listen for the
        // payment_intent.succeeded event that handles any business critical
        // post-payment actions.
      }
    }
  }

  const buttonText = stripeProcess ? 'PROCESSING...' : 'CONTINUE';
  const CARD_OPTIONS = {
    style: {
      base: {
        color: theme.text.primary,
        backgroundColor: theme.shades.light,
        fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
        fontSize: '16px',
        fontSmoothing: 'antialiased',
        ':-webkit-autofill': { color: `rgba(0, 0, 0, 0.87)` },
        '::placeholder': { color: `rgba(0, 0, 0, 0.38)` },
      },
      invalid: {
        color: `#f44336`,
      },
    },
  };


  const cardError = CARD_ERRORS.includes(error.code);
  const dateError = DATE_ERRORS.includes(error.code);
  const ccvError = CCV_ERRORS.includes(error.code);

  return (
    <form onSubmit={handleSubmit}>

      <Form container>
        <FormGroup>
          <Label>Credit Card Number <span>*</span></Label>
          <Input error={cardError}>
            <CardNumberElement options={CARD_OPTIONS} />
          </Input>
          {cardError ? <Error>{error.message}</Error> : null}
        </FormGroup>

        <Form row>
          <Form col>
            <FormGroup>
              <Label>Expiration Date <span>*</span></Label>
              <Input error={dateError}>
                <CardExpiryElement options={CARD_OPTIONS} />
              </Input>
              {dateError ? <Error>{error.message}</Error> : null}
            </FormGroup>
          </Form>

          <Form col>
            <FormGroup>
              <Label>CVC <span>*</span></Label>
              <Input error={ccvError}>
                <CardCvcElement options={CARD_OPTIONS} />
              </Input>
              {ccvError ? <Error>{error.message}</Error> : null}
            </FormGroup>
          </Form>

        </Form>
      </Form>

      {children}

      <Footer container>
        <Footer left>
          <Button
            type="button"
            size="large"
            color="white"
            onClick={onCancel}
            inline
          >
            Back
          </Button>

        </Footer>
        <Footer right>
          <Button
            type="button"
            size="large"
            color="secondary"
            onClick={handleSubmit}
            disabled={!stripe || stripeProcess}
            inline
          >
            {buttonText}
          </Button>
        </Footer>
      </Footer>
    </form>

  )
}

export default CheckoutForm;
