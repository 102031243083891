import authActionType from './auth.types';
import { appActions } from '../../app/redux';
import {
  BeTritonAPI,
  validateError,
  history,
} from '../../../utils';


const authSetToken = (token) => ({
  type: authActionType.SET_TOKEN,
  token,
})


const clearToken = () => ({ type: authActionType.CLEAR_TOKEN })

const authLogout = () => {
  return async (dispatch, getStore) => {
    const { token } = getStore().auth;

    dispatch(appActions.request({
      domain: "auth",
      loading: true,
      error: null,
    }));

    try {
      await BeTritonAPI.post('v1/auth/logout/', {

      }, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${token}`,
        }
      })


      dispatch(clearToken());
      dispatch(appActions.requestClear({
        domain: "auth",
      }));

      history.push('/auth');

    } catch (err) {
      dispatch(appActions.request({
        domain: "auth",
        loading: false,
        error: err,
      }));
    }
  };
};


const verifyEmail = (key) => {
  return async (dispatch, getStore) => {
    // const { token } = getStore().auth;

    dispatch(appActions.request({
      domain: "auth",
      loading: true,
      error: null,
    }));

    try {
      const res = await BeTritonAPI.post(`v1/marketing/email/verify/${key}/`, {
        key,
      }, {
        headers: {
          'Content-Type': 'application/json',
          // Authorization: `Token ${token}`,
        }
      })

      

      dispatch({ type: authActionType.EMAIL_VERIFIED, email: res.data.email })
      dispatch(appActions.requestClear({
        domain: "auth",
      }));


    } catch (err) {
      dispatch(appActions.request({
        domain: "auth",
        loading: false,
        error: err,
      }));
    }
  };
};

const passwordChange = (password_old, password1, password2) => {
  const request = () => ({ type: authActionType.REQUEST })
  const passwordChanged = () => ({ type: authActionType.EMAIL_RECOVERY_CHANGE })
  const failure = (error) => ({ type: authActionType.FAILURE, error: error })

  return dispatch => {
    const token = localStorage.getItem('token');
    dispatch(request());
    BeTritonAPI.post(`rest-auth/password/change/`, {
      new_password1: password1,
      new_password2: password2,
      old_password: password_old,
    }, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      }
    })
      .then(res => {
        dispatch(passwordChanged())
      })
      .catch(err => {
        const response = validateError(`passwordError`, err);
        dispatch(failure(response));
      })

  }
}





export const authActions = {
  setToken: authSetToken,
  clearToken, 
  logout: authLogout,

  verifyEmail,
  passwordChange,
}

export default authActions;
