import React from 'react';
import { connect } from 'react-redux';
import { appActions } from '../../app/redux';
import { ecommerceActions } from '../redux';
import {
  CheckoutWrapper,
  Title,
  Footer,
} from '../components/checkout-wrapper/checkout-wrapper';
import {
  ProductLink,
  Table,
  TableHead,
  TableRow,
  TableCol,
  Quantity,
  QuantityInput,
  SummaryBlock,
  Clear,
} from '../components/basket/basket.styled';
import {
  Divider,
  Entry,
  // Meta,
} from '../components/summary/summary.styled';

import {
  Button,
} from "../../../ui-components";
import {
  // BeTritonAPI,
  history,
} from '../../../utils';


const parseMeta = (meta) => {
  let text = "";


  if (meta?.rental) {
    if (meta.rental?.date_till === "") {
      text = ` (${meta.rental.date_from})`;
    } else {
      text = ` (${meta.rental.date_from} - ${meta.rental.date_till})`;
    }
  }

  return text;
}

class EcommerceBasketContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
    };
  }

  componentDidMount = () => {
    // extra reload for the basket
    this.props.getOrder(this.props.orderUUID);
  }

  componentWillUnmount = () => {
  }

  componentDidUpdate = (prevProps) => {

  }

  handleNext = (e) => {
    history.push("/checkout/user");
    window.scroll({ top: 0 });
  }

  render() {
    return (
      <CheckoutWrapper
        error={this.props.error}
        loading={this.props.loading}
      >
        <Title>Basket</Title>

        <Table>
          <TableHead>
            <TableCol col_item>Item</TableCol>
            <TableCol col_price align="center">Price</TableCol>
            <TableCol col_quantity align="center">Quanity</TableCol>
            <TableCol col_total align="center">Total</TableCol>
            <TableCol col_action>&nbsp;</TableCol>
          </TableHead>
          {this.props.items.map((item, index) => (
            <TableRow key={index}>
              <TableCol col_item>
                <ProductLink
                  type="button"
                  onClick={() => { this.props.openProduct(item.slug) }}
                >
                  {item.name}

                  {parseMeta(item?.meta)}



                </ProductLink>

              </TableCol>
              <TableCol col_price align="center">
                {item.price} {item.currency}
                {item.nature === "rental" ? ` / ${item.unit}` : null}
              </TableCol>
              <TableCol col_quantity align="center" nomargin>
                <Quantity>

                  {item.nature !== "rental"
                    ? <Button
                      type="button"
                      size="icon_small"
                      icon="minus-outline"
                      color="white"
                      onClick={() => { this.props.updateOrderItem(item.order_item_id, item.price_id, item.quantity - 1) }}
                      inline
                      nomargin
                    />
                    : null}
                  <QuantityInput>{item.quantity}</QuantityInput>
                  {item.nature !== "rental"
                    ? <Button
                      type="button"
                      size="icon_small"
                      icon="plus-outline"
                      color="white"
                      onClick={() => { this.props.updateOrderItem(item.order_item_id, item.price_id, item.quantity + 1) }}
                      inline
                      nomargin
                    />
                    : null}
                </Quantity>
              </TableCol>
              <TableCol col_total align="center">{item.total} {item.currency}</TableCol>
              <TableCol col_action align="center" nomargin>
                <Button
                  type="button"
                  size="icon_small"
                  icon="trash"
                  color="white"
                  onClick={() => { this.props.updateOrderItem(item.order_item_id, item.price_id, 0) }}
                  inline
                  nomargin
                />

              </TableCol>
            </TableRow>
          ))}
          {this.props.items.length === 0
            ? (
              <TableRow style>
                <TableCol col_empty>Your basket is empty!</TableCol>
                <TableCol />
                <TableCol />
                <TableCol />
                <TableCol />
              </TableRow>
            )
            : null
          }
        </Table>
        <SummaryBlock>
          <Divider />

          <Entry row>
            <Entry left>
              Subtotal
            </Entry>
            <Entry right>
              {this.props.total_price}
            </Entry>
          </Entry>
          <Entry row>
            <Entry left>
              Shipping
            </Entry>
            <Entry right>
              --
            </Entry>
          </Entry>
          <Divider />

          <Entry row styled>
            <Entry left>
              Total
            </Entry>
            <Entry right>
              {this.props.total_price}
            </Entry>
          </Entry>

          {/* <Divider /> */}
        </SummaryBlock>

        <Clear />
        {this.props.items.length > 0
          ? (
            <Footer container>
              <Footer left />
              <Footer right>
                <Button
                  type="button"
                  size="large"
                  color="secondary"
                  onClick={this.handleNext}
                  inline
                >
                  Continue
                </Button></Footer>
            </Footer>
          ) : null}
      </CheckoutWrapper>
    )
  }
}

const mapStateToProps = (state) => ({
  error: state.app.error?.ecommerce || null,
  loading: state.app.loading?.ecommerce || false,
  orderUUID: state.ecommerce.uuid,
  total_price: state.ecommerce.total_price,
  total_items: state.ecommerce.total_items,
  currency: state.ecommerce.currency,
  items: state.ecommerce.items,
})

const mapDispatchToProps = (dispatch) => ({
  appRequest: (request) => dispatch(appActions.request(request)),
  appRequestClear: (request) => dispatch(appActions.requestClear(request)),
  getOrder: (uuid) => dispatch(ecommerceActions.order.get(uuid)),
  updateOrderItem: (id, price_id, quantity) => dispatch(ecommerceActions.item.changeItem(id, price_id, quantity)),
  openProduct: (product_slug) => dispatch(appActions.popup.show('product', { product_slug, size: 'large' }, true))
})

export default connect(mapStateToProps, mapDispatchToProps)(EcommerceBasketContainer);
