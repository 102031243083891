import React from 'react';
import { connect } from 'react-redux';
import { appActions } from '../../app/redux';
import { authActions } from '../redux';
import {
  BeTritonAPI
} from '../../../utils';
import styled from "styled-components";

import {
  Button,
  ErrorText,
  ZWrapper,
  ZTitle,
  TextField,
} from "../../../ui-components";


const Description = styled.div`
  font-size: 14px;
  line-height: 19px;
  color: ${({ theme }) => theme.text.secondary};
  text-align: left;
  padding: 0 4px;
`;

const Padding = styled.div`
  /* padding: 8px; */
`;


const REDIRECT_TO = "/auth";
// const REDIRECT_TO = "/user/purchase-agreement"

class AuthInvitationAcceptContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      key: "",
      hash: "",
      password: "",
      password_2: "",
      password_changed: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount = () => {
    const { key, hash } = this.props.match.params
    this.setState({
      key,
      hash,
    })
  }

  handleChange = (event) => {
    const name = event.target.name
    const value = event.target.value

    this.setState({
      ...this.state,
      [name]: value
    })
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    this.props.appRequest({
      domain: "auth",
      loading: true,
      error: null,
    })

    try {
      const res = await BeTritonAPI.post(`v1/auth/recovery/${this.state.hash}/${this.state.key}/`, {
        password: this.state.password,
        password_2: this.state.password_2,
      }, {
        headers: {
          'Content-Type': 'application/json',
        }
      })

      this.props.setToken(res.data.token)
      this.props.appRequestClear({ domain: "auth" })
      this.setState({
        password_changed: true
      })
      window.location.href = REDIRECT_TO;


    } catch (err) {
      this.props.appRequest({
        domain: "auth",
        loading: false,
        error: err,
      })
    }
  }

  render() {
    const error = this.props.error || {};



    if (this.state.password_changed) {
      return (
        <ZWrapper>
          <Padding>
            <ZTitle>Success</ZTitle>
            <Description>Your account has been created</Description>
          </Padding>
        </ZWrapper>
      )

    }

    if (this.state.key === "" || this.state.hash === "") {
      return (
        <ZWrapper>
          <Padding>
            <ZTitle>Wrong URL...</ZTitle>
          </Padding>
        </ZWrapper>
      )

    }

    return (
      <ZWrapper>
        <Padding>
          <ZTitle>Account creation</ZTitle>
          {error[`detail`] ? <ErrorText>{error[`detail`]}</ErrorText> : null}

          <Description>Password must be at least 8 characters long contain a number and a special character.</Description>

          {error[`non_field_error`] ? <ErrorText>{error[`non_field_error`]}</ErrorText> : null}
          {error[`non_field_errors`] ? <ErrorText>{error[`non_field_errors`]}</ErrorText> : null}
          {error[`token`] ? <ErrorText>{error[`token`]}</ErrorText> : null}

          <form onSubmit={this.handleSubmit}>
            <TextField
              type="password"
              name="password"
              label="Password"
              hasError={error[`password`]}
              value={this.state.password}
              onChange={this.handleChange} />

            <TextField
              type="password"
              name="password_2"
              label="Confirm password"
              hasError={error[`password_2`]}
              value={this.state.password_2}
              onChange={this.handleChange} />

            <Button
              type="submit"
              color="secondary"
              size="normal"
              disabled={this.props.loading}
            >
              Create Account
            </Button>
          </form>

        </Padding>
      </ZWrapper>
    )
  }
}

const mapStateToProps = state => ({
  error: state.app.error?.auth,
  loading: state.app.loading?.auth,
  email: state.auth.email,
})

const mapDispatchToProps = dispatch => ({
  appRequest: (request) => dispatch(appActions.request(request)),
  appRequestClear: (request) => dispatch(appActions.requestClear(request)),
  verifyEmail: (key) => dispatch(authActions.verifyEmail(key)),
  setToken: (token) => dispatch(authActions.setToken(token)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AuthInvitationAcceptContainer);
