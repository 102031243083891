import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ecommerceActions } from '../../redux';
import {
  Container,
  BasketButton,
  Quantity,
  QuantityLabel,
  QuantityInput,
} from './add-to-basket.styled';
import {
  Button,
} from '../../../../ui-components';


export const AddToBasket = ({ id, meta, nomargin, children, orderItemId, defaultQuantity = 1, oneItem = false, callback = () => { } }) => {
  const [quantity, setQuantity] = useState(defaultQuantity);
  const { loading } = useSelector(state => ({
    loading: state.app.loading?.ecommerce || false,
  }));
  const dispatch = useDispatch();


  useEffect(() => {
    setQuantity(defaultQuantity)
  }, [defaultQuantity])

  const handleQuantityChange = (event) => {
    const { value } = event.target;
    setQuantity(value.replace(/[^\d]/g, ""))
  }


  const handleChange = async (_quantity) => {
    await dispatch(ecommerceActions.item.changeItem(orderItemId, id, _quantity, JSON.stringify(meta)))
    // console.log("change")
  }


  const handleAdd = async (_quantity) => {
    await dispatch(ecommerceActions.item.addItem(id, _quantity, JSON.stringify(meta)))
    setQuantity(1); // resets the amount
    // console.log("add")
  }

  const handleSubmit = async () => {
    const newQuantity = quantity < 1 ? 1 : quantity;
    if (orderItemId) {
      await handleChange(newQuantity)
    } else {
      await handleAdd(newQuantity)
    }
    callback();
  }


  return (
    <Container nomargin={nomargin}>
      <BasketButton >
        {!oneItem
          ? <Quantity>
            <QuantityLabel>Quantity</QuantityLabel>
            <QuantityInput
              type="text"
              value={quantity}
              onChange={handleQuantityChange}
              onBlur={() => {
                if (quantity === "" || quantity < 1) {
                  setQuantity(1)
                }
              }}
            />
          </Quantity>
          : null}

        <Button
          type="button"
          size="large"
          color="secondary"
          nomargin
          onClick={handleSubmit}
          disabled={loading}
        >
          {children}
        </Button>
      </BasketButton>
    </Container>
  )
}

export default AddToBasket;
