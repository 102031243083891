import React from 'react';
import { useSelector } from "react-redux";
import styled, { css } from "styled-components";


const Shadow = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  min-height: 100vh;
  overflow-y: scroll;
  /* background: rgba(255, 255, 255, 0.7); */
  background: rgba(0, 0, 0, 0.2);
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  z-index: 10000;
  /* backdrop-filter: blur(2px); */
`;

const ShadowAnchor = styled.div`
  margin-top: 42px;
  left: 50%;
  /* margin-left: -884px; */

  ${props => props.x && props.y && props.w && props.h && css`
    position: absolute;
    margin: 0;
    top: ${props.y}px;
    left: ${props.x}px;
    width: ${props.w}px;
    height: ${props.h}px;
  `}

  ${props => props.size === "normal" && css`
    width: ${({ theme }) => theme.popup.normal}px;
  `}

  ${props => props.size === "medium" && css`
    width: ${({ theme }) => theme.popup.medium}px;
  `}

  ${props => props.size === "large" && css`
    width: ${({ theme }) => theme.popup.large}px;
  `}

  ${props => props.size === "video" && css`
    width: ${({ theme }) => theme.popup.video}px;
  `}
`;

const ShadowContainer = styled.div`
  position: absolute;
  padding-bottom: 42px;

  ${props => props.position === "TL" && css`
    top: 0;
    left: 0;
  `}

  ${props => props.position === "TR" && css`
    top: 0;
    right: 0;    
  `}

  ${props => props.position === "BL" && css`
    bottom: 0;
    left: 0;    
  `}

  ${props => props.position === "BR" && css`
    bottom: 0;
    right: 0;    
  `}
`;

export const ShadowBox = (props) => {
  const { anchorId, anchorPosition, size } = useSelector(state => ({
    anchorId: state.app.popup.params?.anchorId || null,
    anchorPosition: state.app.popup.params?.anchorPosition,
    size: state.app.popup.params?.size || null,
  }));

  let params = {}
  if (anchorId) {
    const obj = document.getElementById(`${anchorId}`);
    if (obj) {
      const rect = obj.getBoundingClientRect();
      params = {
        x: rect.x,
        y: rect.y,
        w: rect.width,
        h: rect.height,
      }
      // console.log(params);
    }
  } else if (size) {
    params = {
      size,
    }
  }

  return (
    <Shadow>
      <ShadowAnchor id="popup-container" {...params}>
        <ShadowContainer position={anchorPosition}>
          {props.children}
        </ShadowContainer>
      </ShadowAnchor>
    </Shadow>
  )
}