import React from 'react';
import { useSelector } from 'react-redux';
import {
  Container,
  ItemCount,
  Divider,
  Entry,
  Meta,
} from './summary.styled';



const SimpleRow = ({ quantity, nature, unit, title, value, meta, styled = true, product = false }) => {

  let hasMeta = false;
  let metaText = '';
  let text = '';

  let quantityText = quantity;


  switch (nature) {
    default:
      break;
    case "rental":
      quantityText = `1x `;
      text = `${title} ${quantity} day${quantity === 1 ? '' : 's'}`;
      break;

    case "pre-order":
    case "product":
      quantityText = `${quantity}x `;
      text = `${title}`;
      break;
  }




  return (
    <Entry row styled={styled}>
      <Entry left>
        {product ?
          (<Entry textLine>
            <Entry textAmount>{quantityText}</Entry>
            <Entry text>{text}</Entry>
          </Entry>)
          : <>{title}</>}

        {hasMeta
          ? <Meta>{metaText}</Meta>
          : null}
      </Entry>
      <Entry right>
        {value}
      </Entry>
    </Entry>
  )
}


const SummaryTable = ({ view }) => {
  const { total_price, total_items, currency, items } = useSelector((state) => ({
    total_price: state.ecommerce.total_price,
    total_items: state.ecommerce.total_items,
    currency: state.ecommerce.currency,
    items: state.ecommerce.items,
  }))

  const totalFields = [
    {
      title: 'Subtotal',
      value: `${total_price} ${currency}`,
    },
    {
      title: 'Shipping',
      value: `--`,
    },
    // {
    //   title: 'Tax',
    //   value: `0.00 ${currency}`,
    // },
  ]


  return (
    <Container>
      <ItemCount>{total_items} item{total_items === 1 ? '' : 's'}</ItemCount>
      {/* <ItemCount>Basket:</ItemCount> */}

      {items.map((item, index) =>
        <SimpleRow key={index}
          styled={false}
          quantity={item.quantity}
          title={item.name}
          unit={item.unit}
          nature={item.nature}
          meta={item.meta}
          value={`${item.total} ${item.currency}`}
          product={true}
        />
      )}

      <Divider />

      {totalFields.map((item, index) =>
        <SimpleRow key={index}
          styled={false}
          title={item.title}
          value={item.value}
        />
      )}

      <Divider />

      <SimpleRow
        title="Total (inc. VAT)"
        value={`${total_price} ${currency}`}
      />

    </Container>
  );
}

export default SummaryTable;
