import React from 'react';
import {
  Header,
  Footer,
  Layout,
  LayoutHeader,
  LayoutMain,
  LayoutFooter,
} from '../../../ui-components';
import EcommerceFailureContainer from '../containers/ecommerce-failure.container';


const EcommerceFailureView = () => (
  <Layout id="layout" layout="simple">
    <LayoutHeader>
      <Header />
    </LayoutHeader>
    <LayoutMain>
      <EcommerceFailureContainer />
    </LayoutMain>
    <LayoutFooter>
      <Footer />
    </LayoutFooter>
  </Layout>
);

export default EcommerceFailureView;
