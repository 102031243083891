import React from 'react';
import {
  Container,
  Title,
} from './summary.styled';
import { ZWrapper } from "../../../../ui-components";
import SummaryTable from './summary-table';



const Summary = () => (
  <ZWrapper color="white" size="normal">
    <Container>
      <Title>Order Summary</Title>
      <SummaryTable view="checkout" />
    </Container>
  </ZWrapper>
);

export default Summary;