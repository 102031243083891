import React from 'react';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { history, GoogleTagManagerId } from './utils';
import BaseRouter from './routes';
import store from './store';
import { ThemeProvider } from 'styled-components';
import { lightTheme } from './assets/theme/main';
import GlobalStyle from './assets/theme/global';
import AppContainer from './components/app/app.container';
import TagManager from 'react-gtm-module'

if (process.env.NODE_ENV === "production") {
  const tagManagerArgs = {
    gtmId: GoogleTagManagerId,
    // dataLayer: {
    //   userId: "test",
    // }
  }
  TagManager.initialize(tagManagerArgs)
}

const App = () => (
  <Provider store={store}>
    <Router history={history}>
      <ThemeProvider theme={lightTheme}>
        <GlobalStyle />
        <AppContainer />
        <BaseRouter />
      </ThemeProvider>
    </Router>
  </Provider>
);


export default App;
