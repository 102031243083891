import React from 'react';
import {
  Footer,
  Layout,
  LayoutMain,
  LayoutFooter,
} from '../../../ui-components';
import {
  ColumnContainer,
  // Wrapper,
} from '../components/components';
import AuthVerifyContainer from '../containers/auth-verify-container';



const AuthVerifyView = (props) => (
  <>
    <Layout id="layout" layout="simple">
      <LayoutMain>
        <ColumnContainer container>
          <ColumnContainer head>
            
          </ColumnContainer>
          <ColumnContainer details left>
            
              <AuthVerifyContainer {...props} />
            
          </ColumnContainer>
        </ColumnContainer>
      </LayoutMain>
      <LayoutFooter>
        <Footer {...props} />
      </LayoutFooter>
    </Layout>
  </>
);


export default AuthVerifyView;
