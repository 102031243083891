import React from 'react';
import {
  Header,
  Footer,
  Layout,
  LayoutHeader,
  LayoutMain,
  LayoutFooter,
  PageTitle,
} from '../../../ui-components';
import CommunityDealershipContainer from '../../community/containers/community-dealership.container';


const CommunityDealershipView = (props) => (
  <Layout id="layout" layout="simple" compact>
    <LayoutHeader>
      <Header />
    </LayoutHeader>
    <LayoutMain>
      <PageTitle>Dealerships / Collaborations</PageTitle>
      <CommunityDealershipContainer />
    </LayoutMain>
    <LayoutFooter>
      <Footer />
    </LayoutFooter>
  </Layout>
)
export default CommunityDealershipView;
