import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';


export const Grid = styled.div`
  ${props => props.product_container && css`
    display: grid;
    grid-gap: 16px;
    grid-template-columns: 5fr 4fr;
    grid-template-areas:
    "gallery text"
    "gallery description"
    "gallery .";

    @media ${({ theme }) => theme.device.mobile}{
      grid-template-columns: 1fr;
      grid-template-areas:
      "gallery"
      "text"
      "description";
    }
  `}

  ${props => props.preorder_container && css`
    display: grid;
    grid-gap: 0 16px;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
    "gallery text"
    "gallery description"
    "gallery ."
    "map map";


    @media ${({ theme }) => theme.device.mobile}{
      grid-template-columns: 1fr;
      grid-template-areas:
      "gallery"
      "text"
      "description"
      "map";
    }
  `}

  ${props => props.rental_container && css`
    display: grid;
    grid-gap: 16px;
    grid-template-columns: auto 280px;
    grid-template-areas: 
    "gallery text"
    "gallery calendar"
    "description calendar"
    ". calendar";

    @media ${({ theme }) => theme.device.mobile}{
      grid-template-columns: 1fr;
      grid-template-areas:
      "gallery"
      "text"
      "description"
      "calendar";
    }
  `}

  ${props => props.gallery && css`
    grid-area: gallery;
    text-align: center;
    @media ${({ theme }) => theme.device.mobile}{
      margin-bottom: 16px;
    }
  `}

  ${props => props.calendar && css`
    grid-area: calendar;
  `}

  ${props => props.map && css`
    grid-area: map;
  `}

  ${props => props.text && css`
    grid-area: text;
    text-align: left;
    @media ${({ theme }) => theme.device.mobile}{
      text-align: center;
    }
  `} 

  ${props => props.description && css`
    grid-area: description;
    text-align: left;
  `} 
`;

export const Image = styled.img`
  max-width: 100%;
  flex: 1;
  margin: auto;
`;

export const ImageBlank = styled.div`
  flex: 1;
  background: ${({ theme }) => theme.background.default};
`;

export const Title = styled(Link)`
  display: inline-block;
  font-family: 'Quantico', sans-serif;
  font-size: 21px;
  text-transform: uppercase;
  text-align: left;
  margin: 0 8px 8px 0;
  color: ${({ theme }) => theme.primary.main};

  &:hover{
    color: ${({ theme }) => theme.primary.dark};
    text-decoration: none;
  }

`;

export const Label = styled.div`
  display: inline-block;
  font-size: 10px;
  position: relative;
  top: -4px;
  padding: 2px 6px;
  text-transform: uppercase;
  border-radius: 2px;


  ${props => props.unit === "rental" && css`
    background: ${({ theme }) => theme.thirdiary.main};
    color: ${({ theme }) => theme.text.white};
  `}

  ${props => props.unit === "pre-order" && css`
    background: ${({ theme }) => theme.secondary.main};
    color: ${({ theme }) => theme.text.primary};
  `}
`

export const Price = styled.div`
  text-align: left;
  font-size: 18px;
  font-family: 'Quantico', sans-serif;
  color: ${({ theme }) => theme.text.primary};
`;

export const PriceTag = styled.div`
${props => props.container && css`
  margin: 8px 0;
  text-align: left;  

  @media ${({ theme }) => theme.device.mobile}{
    text-align: center;
  }
`}

${props => props.text && css`
  font-size: 14px;
  line-height: 21px;
  color: ${({ theme }) => theme.text.secondary};

`}
${props => props.price && css`
  font-size: 18px;
  font-family: 'Quantico', sans-serif;
  color: ${({ theme }) => theme.text.primary};
`}
`;


export const CallToAction = styled.div`
  text-align: left;
  font-size: 15px;
  line-height: 21px;
  color: ${({ theme }) => theme.text.secondary};
  margin: 16px 0;

  @media ${({ theme }) => theme.device.mobile}{
      text-align: center;
  }
`;


export const Description = styled.div`
  text-align: left;
  font-size: 15px;
  line-height: 19px;;
  /* margin: 16px 0; */
  /* padding: 16px 0 0 0; */
  color: ${({ theme }) => theme.text.primary};
  border-top: 1px solid ${({ theme }) => theme.shades.line};

  p{
    font-size: 15px;
    line-height: 19px;
  }

  ${props => props.noLine && css`
    border: 0;
  `}
`;

export const Gallery = styled.div`
  ${props => props.layout === "none" && css`
    display: grid;
    grid-template-areas:
    "image";
  `}

  ${props => props.layout === "right" && css`
    display: grid;
    grid-template-areas:
    "image options";
  `}

  ${props => props.layout === "left" && css`
    display: grid;
    grid-template-areas:
    "options image";
  `}

  ${props => props.layout === "bottom" && css`
    display: grid;
    grid-template-areas:
    "image"
    "options";
  `}

  ${props => props.options && css`
    grid-area: options;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 0;
    flex-wrap: wrap;
    padding-top: 2px;

    ${props => props.direction === "vertical" && css`
      flex-direction: column;
    `}

  `}

  ${props => props.image && css`
    grid-area: image;
    position: relative;
    line-height: 0;
  `}
`;


export const GalleryThumb = styled.button`
  display: block;
  border: 0;
  background: transparent;
  border: 2px solid transparent;
  padding: 0;
  line-height: 0;
  margin: 2px 2px;
  background: ${({ theme }) => theme.background.default};

  ${props => props.active === "true" && css`
    border: 2px solid ${({ theme }) => theme.thirdiary.main};
  `}
`;

export const GalleryButton = styled.div`
  display: inline-block;
  position: absolute;
  top: 50%;
  margin-top: -14px;
  
  ${props => props.left && css`
    left: 0;

    @media ${({ theme }) => theme.device.phone}{
      left: -16px;
      z-index: 1;
    }
  `}

  ${props => props.right && css`
    right: 0;
    @media ${({ theme }) => theme.device.phone}{
      right: -16px;
      z-index: 1;
    }
  `}
`;

export const Thumbnail = styled.img`
  max-width: 52px;
`;

export const MetaTable = styled.div`
  ${props => props.container && css`
    padding: 8px;
  `}

  ${props => props.row && css`
    padding: 2px;
    font-size: 15px;
    line-height: 21px;
    color: ${({ theme }) => theme.text.secondary};

    strong{
      /* color: ${({ theme }) => theme.text.primary}; */
    }
  `}
`;

export const Location = styled.div`
  ${props => props.container && css`
    display: flex;
    margin: 16px 0;

    @media ${({ theme }) => theme.device.mobile}{
      justify-content: center;
    }
      
  `}

  ${props => props.text && css`
    color: ${({ theme }) => theme.text.primary};
    font-size: 15px;
  `}

  ${props => props.url && css`
    padding-left: 4px;
    font-weight: bold;
    font-size: 15px;
  `}

`;

export const MapTitle = styled.div`
  font-family: 'Quantico', sans-serif;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 21px;
  color: ${({ theme }) => theme.text.primary};
  margin: 32px 0 16px;
  text-align: center;

`;

export const MapImage = styled.img`
  width: 100%;
  height: auto;
`