import React from "react";
import {
  Error,
  FormGroup,
  SelectField,
  InputField,
  Label,
} from './input.styled';


// https://medium.com/@fionnachan/building-a-react-component-library-with-styled-components-input-field-c79c789387ad
// https://codepen.io/lucasyem/pen/ZEEYKdj




export const TextField = (props) => {
  const {
    id,
    name,
    value,
    label,
    hasError,
    type,
    onChange,
    style,
    disabled,
    className,
    required,
    raw,
  } = props;

  const placeholder = label;
  const fieldId = id ? id : name;

  return (
    <FormGroup
      style={style}
      className={className}
      raw={raw}
    >
      <InputField
        id={fieldId}
        type={type}
        name={name}
        value={value}
        placeholder={placeholder}
        error={hasError}
        onChange={onChange}
        disabled={disabled}
        autoComplete="off"
      />
      {label && <Label htmlFor={fieldId}>{label} {required ? <span>*</span> : null}</Label>}
      {hasError && <Error>{hasError}</Error>}
    </FormGroup>
  );
};

export const SelectOption = (props) => {
  const {
    value,
    children
  } = props;

  return (
    <option value={value}>{children}</option>
  )
}

export const Select = (props) => {
  const {
    id,
    name,
    defaultValue,
    value,
    label,
    hasError,
    onChange,
    style,
    disabled,
    className,
    required,
    raw,
  } = props;

  const placeholder = label;
  const fieldId = id ? id : name;

  return (
    <FormGroup
      style={style}
      className={className}
      raw={raw}
    >
      <SelectField
        id={fieldId}
        name={name}
        value={value}
        defaultValue={defaultValue}
        placeholder={placeholder}
        error={hasError}
        onChange={onChange}
        disabled={disabled}
        required
      >
        {props.children}
      </SelectField>
      {label && <Label htmlFor={fieldId}>{label} {required ? <span>*</span> : null}</Label>}
      {hasError && <Error>{hasError}</Error>}
    </FormGroup>
  );
}

export const ErrorText = ({ children }) => <Error>{children}</Error>;
