import React from 'react';
import {
  Footer,
  Layout,
  LayoutHeader,
  LayoutMain,
  LayoutFooter,
} from '../../../ui-components';
import {
  ColumnContainer,
} from '../components/components';
import AuthContainer from '../containers/auth.container';


const AuthView = (props) => (
  <>
    <Layout layout="main">
      <LayoutHeader>
        {/* <Header /> */}
      </LayoutHeader>
      <LayoutMain>
        <ColumnContainer container>
          <ColumnContainer details style={{ textAlign: "center" }}>
            <AuthContainer />
          </ColumnContainer>
        </ColumnContainer>
      </LayoutMain>
      <LayoutFooter>
        <Footer {...props} />
      </LayoutFooter>
    </Layout>
  </>
);


export default AuthView;
