import React from 'react';
import HeaderToogle from './header-toogle';
import {
  Container,
  ToogleWrapper,
  Basket,
  SummaryWrapper,
  Bubble,
  DekstopOnly,
} from './header-basket.styled';
import {
  Icon,
} from '../../ui-components';
import SummaryTable from '../../components/ecommerce/components/summary/summary-table';
import { CheckoutButton } from '../../components/ecommerce/components/basket';


const ToogleContent = ({ active, checkout, order }) => {
  const { items, itemCount, totalPrice, currency } = order;

  const condensed = items.length === 1;
  const itemText = condensed
    ? <><span>{`${items[0].quantity}`} X </span>{items[0].name}</>
    : `${itemCount} items`;

  return (
    <>
      <Container nature="extended">
        <ToogleWrapper active={active}>
          <Basket
            container
            active={active}
          >
            <Basket
              icon
              active={active}
            >
              <Icon
                name="shopping-cart-2"
                size={21}
                noFill
              />
            </Basket>
            <Basket
              items
              condensed={condensed ? "true" : "false"}
              active={active}
            >
              {itemText}
            </Basket>
            <Basket
              total
              active={active}
            >
              Total: {totalPrice} {currency}
            </Basket>
          </Basket>
        </ToogleWrapper>
      </Container>

      <Container nature="simple">
        <ToogleWrapper active={active}>
          <Icon
            name="shopping-cart-2"
            size={21}
            noFill
          />
          {itemCount > 0
            ? <Bubble checkout={checkout ? 1 : 0}>{itemCount}</Bubble>
            : null
          }
        </ToogleWrapper>
      </Container>
    </>
  )
}


const HeaderBasket = ({ children, activeMenu, toogleVisibility, checkout, order }) => {
  const { state, uuid, items } = order;
  const hasBasket = state !== "";

  if (hasBasket)
    return (
      <HeaderToogle
        nature="basket"
        visible={activeMenu === "basket"}
        toogleVisibility={() => toogleVisibility("basket")}
        toogle={
          <ToogleContent
            checkout={checkout}
            active={activeMenu === "basket"}
            order={order}
          />
        }
        background="default"
      >
        <SummaryWrapper>
          <SummaryTable view="header" />
          {!checkout && items.length > 0
            ? <CheckoutButton view="header">
              Checkout
            </CheckoutButton>
            : null}
        </SummaryWrapper>
      </HeaderToogle>
    );

  if (uuid !== "")
    return null

  return (
    <DekstopOnly>
      {children}
    </DekstopOnly>
  )
};

export default HeaderBasket;
