import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';




export const RowContainer = styled.div`

  ${props => props.container && css`
    display: flex;
    flex-direction: row;
    margin: 80px auto 0;
    max-width: 900px;
  `}

  ${props => props.side && css`
    flex: 1;
    margin: 0 12px 0 0;
  `}

  ${props => props.details && css`
    width: 420px;
  `}
`;



export const ColumnContainer = styled.div`
  ${props => props.container && css`
    display: flex;
    flex-direction: column;
    margin: 24px auto;
    max-width: 460px;
    align-items: center;
    justify-content: center;
  `}

  ${props => props.head && css`
    flex: 1;
    padding-bottom: 16px;
    padding-left: 32px;
    text-align: center;
  `}

  ${props => props.details && css`
    flex: 1;
    text-align: center;

    ${props => props.left && css`
        text-align: left;
    `}
  `}
`;


export const Wrapper = styled.div`
  padding: 24px 36px 36px;
  background: ${({ theme }) => theme.background.paper};
  text-align: center;
  box-shadow: 0 0 4px 0px rgb(0 0 0 / 8%);
`;

export const LogoImg = styled.img`
  /* width: 240px; */
  width: 150px;
  height: auto;
  margin-bottom: 16px;
`;

export const Title = styled.h1`
  text-align: left;
  font-size: 21px;
  font-family: RobotoMedium;
  /* color: ${({ theme }) => theme.secondary.main}; */

  color: ${({ theme }) => theme.text.primary};
  margin: 8px 0 32px;

  ${props => props.center && css`
    text-align: center;
  `}
`;

export const Form = styled.form`
  margin: 8px 0;
`;

export const Paragraph = styled.p`
  text-align: left;
  margin: 16px 0;
  font-size: 14px;
  color: ${({ theme }) => theme.text.primary};

  ${props => props.sidepadding && css`
    margin-left: 32px;
  `}
  
  ${props => props.center && css`
    text-align: center;
  `}
`;


export const SimpleLink = styled(Link)`
  color: ${({ theme }) => theme.common.orange};
`



export const LogoPlacement = styled.div`
  margin-bottom: 32px;
`;

export const Bullet = styled.div`
  ${props => props.container && css`
    margin-bottom: 24px;
  `}

  ${props => props.point && css`
    margin-bottom: 8px;
    font-size: 16px;
    font-family: RobotoMedium;
    color: ${({ theme }) => theme.primary.main};

    svg{
      margin-right: 4px;
      fill: ${({ theme }) => theme.common.green};
    }
  `}

  ${props => props.details && css`
    font-size: 15px;
    line-height: 19px;
    color: ${({ theme }) => theme.text.primary};
  `}
`