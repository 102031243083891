import styled, { css } from "styled-components";
// import { Link } from 'react-router-dom';


export const Container = styled.div`
  ${props => props.nature === "extended" && css`
    display: none;
    @media ${({ theme }) => theme.device.mobile}{
      display: block;
    }
  `}

  ${props => props.nature === "simple" && css`
    display: block;
    @media ${({ theme }) => theme.device.mobile}{
      display: none;
    }
  `}
`


const cutSizeWrap = 4;

export const ToogleWrapper = styled.div`
  position: relative;
  display: block;
  padding: 6px 8px;

  svg{
    position: relative;
    top: 2px;
    fill: ${({ theme }) => theme.primary.main};
  }

  @media ${({ theme }) => theme.device.desktop}{
    &:hover{
      svg{
        fill: ${({ theme }) => theme.primary.dark};
      }
    }
  }

  ${props => props.active && css`
    background: ${({ theme }) => theme.primary.main};

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: -${cutSizeWrap}px;
      bottom: 0;
      border-top: ${cutSizeWrap}px solid transparent;
      border-left: ${cutSizeWrap}px solid ${({ theme }) => theme.primary.main};
    }
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: -${cutSizeWrap}px;
      bottom: 0;
      border-top: ${cutSizeWrap}px solid transparent;
      border-right: ${cutSizeWrap}px solid ${({ theme }) => theme.primary.main};
    }

    svg{
      fill: ${({ theme }) => theme.text.white};
    }


    @media ${({ theme }) => theme.device.desktop}{
      &:hover{
        background: ${({ theme }) => theme.primary.dark};
        &::before { border-left: ${cutSizeWrap}px solid ${({ theme }) => theme.primary.dark};}
        &::after { border-right: ${cutSizeWrap}px solid ${({ theme }) => theme.primary.dark};}
        svg{
          fill: ${({ theme }) => theme.text.white};
        }
      }
    }

    @media ${({ theme }) => theme.device.mobile}{
      top: 6px;
      padding: 12px 8px 18px;
    }
  `}
`

export const Basket = styled.div`
  ${props => props.container && css`
    color: ${({ theme }) => theme.text.secondary};
    display: grid;
    grid-gap: 0 8px;
    grid-template-areas:
      "icon items"
      "icon total";
  `}

  ${props => props.icon && css`
    grid-area: icon;
    align-self: center;
    svg{
      position: relative;
      top: 6px;
      fill: ${({ theme }) => theme.primary.main};
      margin-top: -16px;
    }
    ${props => props.active && css`
      svg{
        fill: ${({ theme }) => theme.text.white};
      }
    `}
  `}

  ${props => props.items && css`
    grid-area: items;
    align-self: center;
    font-family: 'Quantico', sans-serif;
    text-transform: uppercase;
    color: ${({ theme }) => theme.primary.main};
    font-size: 14px;
    line-height: 14px;



    span {
      color: ${({ theme }) => theme.text.secondary};
      font-size: 12px;
    }

    ${props => props.condensed === "true" && css`
      overflow: hidden;
      white-space: nowrap;
    `}

    ${props => props.condensed === "false" && css`
      color: ${({ theme }) => theme.text.primary} !important;
      font-size: 13px;
      text-align: left;
    `}

    ${props => props.active && css`
      color: ${({ theme }) => theme.text.white};
      span {
        color: ${({ theme }) => theme.text.disabled};
      }
    `};
  `}

  ${props => props.total && css`
    grid-area: total;
    font-size: 12px;
    line-height: 14px;
    color: ${({ theme }) => theme.text.disabled};
    text-align: right;

    ${props => props.active && css`

    `}
  `}
`;

export const Bubble = styled.div`
  position: absolute;
  top: -4px;
  right: -8px;
  display: block;
  font-size: 12px;
  font-weight: bold;
  width: 20px;
  height: 20px;
  line-height: 18px;
  border-radius: 50%;
  text-align: center;
  padding-left: 1px;
  background: ${({ theme }) => theme.thirdiary.main};
  color: ${({ theme }) => theme.text.white};
  pointer-events: none;
  border: 1px solid ${({ theme }) => theme.text.white};


  ${props => props.checkout && css`
    /* background: ${({ theme }) => theme.primary.main}; */
  
  `}
`;

const cutSize = 5;

export const SummaryWrapper = styled.div`
  position: relative;
  padding: 6px;
  z-index: 2;
  margin: -1px 4px -1px 4px;

  background: ${({ theme }) => theme.background.paper};

  &::before {
      content: '';
      position: absolute;
      top: 0;
      right: -${cutSize}px;
      bottom: 0;
      border-top: ${cutSize}px solid transparent;
      border-left: ${cutSize}px solid ${({ theme }) => theme.background.paper};
      border-bottom: ${cutSize}px solid transparent;
    }
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: -${cutSize}px;
      bottom: 0;
      border-top: ${cutSize}px solid transparent;
      border-right: ${cutSize}px solid ${({ theme }) => theme.background.paper};
      border-bottom: ${cutSize}px solid transparent;
      z-index: 2;
    }
`;


export const DekstopOnly = styled.span`
  @media ${({ theme }) => theme.device.mobile}{
    /* display: none; */
  }
`;