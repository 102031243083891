import React, { useState } from 'react';
import {
  Image,
  ImageBlank,
  Gallery,
  GalleryThumb,
  GalleryButton,
  Thumbnail,
} from './product.styled';
import {
  Button,
} from '../../../../ui-components';


export const ProductGallery = ({ name, images = [], thumbnails = "none" }) => {
  const [currentImage, setCurrentImage] = useState(0);

  const handleGalleryChange = (key) => {
    setCurrentImage(key);
  }

  return (
    <Gallery layout={thumbnails}>
      {thumbnails !== "none"
        ? <Gallery
          direction={["left", "right"].includes(thumbnails) ? "vertical" : "horizontal"}
          options
        >
          {images.map((image, index) =>
            <GalleryThumb
              key={index}
              active={currentImage === index ? "true" : "false"}
              onClick={() => handleGalleryChange(index)}
            >
              <Thumbnail
                src={image.thumb}
                alt={`${name} - ${index}`}
              >
              </Thumbnail>
            </GalleryThumb>
          )}
        </Gallery>
        : null
      }
      <Gallery image>
        {images.length > 0
          ? <Image
            src={images[currentImage].landscape_large}
            alt={name}
          />
          : <ImageBlank />
        }
        {images.length > 1
          ? (
            <>
              <GalleryButton left>
                <Button
                  type="button"
                  size="icon"
                  icon="arrow-ios-back-outline"
                  color="thirdiary"
                  nomargin
                  onClick={() => { handleGalleryChange(currentImage > 0 ? currentImage - 1 : images.length - 1) }}
                />
              </GalleryButton>
              <GalleryButton right>
                <Button
                  type="button"
                  size="icon"
                  icon="arrow-ios-forward-outline"
                  color="thirdiary"
                  nomargin
                  onClick={() => { handleGalleryChange(currentImage < images.length - 1 ? currentImage + 1 : 0) }}

                />
              </GalleryButton>
            </>
          )
          : null}
      </Gallery>
    </Gallery >
  )
}

export default ProductGallery;
