const primary = {
  light: "#88d8f1",
  main: "#00c3e6",
  dark: "#1f8aac",
  contrastText: "#000"
};

const secondary = {
  light: "#ebf97e",
  main: "#DCF323",
  dark: "#bad100",
  contrastText: "#98AB00"
};

const thirdiary = {
  light: "#fe9444",
  main: "#ff6e00",
  dark: "#c75400",
  contrastText: "#fff"
};

const common = {
  black: "#1D1D1B",
  white: "#FFFFFF",
  green: "#0DC64F",
  blue: "#177AF5",
  yellow: "#FFE50A",
  red: "#FA5100",
  orange: "#ff6e00",
  amber: "#FFD160",
  purple: "#BB09DE",
  hover: {
    black: "#4C545E",
    white: "#FAFAFA",
    green: "#0AA742",
    blue: "#136BD9",
    yellow: "#E7CE00",
    red: "#C0341E",
    orange: "#D87E00",
    amber: "#E6A400",
    purple: "#85069E",
  }
};

const error = {
  light: "#e57373",
  main: "#f44336",
  dark: "#d32f2f",
  contrastText: "#fff"
};


const background = {
  paper: "#fff",
  default: "#ECF1F5",
  dark: "#D4DFE9",
};

const shades = {
  light: "#F9FBFD",
  main: "#F3F6F9",
  dark: "#DFE6ED",
  line: "#E3E3EE",
};

const text = {
  primary: "rgba(0, 0, 0, 0.87)",
  secondary: "rgba(0, 0, 0, 0.54)",
  disabled: "rgba(0, 0, 0, 0.28)",
  hint: "rgba(0, 0, 0, 0.38)",
  title: "#52527B",
  label: "#52527B",
  white: "#fff",
};


const unit = 8;

export const size = {
  mobile: 320,
  tabletPortrait: 425,
  tablet: 824,
  desktop: 960,
  max: 1160,
  container: 960,
  sides: unit,
}

export const device = {
  desktop: `only screen and (min-width: ${(size.desktop + 2 * size.sides)}px)`,
  tablet: `only screen and  (min-width: ${(size.tablet + 2 * size.sides)}px) and (max-width: ${(size.desktop + 2 * size.sides - 1)}px)`,
  tabletPortrait: `only screen and (min-width: ${(size.tabletPortrait + 2 * size.sides)}px) and (max-width: ${(size.tablet + 2 * size.sides - 1)}px)`,
  mobile: `only screen and (max-width: ${(size.tablet + 2 * size.sides) - 1}px)`,
  phone: `only screen and (max-width: ${(size.tabletPortrait + 2 * size.sides) - 1}px)`,
};


export const lightTheme = {
  primary,
  secondary,
  thirdiary,
  common,
  error,
  background,
  shades,
  text,
  size,
  device,
  popup: {
    normal: 284,
    medium: 640,
    large: 960,
    video: 720,
  },
};


export const darkTheme = lightTheme;

