import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import {
  COMMON_COLORS,
  HIEARARCHY_COLORS,
  getAngleBySize,
  getAngleByHierarchyColor,
  getAngleByCommonColor,
} from './button.styled.helper';


const ButtonStyle = css`
  position: relative;
  display: inline-block;
  cursor: pointer;
  border: 0;
  position: relative;
  font-family: "Quantico", sans-serif, sans-serif;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 18px;
  box-shadow: none;
  text-decoration: none !important;
  box-sizing: border-box;


  margin: 4px 8px;
  padding: 8px 0px;

  white-space: nowrap;

  ${props => props.nomargin && css`
    margin-top: 0px;
    margin-bottom: 0px;
  `}

  ${props => props.block && css`
    display: block;
  `}

  ${props => props.inline && css`
    display: inline-block;
  `}

  ${getAngleBySize({ angle: '6px' })}

  ${props => props.size === "large" && css`
    font-size: 15px;
    line-height: 18px;
    padding: 12px 24px;
    margin-left: 6px;
    margin-right: 6px;
    ${getAngleBySize({ angle: '6px' })}

    ${props => props.fullwidth && css`
      width: calc(100% - 12px);
    `}
  `}

  ${props => props.size === "normal" && css`
    font-size: 14px;
    line-height: 18px;

    padding: 8px 16px;
    margin-left: 6px;
    margin-right: 6px;
    ${getAngleBySize({ angle: '5px' })}

    ${props => props.fullwidth && css`
      width: calc(100% - 12px);
    `}
  `}

  ${props => props.size === "medium" && css`
    font-size: 13px;
    line-height: 16px;

    padding: 6px 16px;
    margin-left: 6px;
    margin-right: 6px;
    ${getAngleBySize({ angle: '5px' })}

    ${props => props.fullwidth && css`
      width: calc(100% - 12px);
    `}
  `}

  ${props => props.size === "small" && css`
    padding: 4px 4px;
    margin-left: 4px;
    margin-right: 4px;

    font-size: 12px;
    line-height: 16px;
    ${getAngleBySize({ angle: '4px' })}

    ${props => props.fullwidth && css`
      width: calc(100% - 8px);
    `}
  `}

  ${props => props.size === "menu" && css`
    font-size: 14px;
    line-height: 18px;

    padding: 8px 16px;
    margin-left: 6px;
    margin-right: 6px;
    ${getAngleBySize({ angle: '5px' })}

    ${props => props.fullwidth && css`
      width: calc(100% - 12px);
    `}

    @media ${({ theme }) => theme.device.mobile}{
      font-size: 15px;
      line-height: 18px;
      padding: 14px 24px;
      margin-left: 6px;
      margin-right: 6px;
    }
  `}

  ${props => props.size === "icon" && css`
    min-width: auto;
    padding: 8px 2px;
    justify-content: center;
    text-align: center;
    line-height: 0;

    margin-left: 4px;
    margin-right: 4px;

    ${props => props.fullwidth && css`
      width: calc(100% - 12px);
    `}
  `};

  ${props => props.size === "icon_small" && css`
    min-width: auto;
    padding: 6px 2px;
    justify-content: center;
    text-align: center;
    line-height: 0;


    margin-left: 3px;
    margin-right: 3px;
    ${getAngleBySize({ angle: '4px' })}

    ${props => props.fullwidth && css`
      width: calc(100% - 12px);
    `}
  `};

  ${props => props.size === "icon_menu" && css`
    min-width: auto;
    padding: 12px 2px;
    justify-content: center;
    text-align: center;
    line-height: 0;

    margin-left: 4px;
    margin-right: 4px;


    ${getAngleBySize({ angle: '5px' })}


    ${props => props.fullwidth && css`
      width: calc(100% - 10px);
    `}

  `};


  ${props => props.color
    && HIEARARCHY_COLORS.includes(props.color)
    && getAngleByHierarchyColor(props)
  };

  ${props => props.color
    && COMMON_COLORS.includes(props.color)
    && getAngleByCommonColor(props)
  };

  ${props => props.shadow && css`
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.18);
  `}
  
  &:disabled{
    opacity: 0.5;
  }
`;

export const ButtonIcon = styled.div`
  position: relative;
  margin: -2px;
  padding: 0;

`;

export const ButtonIconLeft = styled.div`
  position: relative;
  top: 4px;
  margin-left: -8px;
  margin-right: 0px;
  svg{
    margin: -4px auto 0; 
    border: 1px solid transparent;
  }
`;

export const ButtonIconRight = styled.div`
  position: relative;
  top: 4px;
  margin-right: -10px;
  margin-left: 4px;
  svg{
    margin: -4px auto 0; 
    border: 1px solid transparent;
  }
`;


const ButtonAlignment = css`
  text-align: center;

  ${props => props.align === "left" && css`
    text-align: left;

    ${props => props.size === "large" && css` padding-left: 4px;`}
    ${props => props.size === "normal" && css` padding-left: 4px;`}
    ${props => props.size === "medium" && css` padding-left: 4px;`}
    ${props => props.size === "menu" && css`
      padding-left: 4px;
      @media ${({ theme }) => theme.device.mobile}{
        padding-left: 4px;
      }
    `}
    ${props => props.size === "small" && css` padding-left: 2px;`}
  `}

  ${props => props.align === "center" && css`
    text-align: center;
  `}

  ${props => props.align === "right" && css`
    text-align: right;
    ${props => props.size === "large" && css` padding-right: 4px;`}
    ${props => props.size === "normal" && css` padding-right: 4px;`}
    ${props => props.size === "medium" && css` padding-right: 4px;`}
    ${props => props.size === "menu" && css`
      padding-right: 4px;
      @media ${({ theme }) => theme.device.mobile}{
        padding-right: 4px;
      }
    
    `}
    ${props => props.size === "small" && css` padding-right: 2px;`}
  `} 
`;


export const ButtonLink = styled(Link)`
  ${ButtonStyle}
  ${ButtonAlignment}
`;

export const ButtonAnchorLink = styled.a`
  ${ButtonStyle}
  ${ButtonAlignment}
`;

export const ButtonButton = styled.button`
  ${ButtonStyle}

  ${ButtonAlignment}
`;




export const ButtonGroupFrame = styled.div`

  display: inline-block;
  padding: 1px;

  background-color: ${({ theme }) => theme.secondary.main};
  position: relative;
  /* padding: 0 8px; */

  /* border: 1px solid red; */



  ${getAngleBySize({ angle: '6px' })}

  ${props => props.size === "large" && css`
    ${getAngleBySize({ angle: '7px' })}
    margin-left: 7px;
  `}

  ${props => props.size === "normal" && css`
    ${getAngleBySize({ angle: '6px' })}
    margin-left: 6px;
  `}

  ${props => props.size === "medium" && css`
    ${getAngleBySize({ angle: '6px' })}
    margin-left: 6px;
  `}
  
  ${props => props.size === "small" && css`
    ${getAngleBySize({ angle: '5px' })}
    margin-left: 5px;
  `}


  & > * {
    z-index: 1;
    display: inline-block;
    margin: 0;

    ${props => props.size === "large" && css`
      margin-right: 13px;
    `}

    ${props => props.size === "normal" && css`
      margin-right: 11px;
    `}

    ${props => props.size === "medium" && css`
      margin-right: 11px;
    `}
    
    ${props => props.size === "small" && css`
      margin-right: 9px;
    `}

    &:first-child {
      margin-left: -1px;
    }
    &:last-child {
      margin-right: -1px;
    }
  }



  ${props => props.color
    && HIEARARCHY_COLORS.includes(props.color)
    && getAngleByHierarchyColor({ ...props, disableHover: true })
  };

  ${props => props.color
    && COMMON_COLORS.includes(props.color)
    && getAngleByCommonColor({ ...props, disableHover: true })
  };





`