import React from 'react';
import {
  Header,
  Footer,
  Layout,
  LayoutHeader,
  LayoutMain,
  LayoutFooter,
} from '../../../ui-components';
import MarketingSurveyContainer from '../../marketing/containers/marketing-survey.container';


const MarketingSurveyView = (props) => {
  let userEmail = null;
  const urlParams = new URLSearchParams(props.location.search)
  if(urlParams){
    const params = Object.fromEntries(urlParams);
    userEmail = params?.user_email || null;
  }

  return (
    <Layout id="layout" layout="simple" compact>
      <LayoutHeader>
        <Header />
      </LayoutHeader>
      <LayoutMain>
        <div style={{ margin: "40px 16px" }}>
          <MarketingSurveyContainer email={userEmail} layout="full" />
        </div>
      </LayoutMain>
      <LayoutFooter>
        <Footer />
      </LayoutFooter>
    </Layout>
  )
}

export default MarketingSurveyView;
