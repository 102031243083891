import React from 'react';
import {
  Month,
  WeekDay,
  Day,
  DayEmpty,
} from './calendar.styled';
import { parse } from 'date-fns';



const DATE_FORMAT = "yyyy-MM-dd";
const MONTHS = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];


const MonthDay = ({ uuid, data, selected, year, month, day, disable, onClick }) => {
  const month_str = (month > 9) ? month : `0${month}`;
  const day_str = (day > 9) ? day : `0${day}`;

  let nature = data?.nature
  if(data?.reserved){
    nature = "reserved_me"
  }

  // console.log(data)
  const isAvailable = data?.nature === "available";
  return (
    <Day
      onClick={() => {
        if (isAvailable)
          onClick({
            year,
            month: month_str,
            day: day_str,
            period_id: data.period_id,
          })
      }}
      nature={nature}
      selected={selected}
      disabled={!isAvailable || disable}
    >
      {day}
    </Day>
  )
}

const inRange = (from, till, date_str) => {
  const parsedNow = parse(date_str, DATE_FORMAT, new Date());
  const parsedFrom = parse(from, DATE_FORMAT, new Date());
  const parsedTill = parse(till, DATE_FORMAT, new Date());

  if (till !== '' && parsedNow >= parsedFrom && parsedNow <= parsedTill) {
    return true;
  } else if (till === '' && date_str === from) {
    return true;
  }

  return false;
}


const MonthComponent = ({ month, year, dates, range, disable, onClick }) => {
  const firstDay = (new Date(year, month)).getDay();
  const daysInMonth = 32 - new Date(year, month, 32).getDate();

  const empty = [];
  const calendar = [];
  for (let i = 1; i < firstDay; i++) {
    empty.push(<DayEmpty key={i} />)
  }

  for (let i = 1; i <= daysInMonth; i++) {
    const month_str = (month + 1 > 9) ? month + 1 : `0${(month + 1)}`
    const day_str = (i > 9) ? i : `0${i}`
    const date_str = `${year}-${month_str}-${day_str}`;
    const selected = inRange(range.from, range.till, date_str)

    calendar.push(
      <MonthDay
        key={i}
        day={i}
        data={dates.find(el => el.date === date_str)}
        month={month + 1}
        year={year}
        selected={selected}
        disable={disable}
        onClick={onClick}
      />
    )
  }

  return (
    <Month container>
      <Month month>{MONTHS[month]} {year}</Month>
      <Month weekdays>
        <WeekDay>Mon</WeekDay>
        <WeekDay>Tue</WeekDay>
        <WeekDay>Wed</WeekDay>
        <WeekDay>Thu</WeekDay>
        <WeekDay>Fri</WeekDay>
        <WeekDay>Sat</WeekDay>
        <WeekDay>Sun</WeekDay>
      </Month>
      <Month calendar>
        {empty}
        {calendar}
      </Month>
    </Month>
  )
}

export default MonthComponent;
