import React from 'react';
import {
  Wrapper,
  Anchor,
  Container,
  Close,
  ButtonPlacement,
} from './header-subscribe.styled';
import {
  ZWrapper,
  Button,
  Icon,
} from '..';


export const SubscribeButton = ({ showSubscribe }) => (
  <ButtonPlacement
    type="button"
    onClick={showSubscribe}
  >
    <Icon
      name="email-outline"
      size={24}
      noFill
    />
  </ButtonPlacement>
)

const HeaderSubscribe = ({ activeMenu, toogleVisibility }) => {
  if (activeMenu !== "subscribe")
    return null;

  return (
    <Wrapper>
      <Anchor table>
        <Anchor cell>
          <Anchor place>
            <ZWrapper size="small">
              <Container>
                <Close>
                  <Button
                    type="button"
                    size="icon_small"
                    icon="close-outline"
                    color="primary"
                    nomargin
                    onClick={() => { toogleVisibility("none") }}
                  />
                </Close>
                subscribe to newsletter <br />
                <br />
                Super cool description, maybe some photo or illustration<br />
                <br />
                [ form goes here ]
              </Container>
            </ZWrapper>
          </Anchor>
        </Anchor>
      </Anchor>
    </Wrapper>
  );
}

export default HeaderSubscribe;
