import React from "react";
import { appActions } from "../../../app/redux";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Title,
  ImageContainer,
  Image,
  ImageBlank,
  Price,
  InBasketLocation,
} from './product-list.styled';
import {
  ZWrapper,
  Button,
} from "../../../../ui-components";
import {
  history
} from '../../../../utils';


export const ProductList = (props) => {
  const { name, price, nature, unit, currency, slug, image_primary, clickOpens } = props;
  const { basket } = useSelector(state => ({
    basket: state.ecommerce.items || [],
  }));
  const dispatch = useDispatch();

  const handleOpen = () => {
    if (clickOpens === "popup") {
      dispatch(appActions.popup.show('product', { product_slug: slug, size: 'large' }, true));
    } else {
      history.push(`/p/${slug}`);
    }
  }

  const basketItem = basket.find(el => el.price_id === props.id)

  return (
    <ZWrapper>
      <Container onClick={handleOpen}>
        <ImageContainer>
          {image_primary
            ? <Image
              src={image_primary.landscape}
              alt={name}
              img_width={320}
              img_height={240}
            />
            : <ImageBlank />
          }
        </ImageContainer>
        <Title container>
          <Title text>{name}</Title>
          {nature === "pre-order" ? <br /> : null}
          {nature === "product"
            ? null
            : <Title tag nature={nature}>{nature}</Title>
          }
        </Title>
        <Price>{price} {currency} {nature === "rental" ? `/ ${unit}` : null}</Price>
      </Container>

      {basketItem ?
        <InBasketLocation>
          <Button
            type="link"
            to="/checkout/basket"
            size="icon_small"
            icon="shopping-cart"
            color="secondary"
          />
        </InBasketLocation>
        : null}
    </ZWrapper>
  )

}

export default ProductList;
