import React from 'react';
import {
  Footer,
  Layout,
  LayoutMain,
  LayoutFooter,
} from '../../../ui-components';
import {
  ColumnContainer,
  // Wrapper,
} from '../components/components';
import AuthInvitationAcceptContainer from '../containers/auth-invitation-accept.container';



const AuthInvitationAcceptView = (props) => (
  <>
    <Layout id="layout" layout="simple">
      <LayoutMain>
        <ColumnContainer container>
          <ColumnContainer head>

          </ColumnContainer>
          <ColumnContainer details left>

            <AuthInvitationAcceptContainer {...props} />

          </ColumnContainer>
        </ColumnContainer>
      </LayoutMain>
      <LayoutFooter>
        <Footer {...props} />
      </LayoutFooter>
    </Layout>
  </>
);


export default AuthInvitationAcceptView;
