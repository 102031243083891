import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import { appActions } from '../../app/redux';
import { ecommerceActions } from '../redux';
import {
  Button,
} from "../../../ui-components";
import {
  BeTritonAPI,
  history,
} from '../../../utils';
import Calendar from '../components/calendar/calendar';
import {
  PriceTag,
} from '../components/product-details/product.styled';
import { CalendarContainer } from '../components/calendar/calendar.styled';


class EcommerceRentalCalendarContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      available_date_range: {
        from: "",
        till: "",
      },
      dates: [],
      reservation: {
        location_id: '',
        date_from: '',
        date_till: '',
        quantity: 0,
        hasReservation: false,
        // order_uuid: '',
      },
      productSlug: '',
      productId: '',
    };
  }

  componentDidMount = () => {
    if (this.props.productSlug) {
      this.getCalendar(this.props.productSlug);
      this.setState({
        productSlug: this.props.productSlug,
        productId: this.props.id,
      })
    } else {
      const productSlug = this.props.slug;
      this.getCalendar(productSlug)
      this.setState({
        productSlug,
        productId: this.props.id,
      })
    }
  }

  componentWillUnmount = () => {
  }

  componentDidUpdate = (prevProps) => {

  }

  getCalendar = async (slug) => {
    this.props.appRequest({
      domain: "rental",
      loading: true,
      error: null,
    })
    try {
      const res = await BeTritonAPI.get(`v1/rental/calendar/product/${slug}`, {
        headers: {
          'Content-Type': 'application/json',
        },
        params: {
          uuid: this.props.uuid,
        }
      })
      this.props.appRequestClear({ domain: "rental" })
      const { dates, location_id, reservation, available_date_range } = res.data;
      const hasReservation = reservation.order_uuid !== undefined;

      this.setState({
        reservation: {
          ...this.state.reservation,
          ...reservation,
          location_id,
          hasReservation,
        },
        available_date_range: {
          from: available_date_range.from,
          till: available_date_range.till,
        },
        dates,
      })
    } catch (err) {
      this.props.appRequest({
        domain: "rental",
        loading: false,
        error: err,
      })
      this.setState({
        validated: true,
      })
    }
  }

  handleReservation = ({ from, till, quantity }) => {
    this.setState({
      reservation: {
        ...this.state.reservation,
        date_from: from,
        date_till: till,
        quantity,
      },
    })
  }

  handleAdd = async () => {
    const orderItem = this.props.items.find(el => el.slug === this.state.productSlug);

    const meta = {
      rental: this.state.reservation
    }

    // console.log(meta)

    if (orderItem === undefined) {
      const quantity = this.state.reservation.quantity;
      await this.props.addItem(
        this.state.productId,
        quantity,
        JSON.stringify(meta)
      );
      // history.push('/checkout/user');
    } else {
      // const quantity = this.state.reservation;

      // const reservedDates = this.state.dates.find(el => el.order_uuid === this.props.uuid);
      // if (reservedDates === undefined) {

      // TODO add date_from, date_till to const meta = {}
      // await this.props.changeItem(
      //   orderItem.order_item_id,
      //   this.state.productId,
      //   quantity,
      //   JSON.stringify(meta)
      // );

      // }
      // history.push('/checkout/user');
    }
    if (this.props.items.length > 1) {
      history.push('/checkout/basket');
    } else {
      history.push('/checkout/user');
    }
  }

  render() {
    const dayCount = this.state.reservation.quantity;
    const { price, currency } = this.props;

    return (
      <CalendarContainer container>
        <CalendarContainer calendar>
          <Calendar
            dates={this.state.dates}
            available_date_range={this.state.available_date_range}
            handleReservation={this.handleReservation}
            productSlug={this.state.productSlug}
            disable={this.state.reservation.hasReservation}
          />
        </CalendarContainer>
        <CalendarContainer bottom>
          <CalendarContainer price>
            <PriceTag container>
              <PriceTag text>Total price:</PriceTag>
              <PriceTag price>
                {price * dayCount} {currency}
              </PriceTag>
            </PriceTag>

          </CalendarContainer>

          <CalendarContainer button>
            <Button
              type="button"
              size="large"
              color="secondary"
              nomargin
              onClick={this.handleAdd}
              disabled={this.props.loading || dayCount === 0}
            >
              Checkout
            </Button>
          </CalendarContainer>
        </CalendarContainer>
      </CalendarContainer>
    )
  }
}

const mapStateToProps = (state) => ({
  error: state.app.error?.rental || null,
  loading: state.app.loading?.rental || false,
  uuid: state.ecommerce.uuid || '',
  items: state.ecommerce.items || [],
  productSlug: state.app.popup.params.product_slug || '',
})

const mapDispatchToProps = (dispatch) => ({
  appRequest: (request) => dispatch(appActions.request(request)),
  appRequestClear: (request) => dispatch(appActions.requestClear(request)),
  addItem: (priceId, quantity, meta) => dispatch(ecommerceActions.item.addItem(priceId, quantity, meta)),
  changeItem: (orderItemId, priceId, quantity, meta) => dispatch(ecommerceActions.item.changeItem(orderItemId, priceId, quantity, meta)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EcommerceRentalCalendarContainer));
