import styled from 'styled-components';
import { Link } from 'react-router-dom';



export const BasketSnippetContainer = styled.div`
  padding: 16px;
  background: ${({ theme }) => theme.background.default};
  color: ${({ theme }) => theme.text.primary};
  text-align: left;
`;

export const BasketSnippetLink = styled(Link)`

`