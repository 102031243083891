import React from 'react';
import {
  Header,
  Footer,
  Layout,
  LayoutHeader,
  LayoutProgress,
  LayoutMain,
  LayoutFooter,
  ZWrapper,
} from '../../../ui-components';
import StepProgress from '../components/step-progress/step-progress';
import EcommerceProductContainer from '../containers/ecommerce-product.container';


const EcommerceProductView = (props) => (
  <Layout id="layout" layout="basket">
    <LayoutHeader>
      <Header />
    </LayoutHeader>
    <LayoutProgress>
      <StepProgress
        match={props.match}
        forcedStep={0}
      />
    </LayoutProgress>
    <LayoutMain>
      <ZWrapper>
        <EcommerceProductContainer match={props.match} />
      </ZWrapper>
    </LayoutMain>
    <LayoutFooter>
      <Footer />
    </LayoutFooter>
  </Layout>
);

export default EcommerceProductView;
