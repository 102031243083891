import React from 'react';
import { connect } from 'react-redux';
import { appActions } from '../../app/redux';
import { ecommerceActions } from '../redux';
import {
  CheckoutWrapper,
  Title,
} from '../components/checkout-wrapper/checkout-wrapper';
import {
  // Button,
} from '../../../ui-components';
import {
  history,
  StipeApiPublicKey,
} from '../../../utils';
import {
  Elements,
} from '@stripe/react-stripe-js';
import {
  loadStripe,
} from "@stripe/stripe-js";
import {
  ErrorText,
} from '../../../ui-components';
import 'regenerator-runtime/runtime';
import {
  PaymentForm,
  TrustElements,
} from '../components/payment-form';



// https://linguinecode.com/post/integrate-stripe-payment-form-with-react


const stripePromise = loadStripe(StipeApiPublicKey);

class EcommercePaymentContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: ''
    };
  }


  componentDidMount = () => {
    if (this.props.clientSecret === '' && this.props.orderUUID !== '') {
      this.props.getClientSecret();
    }

    if(this.props.orderUUID === ''){
      history.push('/')
    }
  }

  handlePrev = (e) => {
    history.push("/checkout/user");
    window.scroll({ top: 0 });
  }

  handleNext = (e) => {
    this.props.completeOrder();

    history.push("/checkout/success");
    window.scroll({ top: 0 });
  }

  handleError = (error) => {
    // console.log(error)
    this.setState({
      error: error?.message || '',
    })
  }

  render() {
    const { user, shipping, errorOrder, clientSecret } = this.props;
    const billingDetails = {
      name: user.name,
      email: user.email,
      address: {
        line1: shipping.address,
        line2: shipping.address_2,
        city: shipping.city,
        country: shipping.country,
        postal_code: shipping.postal_code,
        state: shipping.state,
      }
    };

    return (
      <CheckoutWrapper
        loading={this.props.loading}
        error={errorOrder}
      >
        <Elements stripe={stripePromise}>
          <Title>Payment</Title>
          {this.state.error !== '' ? <ErrorText>{this.state.error}</ErrorText> : null}
          <PaymentForm
            clientSecret={clientSecret}
            billingDetails={billingDetails}
            onError={this.handleError}
            onCancel={this.handlePrev}
            onSuccess={this.handleNext}
          >
            <TrustElements />
          </PaymentForm>
        </Elements>
      </CheckoutWrapper>
    )
  }
}

const mapStateToProps = (state) => ({
  errorOrder: state.app.error?.ecommerce || null,
  error: state.app.error?.ecommerce_user || null,
  loading: state.app.loading?.ecommerce_user || false,
  user: state.ecommerce.user || {},
  shipping: state.ecommerce.shipping || {},
  clientSecret: state.ecommerce.client_secret || '',
  orderUUID: state.ecommerce.uuid,
})

const mapDispatchToProps = (dispatch) => ({
  appRequest: (request) => dispatch(appActions.request(request)),
  appRequestClear: (request) => dispatch(appActions.requestClear(request)),
  basketAddItem: (id, quantity) => dispatch(ecommerceActions.item.addItem(id, quantity)),
  getClientSecret: () => dispatch(ecommerceActions.user.getClientSecret()),
  completeOrder: () => dispatch(ecommerceActions.order.complete()),
})

export default connect(mapStateToProps, mapDispatchToProps)(EcommercePaymentContainer);
