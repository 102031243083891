import React, { useState } from 'react';
import {
  Header,
  Footer,
  Layout,
  LayoutHeader,
  LayoutMain,
  LayoutFooter,
  PageTitle,
  ZWrapper,
  Button,
} from '../../../ui-components';
import MarketingSurveyContainer from '../containers/marketing-survey.container';
import styled from "styled-components";

const Iframe = styled.iframe`
  width: 100%;
  height: 353px;

  @media ${({ theme }) => theme.device.phone}{
    height: 250px;
  }
`;

const CROWDFUNDING_VIDEO = "https://www.youtube.com/embed/W4bl5Q6ooaw?autoplay=1&mute=0&controls=1&showinfo=0";


const MarketingCrowdfundingView = (props) => {
  const [showVideo, setShowVideo] = useState(false);
  return (
    <Layout id="layout" layout="simple">
      <LayoutHeader>
        <Header />
      </LayoutHeader>
      <LayoutMain>
        <PageTitle>Equity Crowdfunding</PageTitle>
        <div style={{ textAlign: "center ", padding: "0 8px"}}>
          <p> Due to the significant interest in BeTriton, we’re prioritizing the production of our first batch of units. BeTriton community has been steadily growing, and we’re excited to offer our fans the chance to support our continued expansion by becoming shareholders of the company. Currently, we’re gearing up for an equity crowdfunding campaign on a major platform and conducting a survey across our networks to see how many people would be interested in investing in BeTriton (starting from as little as 100 EUR)</p>
          <p> You can join our early access group by submitting your details via the form below. Please note that expressing interest does not obligate you to make an investment. </p>
          <p> By registering, you’ll be among the first to receive updates about our fundraising plans. You’ll also have the opportunity to learn more about the company, the investment opportunity, community rewards, and gain early access to our campaign.  </p>
        </div>
        <div style={{ margin: "40px 16px",  textAlign: "center" }}>
          <div style={{  maxWidth: "660px", margin: "auto" }}>
            <ZWrapper>
              {showVideo ? <Iframe frameBorder="0" allowFullScreen src={CROWDFUNDING_VIDEO}></Iframe>
              : (<>
                <Button 
                  key="button_key"
                  type="button"
                  color="primary"
                  size="large"
                  inline 
                  onClick={() => setShowVideo(!showVideo)}>Video</Button>
                </>)
              }
            </ZWrapper>
            <br />
            <MarketingSurveyContainer slug="crowdfunding" ignoreEmail={true} />
          </div>
        </div>
        <br />
        <div style={{ textAlign: "center", opacity: "0.7",padding: "0 8px" }}>
          <p>Disclaimer: The information contained in the video and this page is not intended as a financial promotion, and should not be viewed as a recommendation or advice to participate in an investment offering.  </p>
          <p>When investing in early-stage companies, your capital is at risk. You should not invest in early-stage companies unless you’re prepared to lose all the money you invest. Early-stage investments carry a high-risk and you are unlikely to be protected if something goes wrong.</p>
        </div>
      </LayoutMain>
      <LayoutFooter>
        <Footer />
      </LayoutFooter>
    </Layout>
  )
}

export default MarketingCrowdfundingView;
