import React from 'react';
import styled from "styled-components";


const Container = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  min-width: 180px;
  max-width: 360px;
  bottom: 0;
  background: ${({ theme }) => theme.background.paper};
  box-shadow: -1px 0 4px 0px rgb(0 0 0 / 6%);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  padding: 32px;
  min-height: 100vh;
  overflow-y: auto;
`;

export const Sidebar = (props) => {
  return (
    <Container>
      SideBar
    </Container>
  )
}