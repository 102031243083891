import appActionType from './app.types';
import { simplifyError } from '../../../utils/api';


const addModalMessage = (color, text, timeout = 1000) => ({
  type: appActionType.MODAL_ADD_MESSAGE,
  color,
  text,
  timeout,
})

const removeModalMessage = (index = 0) => ({
  type: appActionType.MODAL_REMOVE_MESSAGE,
  index,
})


const appRequest = ({ domain, loading, error }) => {
  return dispatch => {
    const parsedError = error ? simplifyError(error) : error;
    dispatch({
      type: appActionType.REQUEST,
      domain,
      loading,
      error: parsedError || null,
    });
  }
}

const appRequestClear = ({ domain }) => ({
  type: appActionType.REQUEST_CLEAR,
  domain,
})


const popupShow = (name, params, fixed = false) => {
  return (dispatch) => {
    if (fixed) {
      if (document.getElementById("layout"))
        document.getElementById("layout").style.position = 'fixed';
    }
    dispatch({
      type: appActionType.POPUP_SHOW,
      name,
      params,
      fixed,
    })
  }
}

const popupHide = () => {
  return dispatch => {
    if (document.getElementById("layout"))
      document.getElementById("layout").style.position = 'relative';
    dispatch({
      type: appActionType.POPUP_HIDE,
    });
  }
}

const sidebarShow = ({ sidebar }) => ({
  type: appActionType.SIDEBAR_SHOW,
  sidebar,
})

const sidebarHide = () => ({
  type: appActionType.SIDEBAR_HIDE,
})



export const appActions = {
  request: appRequest,
  requestClear: appRequestClear,
  modal: {
    addMessage: addModalMessage,
    removeMessage: removeModalMessage
  },
  popup: {
    show: popupShow,
    hide: popupHide,
  },
  sidebar: {
    show: sidebarShow,
    hide: sidebarHide,
  },
}

export default appActions;
