import React from 'react';
import {
  Header,
  Footer,
  Layout,
  LayoutHeader,
  LayoutMain,
  LayoutFooter,
  PageTitle,
} from '../../../ui-components';
// import ProductSidebar from '../components/product-sidebar/product-sidebar';
import EcommerceProductListContainer from '../containers/ecommerce-product-list.container';


const EcommerceShopView = () => (
  <Layout id="layout" layout="simple">
    <LayoutHeader>
      <Header />
    </LayoutHeader>
    <LayoutMain>
      <PageTitle>Shop</PageTitle>
      <EcommerceProductListContainer
        clickOpens="page"
      />
    </LayoutMain>
    <LayoutFooter>
      <Footer />
    </LayoutFooter>
  </Layout>
);

export default EcommerceShopView;
