import React from 'react';
import { connect } from 'react-redux';
import { appActions } from '../../app/redux';
import { userActions } from '../redux';
import { authActions } from '../../auth/redux';
import {
  BeTritonAPI
} from '../../../utils';
import styled from "styled-components";
import {
  Tos,
  TosContent,
} from '../../ecommerce/components/checkout-wrapper/checkout-wrapper';
import {
  Button,
  Checkbox,
  ZWrapper,
  ZTitle,
} from "../../../ui-components";
import {
  PreOrderProduct,  
} from '../components/preorder-product';


const Description = styled.div`
  font-size: 14px;
  line-height: 19px;
  color: ${({ theme }) => theme.text.secondary};
  text-align: left;
  padding: 0 4px;
`;

const Padding = styled.div`
  padding: 8px;
`

const DocumentContent = styled.div`
  min-width: 820px;
  font-size: 16px;
  line-height: 21px;

  @media ${({ theme }) => theme.device.mobile}{
    min-width: auto;
  }

  h1{
    margin: 0 0 20px 0;
  }

  p{
    margin: 20px 0;
    font-size: 16px;
    line-height: 21px;
  }
`;

const Profile = styled.div`
  padding: 8px 8px 16px 24px;
  font-size: 14px;
  line-height: 19px;

`;

const Checksum = styled.div`
  margin: 20px 0 10px;
  font-size: 12px;
  color: ${({ theme }) => theme.text.secondary};
  padding: 8px;
  /* border: 1px solid ${({ theme }) => theme.text.secondary}; */
  background-color: ${({ theme }) => theme.background.default};
`;

const Signature = styled.div`
  font-size: 14px;
  background-color: ${({ theme }) => theme.background.default};
  padding: 8px;
  margin: 0 0 20px 0;
`;

class UserPurchaseAgreementContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      content: "",
      checksum: "",
      signature: null,
      version: "",
      tos: false,
      agreement_signed: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount = () => {
    this.getDocument();
  }

  getDocument = async () => {
    this.props.appRequest({
      domain: "user",
      loading: true,
      error: null,
    })

    try {
      const res = await BeTritonAPI.get(`v1/legal/document/purchase_agreement/`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${this.props.token}`,
        }
      })

      this.setState({
        content: res.data.content,
        checksum: res.data.checksum,
        signature: res.data.signature,
        version: res.data.version,
      })

      this.props.appRequestClear({ domain: "user" })


    } catch (err) {
      this.props.appRequest({
        domain: "user",
        loading: false,
        error: err,
      })
    }
  }

  handleTosChange = () => {
    this.setState({
      tos: !this.state.tos,
    })
  }


  handleSubmit = async (event) => {
    event.preventDefault();
    this.props.appRequest({
      domain: "user",
      loading: true,
      error: null,
    })

    try {
      const res = await BeTritonAPI.post(`v1/legal/document/purchase_agreement/`, {}, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${this.props.token}`,
        }
      })

      this.props.appRequestClear({ domain: "user" })

      this.setState({
        signature: res.data.signature,
      })



    } catch (err) {
      this.props.appRequest({
        domain: "user",
        loading: false,
        error: err,
      })
    }
  }

  render() {
    // const error = this.props.error || {};

    const signature = this.state.signature || {};
    const signed = this.state.signature !== null



    if (this.state.password_changed) {
      return (
        <ZWrapper>
          <Padding>
            <ZTitle>Success</ZTitle>
            <Description>You have sucessfully signed the agreement!</Description>
          </Padding>
        </ZWrapper>
      )

    }


    return (
      <>
        <Profile>
          <span style={{ "float": "right" }}>
            <Button
              type="button"
              onClick={this.props.logout}
              size="small"
              color="default"
            >
              Logout
            </Button>
          </span>
          Email: <strong>{this.props.user.email}</strong><br />
          {this.props.user.name && <span>Name: <strong>{this.props.user.name}</strong></span>}<br />
        </Profile>

        <PreOrderProduct />
        <br />
        
        <ZWrapper>
          <Padding>
            {signed &&
              <Signature>Document has been signed: {signature.signed_at} </Signature>
            }
            <DocumentContent dangerouslySetInnerHTML={{ __html: `<span>${this.state.content}</span>` }} />

            {signed ? <>
              <br />
              <Signature>Document has been signed: {signature.signed_at} </Signature>
            </> :
              <>
                <Checksum>Document hash checksum: {this.state.checksum}</Checksum>
                <br />

                <Tos container>
                  <TosContent checkbox>
                    <Checkbox
                      checked={this.state.tos}
                      onChange={() => this.handleTosChange()}
                    />
                  </TosContent>
                  <TosContent text>
                    I have read and accept the terms and conditions above
                  </TosContent>
                </Tos>

                <Button
                  type="button"
                  onClick={this.handleSubmit}
                  size="large"
                  color={this.state.tos ? "secondary" : "background"}
                  disabled={!this.state.tos || this.props.loading}
                  fullwidth
                >
                  Sign Contract
                </Button>
              </>
            }
          </Padding>
        </ZWrapper>
      </>
    )
  }
}

const mapStateToProps = state => ({
  error: state.app.error?.user,
  loading: state.app.loading?.user,
  user: state.user || {},
  token: state.auth.token,
})

const mapDispatchToProps = dispatch => ({
  appRequest: (request) => dispatch(appActions.request(request)),
  appRequestClear: (request) => dispatch(appActions.requestClear(request)),
  getUser: () => dispatch(userActions.get()),
  logout: () => dispatch(authActions.logout()),
})

export default connect(mapStateToProps, mapDispatchToProps)(UserPurchaseAgreementContainer);
