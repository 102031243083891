import React from 'react';
import {
  Footer,
  Layout,
  LayoutMain,
  LayoutFooter,
} from '../../../ui-components';
import {
  ColumnContainer,

} from '../components/components';
import AuthLoginContainer from '../containers/auth-login.container';


const AuthLoginView = (props) => (

  <>
    <Layout id="layout" layout="simple">
      <LayoutMain>
        <ColumnContainer container>
          <ColumnContainer head>
          </ColumnContainer>
          <ColumnContainer details left>
            <AuthLoginContainer />
          </ColumnContainer>
        </ColumnContainer>
      </LayoutMain>
      <LayoutFooter>
        <Footer {...props} />
      </LayoutFooter>
    </Layout>
  </>

);


export default AuthLoginView;
