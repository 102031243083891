import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { appActions } from '../../redux';
import {
  Container,
  Message,
} from './modal.styled';



const REMOVE_ANIMATION_INTERVAL = 350;

const Modal = (props) => {
  const [message, setMessage] = useState('');
  const [showing, setShowing] = useState(false);
  const { allMessages } = useSelector(state => ({
    allMessages: state.app.modal.messages,
  }));
  const dispatch = useDispatch();

  useEffect(() => {
    if (allMessages.length > 0) {
      setMessage(allMessages[0]);
    }
  }, [message, allMessages])


  useEffect(() => {
    if (message !== '') {

      const startShowing = () => {
        // console.log("showing", message);
        setShowing(true);

        setTimeout(() => {
          removeMessage();
        }, message.timeout)
      }


      const removeMessage = () => {
        setShowing(false)

        setTimeout(() => {
          dispatch(appActions.modal.removeMessage());
          setMessage('');
        }, REMOVE_ANIMATION_INTERVAL)
      }

      startShowing();
    }
  }, [message, dispatch])


  return (
    <>
      {message !== '' ?
        <Container showing={showing}>
          <Message
            color={message.color}
          >
            {message.text}
          </Message>
        </Container>
        : ''
      }
    </>
  )
}


export default Modal;

