import styled, { css } from 'styled-components';

export const ListContainer = styled.div`
  margin-bottom: 24px;
  margin-left: auto;
  margin-right: auto;

  @media only screen and (max-width: 980px){
    max-width: 640px;
    padding-right: 8px;
  }

  @media ${({ theme }) => theme.device.mobile}{
    max-width: 640px;
    padding-right: 8px;
  }

  @media ${({ theme }) => theme.device.tabletPortrait}{
    max-width: 328px;
  }

  @media ${({ theme }) => theme.device.phone}{
    max-width: 328px;
  }


  @media only screen and (max-width: 328){
    margin-left: 10px;
    margin-right: 10px;
  }
`;


export const ListTitle = styled.div`
  padding: 8px;
  font-family: 'Quantico', sans-serif;
  font-size: 16px;
  line-height: 23px;
  /* text-transform: uppercase; */

  @media only screen and (max-width: 980px){
    text-align: center;
  }

  
`

export const ListProducts = styled.div`
  display: grid;
  grid-gap: 4px;
  grid-template-columns: 1fr 1fr 1fr;


  @media only screen and (max-width: 980px){
    grid-template-columns: 1fr 1fr;
    margin: auto;
  }

  @media ${({ theme }) => theme.device.mobile}{
    grid-template-columns: 1fr 1fr;
  }

  @media ${({ theme }) => theme.device.tabletPortrait}{
    grid-template-columns: 1fr;
    text-align: center;
  }


  @media ${({ theme }) => theme.device.phone}{
    grid-template-columns: 320px;
    /* border: 1px solid red; */
    text-align: center;
  }
`;

export const ListEmptyWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const Container = styled.div`
  height: 100%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: 0;
  
`

export const ImageContainer = styled.div`
  flex: 1;
  min-height: 220px;
  height: 220px;
  max-height: 220px;
  overflow: hidden;
  text-align: center;
  position: relative;
  margin: -8px -8px 0;
  /* border: 1px solid red; */
`;


export const Image = styled.img`
  position: absolute;
  top: -9999px;
  bottom: -9999px;
  left: -9999px;
  right: -9999px;
  margin: auto;
  width: ${props => props.img_width}px;
  height: ${props => props.img_height}px;
`

export const ImageBlank = styled.div`
  flex: 1;
  background: ${({ theme }) => theme.background.default};
`

export const Title = styled.div`
${props => props.container && css`
  /* padding: 4px 0 0 0; */
  margin: 8px 0 4px;
`}

${props => props.text && css`
  display: inline-block;
  font-family: 'Quantico', sans-serif;
  font-size: 16px;
  line-height: 23px;
  text-transform: uppercase;
  margin-right: 8px;
  color: ${({ theme }) => theme.primary.main};
  &:hover{
    color: ${({ theme }) => theme.primary.dark};
  }
`}

${props => props.tag && css`
  display: inline-block;
  font-size: 10px;
  position: relative;
  top: -2px;
  padding: 2px 6px;
  text-transform: uppercase;
  border-radius: 2px;


  ${props => props.nature === "rental" && css`
    background: ${({ theme }) => theme.thirdiary.main};
    color: ${({ theme }) => theme.text.white};
  `}

  ${props => props.nature === "pre-order" && css`
    background: ${({ theme }) => theme.secondary.main};
    color: ${({ theme }) => theme.text.primary};
  `}
`}


`

export const Price = styled.div`
  font-size: 15px;
  /* padding: 4px 0; */
  color: ${({ theme }) => theme.text.secondary};
`

export const InBasketLocation = styled.div`
  position: absolute;
  top: 0px;
  right: -4px;
`;