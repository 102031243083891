import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import LogoImageSrc from '../../assets/img/BeTRITON-black.png';
import LogoSimpleImageSrc from '../../assets/img/logo-simple.png';
import {
  Shadow,
  Wrapper,
  Container,
  Grid,
  Logo,
  LogoSimple,
  Placeholder,
  ButtonLine,
  LogoButton,
} from './header.styled';
import {
  Button,
} from '../../ui-components';
import HeaderMenu from "./header-menu";
import HeaderBasket from "./header-basket";
import HeaderSocial from './header-social';
// import HeaderSubscribe, { SubscribeButton } from "./header-subscribe";
import HeaderSubscribe from "./header-subscribe";
import {
  beLandingURL,
  beLink,
  beLinkType,
} from '../../utils';
import MENU_LIST from './header-menu.json';
import SOCIAL_LIST from './header-social.json';

const STARTING_POS = 50;

const Header = ({ checkout, disableSubscribe = false }) => {
  const [hidden, setHidden] = useState(false);
  const [menu, setMenu] = useState("none");
  const [order, setOrder] = useState({
    state: '',
    uuid: '',
    items: [],
    itemCount: 0,
    totalPrice: '',
    currency: '',
  })

  const [prevPos, setPrevPos] = useState();

  const { state, uuid, items, itemCount, totalPrice, currency } = useSelector((state) => ({
    state: state.ecommerce.state,
    uuid: state.ecommerce.uuid,
    items: state.ecommerce.items,
    itemCount: state.ecommerce.total_items,
    totalPrice: state.ecommerce.total_price,
    currency: state.ecommerce.currency,
  }))

  useEffect(() => {
    if (state) {
      setOrder((state) => ({
        state: state || '',
        uuid: uuid || '',
        items: items || [],
        itemCount: itemCount || 0,
        totalPrice: totalPrice || '',
        currency: currency || '',
      }))
    }

  }, [state, uuid, items, itemCount, totalPrice, currency])


  useEffect(() => {
    setPrevPos(window.scrollY);
  }, [])

  const detectScroll = () => {
    const currPos = window.scrollY; //event.target.documentElement.scrollTop;
    const deltaPos = prevPos - currPos;
    const deltaPosPositive = deltaPos < 0 ? deltaPos * -1 : deltaPos;

    if (currPos > STARTING_POS && deltaPosPositive > 4) {
      if (deltaPos < 0) {
        setHidden(true);
      } else {
        setHidden(false);
      }
    } else if (currPos <= STARTING_POS) {
      setHidden(false);
    }
    setPrevPos(currPos);
  };

  useEffect(() => {
    window.addEventListener('scroll', detectScroll);
    window.addEventListener('touchmove', detectScroll);
    return () => {
      window.removeEventListener('scroll', detectScroll);
      window.removeEventListener('touchmove', detectScroll);
    };
  });

  const handleMenu = (nature) => {
    setMenu(nature === menu ? "none" : nature)
  }

  // const { state, uuid, items, itemCount, totalPrice, currency } = order;

  return (
    <>
      {(menu === "none" || menu === "subscribe") ? null : <Shadow />}
      <HeaderSubscribe
        activeMenu={menu}
        toogleVisibility={handleMenu}
      />
      <Wrapper hidden={hidden}>
        <Container>
          <Grid container>
            <Grid logo>
              <LogoButton
                href={beLandingURL}
                hideInPhone={itemCount > 0}
              >
                <Logo src={LogoImageSrc} alt="BeTRITON" />
                <LogoSimple src={LogoSimpleImageSrc} alt="BeTRITON" />
              </LogoButton>
            </Grid>
            <Grid space />
            <Grid social>
              <HeaderSocial
                socialList={SOCIAL_LIST}
                openSubscribe={() => { handleMenu("subscribe") }}
              />
            </Grid>
            <Grid basket>

              <HeaderBasket
                activeMenu={menu}
                checkout={checkout}
                toogleVisibility={handleMenu}
                order={order}
              >
                <ButtonLine container>
                  <ButtonLine item>
                    <Button
                      type={beLinkType("app")}
                      to={beLink("app", "/rent")}
                      color="primary"
                      size="normal"
                      fullwidth
                    >
                      Rent
                    </Button>
                  </ButtonLine>
                  <ButtonLine item>
                    <Button
                      type={beLinkType("app")}
                      to={beLink("app", "/preorder")}
                      color="secondary"
                      size="normal"
                      fullwidth
                    >
                      Pre-Order
                    </Button>
                  </ButtonLine>
                </ButtonLine>
              </HeaderBasket>
            </Grid>
            <Grid menu>
              <HeaderMenu
                activeMenu={menu}
                toogleVisibility={handleMenu}
                menuList={MENU_LIST}
                socialList={SOCIAL_LIST}
                showSubscribe={() => { handleMenu("subscribe") }}
              />
            </Grid>
          </Grid>
        </Container>
      </Wrapper>
      <Placeholder />
      {/* {!disableSubscribe && menu === "none" && !checkout ? <SubscribeButton showSubscribe={() => { handleMenu("subscribe") }} /> : null} */}
    </>
  )
}

export default Header;
