import React from 'react';
import {
  Header,
  Footer,
  Layout,
  LayoutHeader,
  LayoutMain,
  LayoutProgress,
  LayoutFooter,
} from '../../../ui-components';
import StepProgress from '../components/step-progress/step-progress';
import EcommerceBasketContainer from '../containers/ecommerce-basket.container';


const EcommerceBasketView = (props) => (
  <Layout id="layout" layout="basket">
    <LayoutHeader>
      <Header checkout={true} />
    </LayoutHeader>
    <LayoutProgress>
      <StepProgress match={props.match} />
    </LayoutProgress>
    <LayoutMain>
      <EcommerceBasketContainer />
    </LayoutMain>
    <LayoutFooter>
      <Footer />
    </LayoutFooter>
  </Layout>
);

export default EcommerceBasketView;
