import React from 'react';
import { EcommerceProductView } from '..';



const DEFAULT_PREORDER_SLUG = 'latvia-valmiera';

const EcommerceRentalSingleView = (props) => (
  <EcommerceProductView
    {...props}
    match={{
      ...props.match,
      params: {
        ...props.match.params,
        product_slug: DEFAULT_PREORDER_SLUG,
      }
    }} />
)


export default EcommerceRentalSingleView;
