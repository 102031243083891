import React from 'react';
import {
  Contanier,
  SocialText,
  // SocialButton,
  SocialLink,
} from './header-social.styled';
import {
  Icon,
} from '..';


const SOCIAL_ICON_SIZE = 18;

const HeaderSocial = ({ socialList, openSubscribe }) => (
  <Contanier>
    <SocialText>
      Subscribe:
    </SocialText>
    {/* <SocialButton onClick={openSubscribe}>
      <Icon
        name="email-outline"
        width={SOCIAL_ICON_SIZE}
        height={SOCIAL_ICON_SIZE}
        noFill
      />
    </SocialButton> */}
    {socialList.map((social, index) => (
      <SocialLink
        key={index}
        href={social.url}
        target="_blank"
      >
        <Icon
          name={social.icon}
          size={social.icon === "youtube" ? SOCIAL_ICON_SIZE : SOCIAL_ICON_SIZE - 4}
          noFill
        />
      </SocialLink>
    ))}
  </Contanier>
);

export default HeaderSocial;
