
import styled, { css } from "styled-components";


export const Container = styled.div`
  padding: 8px;
`;

export const Title = styled.div`
  font-family: 'Quantico', sans-serif;
  text-transform: uppercase;
  color: ${({ theme }) => theme.primary.main};
  margin-bottom: 16px;
`;

export const ItemCount = styled.div`
  background: ${({ theme }) => theme.background.paper};
  font-size: 12px;
  color: ${({ theme }) => theme.text.disabled};
  margin: 8px 0;
`;


export const Divider = styled.hr`
  border: 0;
  border-top: 1px solid #eee;
  margin: 8px 0;
`;

export const Entry = styled.div`

  ${props => props.row && css`
    display: flex;
    flex-direction: row;
    font-size: 14px;
    line-height: 15px;
    color: ${({ theme }) => theme.text.secondary};
    margin: 6px 0;

    ${props => props.styled && css`
      font-family: 'Quantico', sans-serif;
      text-transform: uppercase;
      color: ${({ theme }) => theme.text.primary};
    `} 
  `}

  ${props => props.left && css`
    flex: 1;
    /* padding: 2px 4px; */
  `}

  ${props => props.right && css`
    text-align: right;
    /* padding: 2px 4px; */
  `}

  ${props => props.textLine && css`
    display: flex;
    
  `}
  ${props => props.textAmount && css`
    /* align-self: center; */
    font-size: 13px;
    padding-right: 5px;
  `}
  ${props => props.text && css`
    flex: 1;
    padding-right: 4px;
    /* color: ${({ theme }) => theme.text.primary}; */
    
  `}
`;

export const Meta = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.text.disabled};
  margin-bottom: 4px;
  font-style: italic;
`;