import React from 'react';
import { useSelector } from 'react-redux';
import {
  BasketSnippetContainer,
  BasketSnippetLink,
} from './basket-snippet.styled';
import {
  Icon,
} from '../../../../ui-components';


export const BasketSnippet = ({ id }) => {
  const { items } = useSelector(state => ({
    loading: state.app.loading?.ecommerce || false,
    items: state.ecommerce.items || [],
  }));


  const basketItem = items.find(el => el.price_id === id)

  if (basketItem)
    return (
      <BasketSnippetContainer>
        There {basketItem.quantity === 1 ? 'is' : 'are'}{' '}
        <strong>{basketItem.quantity} x {basketItem.name}{basketItem.quantity === 1 ? '' : '`s'}</strong>
        {' '}in your{' '}
        <Icon
          name="shopping-cart"
          size={14}
          noFill
        />
        {' '}basket, proceed to{' '}
        <BasketSnippetLink to="/checkout/basket">
          Checkout
        </BasketSnippetLink>
        .
      </BasketSnippetContainer>
    )

  return null;
}

export default BasketSnippet;
