import React from 'react';
import { connect } from 'react-redux';
import { ecommerceActions } from '../redux';
import {
  // LoadingSpinner,
  ZWrapper,
  Button,
} from "../../../ui-components";
import {
  // BeTritonAPI,
  // history,
} from '../../../utils';


class EcommerceFailureContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
    };
  }

  componentDidMount = () => {
    this.props.clearOrder()
  }

  componentWillUnmount = () => {
  }

  componentDidUpdate = (prevProps) => {

  }

  render() {

    return (

      <ZWrapper color="white" size="normal">
        Failure.

        Your order has been rejected. Probably because of rental timeout.
        <Button
          type="link"
          to="/shop"
          color="primary"
          size="normal"
          inline
        >Back to the Store</Button>

      </ZWrapper>
    )
  }
}

const mapStateToProps = (state) => ({
  errorDeal: state.app.error?.ecommerce || null,
  loadingDeal: state.app.loading?.ecommerce || false,
})

const mapDispatchToProps = (dispatch) => ({
  clearOrder: () => dispatch(ecommerceActions.order.clear()),
})

export default connect(mapStateToProps, mapDispatchToProps)(EcommerceFailureContainer);
