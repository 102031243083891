import React from 'react';
import { useSelector } from 'react-redux';
import {
  Stepper,
  Step,
  StepDivide,
  StepIcon,
  StepLabel,
} from './step-progress.styled';
import {
  history
} from '../../../../utils';


export const STEPS = {
  '/checkout/basket': "Information",
  '/checkout/user': "Contact Details",
  '/checkout/payment': "Payment",
}

const StepProgress = ({ match, forcedStep }) => {
  const { orderState, items } = useSelector((state) => ({
    orderState: state.ecommerce.state,
    items: state.ecommerce.items,
  }))
  const stepPath = [];
  const stepTitle = [];
  const stepIcons = [];
  const stepProgress = [];
  const { path } = match;
  let nr = 1;

  let now = Object.keys(STEPS).findIndex(el => el === path)
  if (forcedStep !== undefined) {
    now = forcedStep;
  }

  const stepCount = Object.keys(STEPS).length;

  Object.entries(STEPS).forEach((element, index) => {
    stepPath.push(element[0])
    stepTitle.push(element[1])
    stepIcons.push(nr);

    if (now === nr - 1) {
      stepProgress.push('active');
    } else if (now > nr - 1) {
      stepProgress.push('completed');
    } else {

      if (["payment_method", "payment"].includes(orderState)) {
        stepProgress.push('default');

      } else if (["new"].includes(orderState) && nr < 3) {
        stepProgress.push('default');


      } else {
        stepProgress.push('inactive');
      }

    }
    nr++;

    if (index < stepCount - 1) {
      stepPath.push('--');
      stepTitle.push('--');
      stepIcons.push('--');
      stepProgress.push('--');
    }
  });


  const handleClick = (index) => {
    const state = stepProgress[index]
    if (now !== index) {
      if (["completed", "active", "default"].includes(state)) {
        if (index === 0 && items.length === 1) {
          history.push(`/p/${items[0].slug}`);
        } else {
          history.push(stepPath[index])
        }
      }
    }
  }

  return (
    <Stepper>
      {stepTitle.map((element, key) => (
        (element === '--')
          ? <StepDivide key={key} />
          : (
            <Step
              key={element}
              state={stepProgress[key]}
              onClick={() => { handleClick(key) }}
            >
              <StepIcon state={stepProgress[key]}>
                {stepIcons[key]}
              </StepIcon>
              <StepLabel state={stepProgress[key]}>{element}</StepLabel>
            </Step>
          )
      ))}
    </Stepper>
  );
}

export default StepProgress;
