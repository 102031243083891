import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import { appActions } from '../../app/redux';
import {
  BeTritonAPI,
  countries,
  beLandingURL,
} from '../../../utils';
import {
  Title,
  Footer,
  Form,
} from '../../ecommerce/components/checkout-wrapper/checkout-wrapper';
import {
  ZWrapper,
  Button,
  TextField,
  Select,
  SelectOption,
  ZTitle,
} from "../../../ui-components";
import styled from "styled-components";


const Container = styled.div`
  padding: 8px;
`;
const SurveyDescription = styled.div`
  text-align: left;
  font-size: 15px;
  line-height: 19px;;
  margin: 16px 0 32px;
  color: ${({ theme }) => theme.text.primary};
`;



class CommunityDealershipContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',

      title: '',
      crn: '',
      region: '',
      website: '',
      nature: '',
      market: '',
      representation: '',

      address: '',
      address_2: '',
      city: '',
      state: '',
      country: '',
      postal_code: '',
      submitted: false,
    };
  }

  submit = async (payload) => {
    this.props.appRequest({
      domain: "community_dealership",
      loading: true,
      error: null,
    })
    try {
      await BeTritonAPI.post(`v1/user/community-role/dealership/`,
        {
          ...payload,
        }
        , {
          headers: {
            'Content-Type': 'application/json',
          }
        })
      this.setState({
        submitted: true,
      })
      this.props.appRequestClear({ domain: "community_dealership" })
    } catch (err) {
      this.props.appRequest({
        domain: "community_dealership",
        loading: false,
        error: err,
      })
    }
  }


  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    })
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const payload = {
      name: this.state.name,
      email: this.state.email,
      title: this.state.title,
      crn: this.state.crn,
      region: this.state.region,
      website: this.state.website,
      nature: this.state.nature,
      market: this.state.market,
      representation: this.state.representation,
      address: this.state.address,
      address_2: this.state.address_2,
      city: this.state.city,
      state: this.state.state,
      country: this.state.country,
      postal_code: this.state.postal_code,
    }
    this.submit(payload);
  }

  render = () => {
    const { submitted } = this.state;
    const error = this.props.error || {};

    if (submitted) {
      return (
        <ZWrapper color="white" size="normal" padding>


          <ZTitle align="left" size="large">Success!</ZTitle>
          <SurveyDescription>Your application has been submitted.</SurveyDescription>
          <Button
            type="anchor"
            target="_self"
            color="secondary"
            size="normal"
            to={beLandingURL}
          >
            Go to home page
          </Button>
        </ZWrapper>
      )
    }

    return (
      <ZWrapper color="white" size="normal">
        <Container>
          <Title>User information</Title>

          <Form container>
            <TextField
              id="name"
              type="text"
              label="Full Name"
              name="name"
              hasError={error['name']}
              value={this.state.name}
              onChange={this.handleChange}
              required
            />
            <TextField
              id="email"
              label="Email Address"
              name="email"
              type="email"
              hasError={error['email']}
              value={this.state.email}
              onChange={this.handleChange}
              required
            />
          </Form>
          <Title>Company Details</Title>

          <Form container>
            <TextField
              id="title"
              type="text"
              label="Title"
              name="title"
              hasError={error['title']}
              value={this.state.title}
              onChange={this.handleChange}
              required
            />
            <TextField
              id="crn"
              label="Company Reg. No."
              name="crn"
              type="text"
              hasError={error['crn']}
              value={this.state.crn}
              onChange={this.handleChange}
            />
            <Select
              id="region"
              label="Business region"
              name="region"
              onChange={this.handleChange}
              hasError={error['region']}
              value={this.state.region}
              required
            >
              <SelectOption value=""></SelectOption>
              {countries.map((data, index) =>
                <SelectOption value={data.code} key={index}>{data.name}</SelectOption>
              )}
            </Select>

            <TextField
              id="nature"
              label="What industry is your business operating in?"
              name="nature"
              type="text"
              hasError={error['nature']}
              value={this.state.nature}
              onChange={this.handleChange}
              required
            />

            <TextField
              id="market"
              label="What is your market focus?"
              name="market"
              type="text"
              hasError={error['market']}
              value={this.state.market}
              onChange={this.handleChange}
              required
            />

            <TextField
              id="representation"
              label="What vehicle types/brands do you already represent?"
              name="representation"
              type="text"
              hasError={error['representation']}
              value={this.state.representation}
              onChange={this.handleChange}
              required
            />

            <TextField
              id="website"
              label="Website"
              name="website"
              type="text"
              hasError={error['website']}
              value={this.state.website}
              onChange={this.handleChange}
            />

          </Form>

          <Title>Business address</Title>

          <Form container>
            <TextField
              id="address"
              label="Address"
              name="address"
              type="text"
              value={this.state.address}
              hasError={error['address']}
              onChange={this.handleChange}
              required
            />
            <TextField
              id="address_2"
              label="Address Line 2"
              name="address_2"
              type="text"
              value={this.state.address2}
              onChange={this.handleChange}
            />
            <TextField
              id="city"
              label="City"
              name="city"
              type="text"
              value={this.state.city}
              hasError={error['city']}
              onChange={this.handleChange}
              required
            />

            <Select
              id="country"
              label="Country"
              name="country"
              onChange={this.handleChange}
              hasError={error['country']}
              value={this.state.country}
              required
            >
              <SelectOption value=""></SelectOption>
              {countries.map((data, index) =>
                <SelectOption value={data.code} key={index}>{data.name}</SelectOption>
              )}
            </Select>

            <Form row>
              <Form col>
                <TextField
                  id="state"
                  label="State"
                  name="state"
                  type="text"
                  value={this.state.state}
                  onChange={this.handleChange}
                />
              </Form>
              <Form col>
                <TextField
                  id="postal_code"
                  label="Postal Code"
                  name="postal_code"
                  type="text"
                  onChange={this.handleChange}
                  hasError={error['postal_code']}
                  value={this.state.postal_code}
                  required
                />
              </Form>
            </Form>
          </Form>
          <Footer container>
            <Footer left>
              &nbsp;
            </Footer>
            <Footer right>
              <Button
                type="button"
                size="large"
                color="secondary"
                onClick={this.handleSubmit}
                inline
              >
                Submit
              </Button>
            </Footer>
          </Footer>

        </Container>
      </ZWrapper>
    )
  }
}

const mapStateToProps = state => ({
  error: state.app.error?.community_dealership || null,
  loading: state.app.loading?.community_dealership || false,
})

const mapDispatchToProps = (dispatch) => ({
  appRequest: (request) => dispatch(appActions.request(request)),
  appRequestClear: (request) => dispatch(appActions.requestClear(request)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CommunityDealershipContainer));
