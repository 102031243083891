import React from 'react';
import { connect } from 'react-redux';
import { authActions } from '../redux';
import {
  Button,
  ErrorText,
  ZWrapper,
  ZTitle,
} from '../../../ui-components';
import {
  beLandingURL
} from '../../../utils';
import styled from "styled-components";
import MarketingSurveyContainer from '../../marketing/containers/marketing-survey.container';


const Description = styled.div`
  font-size: 15px;
  line-height: 20px;
  color: ${({ theme }) => theme.text.primary};
  text-align: left;

  
`;

const Padding = styled.div`
  /* padding: 8px; */
`


class AuthVerifyContainer extends React.Component {
  componentDidMount = () => {
    const key = this.props.match.params.key
    this.props.verifyEmail(key)
  }

  render() {
    const error = this.props.error || {};

    if (this.props.loading) {
      return (
        <ZWrapper>
          <Padding>
            <ZTitle>Loading...</ZTitle>
          </Padding>
        </ZWrapper>
      )

    }

    if (this.props.isEmailVerified) {
      return (
        <>
          <ZWrapper>
            <Padding>
              <ZTitle size="large">Your email is verified!</ZTitle>
              <Description>You are a member of BeTRITON community now!</Description>

              {/* 
              <Button
                type="anchor"
                target="_self"
                color="secondary"
                size="large"
                to={beLandingURL}
              >
                Go to home page
              </Button> */}
            </Padding>
          </ZWrapper>

          <br />
          <MarketingSurveyContainer slug="community-profile" email={this.props.email} layout="embeded" />


        </>
      )
    } else {
      return (
        <ZWrapper>
          <Padding>
            <ZTitle>Oops, we couldn't verify your email...</ZTitle>
            {error[`detail`] ? <ErrorText>{error[`detail`]}</ErrorText> : null}

            <Description>Please re-send verification email.</Description>

            <br />
            <Button
              type="anchor"
              target="_self"
              color="secondary"
              size="normal"
              to={beLandingURL}
            >
              Go to home page
            </Button>
          </Padding>
        </ZWrapper>
      )
    }
  }
}

const mapStateToProps = state => ({
  error: state.app.error?.auth,
  loading: state.app.loading?.auth,
  isEmailVerified: state.auth.email_verified,
  email: state.auth.email,
})

const mapDispatchToProps = dispatch => ({
  verifyEmail: (key) => dispatch(authActions.verifyEmail(key))
})

export default connect(mapStateToProps, mapDispatchToProps)(AuthVerifyContainer);
