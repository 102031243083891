const authActionType = {
  SET_TOKEN: 'AUTH_SET_TOKEN',
  CLEAR_TOKEN: 'AUTH_CLEAR_TOKEN',

  REQUEST: 'AUTH_REQUEST',
  FAILURE: 'AUTH_FAILURE',
  SUCCESS: 'AUTH_SUCCESS',
  LOGOUT: 'AUTH_LOGOUT',
  ERROR_CLEAR: 'AUTH_ERROR_CLEAR',
  EMAIL_VERIFIED: 'AUTH_EMAIL_VERIFIED',
  EMAIL_RECOVERY: 'AUTH_EMAIL_RECOVERY',
  EMAIL_RECOVERY_CHANGE: 'AUTH_EMAIL_RECOVERY_CHANGE',
  RESET: 'AUTH_RESET',
};

export default authActionType;
