const ecommerceActionType = {
  ORDER_DATA: 'ECOMMERCE_ORDER_DATA',
  ORDER_CLEAR: 'ECOMMERCE_ORDER_CLEAR',
  ORDER_CLEAR_SOFT: 'ECOMMERCE_ORDER_CLEAR_SOFT',
  ORDER_RESET_TIMEOUT: 'ECOMMERCE_ORDER_RESET_TIMEOUT',
  ORDER_CLEAR_TIMEOUT: 'ECOMMERCE_ORDER_CLEAR_TIMEOUT',
  ORDER_CLIENT_SERCRET: 'ECOMMERCE_ORDER_CLIENT_SERCRET',
  
  // ORDER_REQUEST: 'ECOMMERCE_ORDER_REQUEST',
  // ORDER_FAILURE: 'ECOMMERCE_ORDER_FAILURE',
  // ORDER_SUCCESS: 'ECOMMERCE_ORDER_SUCCESS',
  // ORDER_CLIENT_SECRET: 'ECOMMERCE_ORDER_CLIENT_SECRET',
  // ORDER_USER_CHANGE: 'ECOMMRECE_ORDER_USER_CHANGE',
  // ORDER_TEMP_SUMMARY: 'ECOMMERCE_ORDER_TEMP_SUMMARY',
  // ORDER_CLEAR: 'ECOMMERCE_ORDER_CLEAR',

  // STORE_REQUEST: 'ECOMMERCE_STORE_REQUEST',
  // STORE_FAILURE: 'ECOMMERCE_STORE_FAILURE',
  // STORE_SUCCESS: 'ECOMMERCE_STORE_SUCCESS',

  // RENTAL_REQUEST: 'ECOMMERCE_RENTAL_REQUEST',
  // RENTAL_FAILURE: 'ECOMMERCE_RENTAL_FAILURE',
  // RENTAL_SUCCESS: 'ECOMMERCE_RENTAL_SUCCESS',

  // FORM_INIT: 'ECOMMERCE_FORM_INIT',
  // FORM_STEP: 'ECOMMERCE_FORM_STEP',
  // FORM_CLEAR: 'ECOMMERCE_FORM_CLEAR',
  // FORM_MOVE: 'ECOMMERCE_FORM_MOVE',

  // REFERER_CHANGE: 'ECOMMERCE_REFERER_CHANGE',
};

export default ecommerceActionType;
