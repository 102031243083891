
import styled, { css } from "styled-components";


export const SurveyDescription = styled.div`
  text-align: left;
  font-size: 15px;
  line-height: 19px;;
  margin: 16px 0 32px;
  color: ${({ theme }) => theme.text.primary};
`;

export const Container = styled.div`
  /* border: 1px solid green; */
  margin: 0 0 32px 0;

  ${props => props.layout === "full" && css`
    margin-bottom: 0px;
  `}
`
export const Text = styled.div`
  // font-family: 'Quantico', sans-serif;
  // text-transform: uppercase;
  color: ${({ theme }) => theme.text.secondary};
  transition: 0.2s;
  font-size: 13px;
  padding-left: 8px;
  margin-bottom: 16px;
  text-align: left;

  span{
    color: ${({ theme }) => theme.error.main};
  }
  /* border: 1px solid red; */
`;

export const Disclaimer = styled.div`
  color: ${({ theme }) => theme.text.secondary};
  margin: -12px 0 16px 4px;
  font-style: italic;
  font-size: 13px;
  line-height: 18px;
  text-align: left;
  /* border: 1px solid blue; */

`
export const Response = styled.div`
`

export const CheckboxLabel = styled.label`
  font-size: 15px;
  line-height: 19px;
  color: ${({ theme }) => theme.text.primary};
  margin-right: 8px;
  cursor: pointer;
  display: block;
  margin-bottom: 8px;

  ${props => props.disabled && css`
    color: ${({ theme }) => theme.text.disabled};
  `}

`
