import styled, { css } from "styled-components";


export const Month = styled.div`
  ${props => props.container && css`
    margin: 0 0 16px 0;
  `}

  ${props => props.month && css`
    padding: 8px;
    font-size: 16px;
    color: ${({ theme }) => theme.text.secondary};
    font-family: 'Quantico', sans-serif;
    text-transform: uppercase;

    @media ${({ theme }) => theme.device.mobile}{
      text-align: center;
    }
  `}

  ${props => props.weekdays && css`
    text-align: center;
    font-size: 12px;
    padding: 2px;
    color: ${({ theme }) => theme.text.disabled};
    display: grid;
    grid-template-columns: repeat(7, 1fr);
  `}

  ${props => props.calendar && css`
    display: grid;
    grid-template-columns: repeat(7, 1fr);
  `}
`;


export const Day = styled.button`
  background: #fff;
  border: 1px solid transparent;
  padding: 8px;
  font-size: 14px;
  font-family: 'Quantico', sans-serif;
  color: ${({ theme }) => theme.text.disabled};
  background: ${({ theme }) => theme.common.white};
  /* background: ${({ theme }) => theme.background.default}; */
  margin: 1px 1px 0 0;

  ${props => props.nature === "available" && css`
    font-weight: bold;
    color: ${({ theme }) => theme.thirdiary.main};
    background: ${({ theme }) => theme.common.white};

    border: 1px solid ${({ theme }) => theme.shades.line};

    &:hover{
      color: ${({ theme }) => theme.primary.main};
    }
  `}

  ${props => props.nature === "reserved" && css`
    color: ${({ theme }) => theme.background.default};
    background: ${({ theme }) => theme.common.white};
  `}

  ${props => props.nature === "reserved_me" && css`
    color: ${({ theme }) => theme.common.white};
    background: ${({ theme }) => theme.primary.dark};
  `}

  ${props => props.selected && css`
    color: ${({ theme }) => theme.common.white};
    background: ${({ theme }) => theme.primary.main};

    &:hover{
      color: ${({ theme }) => theme.common.white};
    }
  `}

`;


export const WeekDay = styled.div`

`;


export const DayEmpty = styled.div``;


export const CalendarContainer = styled.div`
  ${props => props.container && css`
    height: 100%;
    display: flex;
    flex-direction: column;
    text-align: left;
  `}

  ${props => props.calendar && css`
    /* border: 1px solid red; */
    /* flex: 1; */
    max-height: 100%;
    
    overflow-y: scroll;
    max-height: 400px;

    @media ${({ theme }) => theme.device.mobile}{
      text-align: center;
    }
  `}

  ${props => props.bottom && css`
    border-top: 1px solid ${({ theme }) => theme.shades.line};
    text-align: left;

    @media ${({ theme }) => theme.device.mobile}{
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 240px;
      margin: auto;
    }
  `}

  ${props => props.price && css`
    flex: 1;
  `}
  ${props => props.button && css`
    
  `}
`;

export const More = styled.button`
  margin: 0 0 16px 0;
  border: 0;
  background: none;
  padding:0;
  font-size: 12px;
  color: ${({ theme }) => theme.primary.main};
  font-family: 'Quantico', sans-serif;
  text-transform: uppercase;

 
`;


export const FullyBooked = styled.div`
  padding: 24px;
  /* background: ${({ theme }) => theme.background.default}; */
  color: ${({ theme }) => theme.text.secondary};
`