import axios from 'axios';


const BASE_URL = process.env.NODE_ENV === "production" ? `https://api.betriton.com/` : "http://localhost:8000/";
export const BeTritonAPI = axios.create({ baseURL: BASE_URL });

export const GoogleTagManagerId = process.env.NODE_ENV === "production" ? 'GTM-NGP5RJV' : '';


const IsJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};


export const validateError = (domain, error) => {
  if (error.response?.request) {
    const data = {};
    if (IsJsonString(error.response.request.response)) {
      const json = JSON.parse(error.response.request.response);
      Object.entries(json).forEach((entry) => {
        const value = Array.isArray(entry[1])
          ? entry[1].join(', ')
          : entry[1];
        data[entry[0]] = value;
      });
    }
    return {
      [`${domain}`]: data,
    };
  }
  return {
    [domain]: {
      non_field_error: JSON.stringify(error),
    },
  };
};

export const simplifyError = (error) => {
  if (error.response?.request) {
    const data = {};
    if (IsJsonString(error.response.request.response)) {
      const json = JSON.parse(error.response.request.response);
      Object.entries(json).forEach((entry) => {
        const value = Array.isArray(entry[1])
          ? entry[1].join(', ')
          : entry[1];
        data[entry[0]] = value;
      });
    }
    return data
  }
  return {
    non_field_error: JSON.stringify(error),
  };
};

export const parseError = (domain, error, defaultReturn = {}) => {
  if (error) {
    if (domain in error) {
      return error[domain];
    }
  }
  return defaultReturn;
};


// export const restHeader = (type = 'json') => {
//   const header = {};

//   switch (type) {
//     default:
//     case 'json':
//       header['Content-Type'] = 'application/json';
//       break;
//     case 'multipart':
//       header['Content-Type'] = 'multipart/form-data';
//       break;
//   }

//   const token = localStorage.getItem('token');
//   if (token) { header.Authorization = `Token ${token}`; }
//   return header;
// };
