import appActionType from './app.types';

const initialState = {
  loading: false,
  error: null,
  modal: {
    messages: [],
  },
  // popup: {
  //   name: "deal",
  //   params: {
  //     // anchorId: 'PipelineFilter',
  //     // anchorPosition: 'TR',
  //     id: 39,
  //     size: "large",
  //   },
  // },
  popup: {
    name: null,
    fixed: false,
    mandatory: false,
    params: {},
  },
  sidebar: null,
};


export const appReducer = (state = initialState, action) => {
  switch (action.type) {

    case appActionType.REQUEST:
      return {
        ...state,
        loading: {
          ...state.loading,
          [action.domain]: action.loading,
        },
        error: {
          ...state.error,
          [action.domain]: action.error,
        }
      }

    case appActionType.REQUEST_CLEAR:

      const clearLoading = { ...state.loading };
      delete clearLoading[action.domain];

      const clearError = { ...state.loading };
      delete clearError[action.domain];

      return {
        ...state,
        loading: clearLoading,
        error: clearError,
      }


    case appActionType.MODAL_ADD_MESSAGE:
      return {
        ...state,
        modal: {
          messages: [
            ...state.modal.messages,
            {
              color: action.color,
              text: action.text,
              timeout: action.timeout,
            },
          ]
        }
      }

    case appActionType.MODAL_REMOVE_MESSAGE:
      state.modal.messages.splice(action.index, 1)
      return {
        ...state
      }

    case appActionType.POPUP_SHOW:
      return {
        ...state,
        popup: {
          name: action.name,
          fixed: action.fixed,
          params: action.params,
        }
      }
    case appActionType.POPUP_HIDE:
      return {
        ...state,
        popup: {
          // ...initialState.popup, #TODO CHANGE
          name: null,
          fixed: false,
          params: {},
        },
      }

    case appActionType.SIDEBAR_SHOW:
      return {
        ...state,
        sidebar: action.sidebar,
      }
    case appActionType.SIDEBAR_HIDE:
      return {
        ...state,
        sidebar: null,
      }
    default:
      return state;
  }
};

export default appReducer;