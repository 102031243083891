import styled, { css } from 'styled-components';

export const ModalPlacement = styled.div`
  position: absolute;
  top: 0; 
  left: 0;
  right: 0;
  z-index: 20000;
`

export const Container = styled.div`
  position: fixed;
  height: 0px;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 60px;
  top: ${props => props.showing ? "0px" : "-60px"};
  overflow: hidden;
  transition: all 0.3s ease-out;
  pointer-events: none;
`;

export const Message = styled.div`
  display: inline-block;
  font-family: RobotoMedium;
  text-transform: uppercase;
  text-align: center;
  font-size: 11px;
  padding: 8px 16px;
  border-radius: 16px;

  ${props => props.color === "green" && css`
    color: ${({ theme }) => theme.text.white};
    background: ${({ theme }) => theme.secondary.dark};
  `}

  ${props => props.color === "red" && css`
    color: ${({ theme }) => theme.text.white};
    background: ${({ theme }) => theme.common.red};
  `}
`;

