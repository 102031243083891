import React, { useState, useEffect } from 'react';
import MonthComponent from './calendar-month';
import { format, parse } from 'date-fns';
import {
  More,
  FullyBooked,
} from './calendar.styled';



const MAX_DATE_RANGE = 2;
const DATE_FORMAT = "yyyy-MM-dd";

const next = ({ year, month }) => {
  year = (month === 11) ? year + 1 : year;
  month = (month + 1) % 12;
  return { month, year };
}

// const getWeekOfMonth = (date) => {
//   const adjustedDate = date.getDate() + date.getDay();
//   const prefixes = ['0', '1', '2', '3', '4', '5'];
//   return (parseInt(prefixes[0 | adjustedDate / 7]) + 1);
// }

export const Calendar = ({ dates = [], available_date_range, disable, handleReservation }) => {
  const [reservation, setReservation] = useState({
    from: '',
    till: '',
  })
  const [calendars, setCalendars] = useState([]);

  useEffect(() => {

    if (available_date_range?.from === ('' || null) && available_date_range?.till === ('' || null)) {
      // const today = new Date()
      // const currentMonth = today.getMonth();
      // const currentYear = today.getFullYear();
      // const weekInMonth = getWeekOfMonth(today);

      // let data = [{ month: currentMonth, year: currentYear }]

      // if (weekInMonth > 2) {
      //   const { month, year } = next({ year: currentYear, month: currentMonth });
      //   data.push({
      //     month,
      //     year,
      //   })
      // }

      // setCalendars([
      //   ...data
      // ])

    } else {

      const parsedFrom = parse(available_date_range.from, DATE_FORMAT, new Date());

      // console.log(parsedFrom.getMonth())
      // const till = available_date_range.till.split("-")

      if (!isNaN(parsedFrom.getMonth())) {
        setCalendars([{ month: parsedFrom.getMonth(), year: parsedFrom.getFullYear() }])
      }
    }

    // console.log(available_date_range)

  }, [available_date_range])


  // console.log(calendars)
  useEffect(() => {
    let quantity = 0;

    if (reservation.from !== '' && reservation.till === '') {
      quantity = 1;

    } else if (reservation.from !== '' && reservation.till !== '') {
      const parsedFrom = parse(reservation.from, DATE_FORMAT, new Date());
      const parsedTill = parse(reservation.till, DATE_FORMAT, new Date());
      quantity = (parsedTill - parsedFrom) / (1000 * 60 * 60 * 24) + 1;
    }

    handleReservation({
      from: reservation.from,
      till: reservation.till,
      quantity,
    });

  }, [reservation, handleReservation])

  const handleSelect = ({ year, month, day }) => {
    const date_str = `${year}-${month}-${day}`;

    if (reservation.from === '') {
      setReservation((state) => ({
        ...state,
        from: date_str,
      }))
    } else if (reservation.from !== '' && reservation.till === '') {
      const parsedFrom = parse(reservation.from, DATE_FORMAT, new Date());
      const parsedTill = parse(date_str, DATE_FORMAT, new Date());
      const range = (parsedTill - parsedFrom) / (1000 * 60 * 60 * 24)

      if (parsedTill > parsedFrom) {
        if (range + 1 <= MAX_DATE_RANGE) {
          setReservation((state) => ({
            ...state,
            till: date_str,
          }))
        } else {
          // selects date at max range end
          const endDate = new Date(parsedFrom);
          endDate.setDate(parsedFrom.getDate() + MAX_DATE_RANGE - 1);

          let maxRange = true;

          for (let dt = new Date(parsedFrom); dt <= endDate; dt.setDate(dt.getDate() + 1)) {

            const date_str = format(new Date(dt), DATE_FORMAT)
            const data = dates.find(el => el.date === date_str)
            // console.log(data)
            if (data && data.nature !== "available") {
              maxRange = false;
              const day = new Date(dt);
              const dayBefore = day.setDate(day.getDate() - 1);
              setReservation((state) => ({
                ...state,
                till: format(new Date(dayBefore), DATE_FORMAT),
              }));
              break;
            }
          }

          if (maxRange) {
            setReservation((state) => ({
              ...state,
              till: format(endDate, DATE_FORMAT),
            }));
          };
        }
      } else {
        setReservation((state) => ({
          ...state,
          from: date_str,
        }))
      }
    } else if (reservation.from !== '' && reservation.till !== '') {
      setReservation((state) => ({
        ...state,
        from: date_str,
        till: '',
      }))
    }
  };

  const handleAddMonth = () => {
    const lastCalendar = calendars[calendars.length - 1];
    const { month, year } = next({ year: lastCalendar.year, month: lastCalendar.month });
    setCalendars((state) => ([
      ...state,
      {
        month,
        year,
      }
    ]));
  }


  // console.log(calendars)

  if (calendars.length === 0) {
    return <FullyBooked>The rental calendar for the 2024 season will be available soon.</FullyBooked>
  }

  return (
    <>
      {calendars.map((calendar, index) => (
        <MonthComponent
          key={index}
          month={calendar.month}
          year={calendar.year}
          dates={dates || []}
          disable={disable}
          range={reservation}
          onClick={handleSelect}
        />
      ))}

      {!disable
        ? (
          <More onClick={() => handleAddMonth()}>
            Load more dates
          </More>
        )
        : null}

    </>
  )
}

export default Calendar;
