import ecommerceActionType from './ecommerce.types';


const order_uuid = localStorage.getItem('order_uuid');
const order_timeout = localStorage.getItem('order_timeout');
const initialState = {
  uuid: order_uuid ? order_uuid : '',
  state: '',
  currency: '',
  total_price: 0.00,
  total_items: 0,
  items: [],
  user: {},
  shipping: {},
  meta: '',
  comment: '',
  referer: '',
  client_secret: '',
  timeout: order_timeout ? order_timeout : '',
};

export const ecommerceReducer = (state = initialState, action) => {
  switch (action.type) {
    case ecommerceActionType.ORDER_DATA:
      localStorage.setItem('order_uuid', action.uuid);
      return {
        ...state,
        uuid: action.uuid,
        state: action.state,
        currency: action.currency,
        total_price: action.total_price,
        total_items: action.total_items,
        items: action.items,
        user: action.user,
        shipping: action.shipping,
        meta: action.meta,
        comment: action.comment,
      }
    case ecommerceActionType.ORDER_CLIENT_SERCRET:
      return {
        ...state,
        client_secret: action.client_secret,
      }
    case ecommerceActionType.ORDER_CLEAR_SOFT:
      localStorage.removeItem('order_uuid');
      return {
        ...state,
      }
    case ecommerceActionType.ORDER_CLEAR:
      localStorage.removeItem('order_uuid');
      return {
        ...state,
        uuid: '',
        state: '',
        currency: '',
        total_price: 0.00,
        total_items: 0,
        items: [],
        user: {},
        shipping: {},
        meta: '',
        comment: '',
        client_secret: '',
      }
    case ecommerceActionType.ORDER_RESET_TIMEOUT:
      localStorage.setItem('order_timeout', action.timeout);
      return {
        ...state,
        timeout: action.timeout,
      }

    case ecommerceActionType.ORDER_CLEAR_TIMEOUT:
      localStorage.removeItem('order_timeout');
      return {
        ...state,
        timeout: '',
      }
    default:
      return state;
  }
};

export default ecommerceReducer;



