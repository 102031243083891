import userActionType from './user.types';

const user = JSON.parse(localStorage.getItem('user'));
const userData = user ? { ...user } : {};

const initialState = {
  logged_in: (user !== null),
  ...userData,
};


export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case userActionType.PROFILE:
      localStorage.setItem('user', JSON.stringify(action.user));
      return {
        ...state,
        logged_in: true,
        ...action.user,
      };
    case userActionType.PROFILE_CLEAR:
      localStorage.removeItem('user');
      return {
        logged_in: false,
      };
    default:
      return state;
  }
}

export default userReducer;
