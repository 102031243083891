import React from 'react';
import { useSelector } from 'react-redux';
import {
  Grid,
  Title,
  Label,
  Description,
  // CallToAction,
  PriceTag,
  MetaTable,
  MapTitle,
  MapImage,
} from './product.styled';
import ProductGallery from './product-gallery';
import {
  AddToBasket,
  // CheckoutButton,
} from '../basket';
import ReactMarkdown from 'react-markdown';
import {
  ZWrapper
} from '../../../../ui-components';
import {
  history,
} from '../../../../utils';


export const ProductPreOrder = ({ id, slug, name, price, currency, description, image_primary, image_gallery, meta }) => {
  const gallery = image_primary ? [image_primary, ...image_gallery || []] : [];
  const { items } = useSelector(state => ({
    items: state.ecommerce.items || [],
  }));

  const handleSubmit = () => {
    if (items.length > 0) { // because handle submit is triggered before item is successfuly added to the basket
      history.push('/checkout/basket');
    } else {
      history.push('/checkout/user');
    }
  }

  const basketItem = items.find(el => el.price_id === id)
  return (
    <Grid preorder_container>
      <Grid gallery>
        <ProductGallery
          name={name}
          images={gallery}
          thumbnails="bottom"
        />
      </Grid>
      <Grid text>
        <Title to={`/p/${slug}` || '/'}>{name}</Title>
        <Label unit="pre-order">Pre-Order</Label>

        <PriceTag container>
          <PriceTag text>Pre-order price:</PriceTag>
          <PriceTag price>{price} {currency}</PriceTag>
        </PriceTag>

        <br />

        <ZWrapper color="background" size="normal">
          <MetaTable container>
            {meta?.full_price
              ? <MetaTable row>Full Price: <strong>{meta.full_price}</strong></MetaTable>
              : null
            }
            {meta?.first_deliveries
              ? <MetaTable row>First deliveries: <strong>{meta.first_deliveries}</strong></MetaTable>
              : null
            }
          </MetaTable>
        </ZWrapper>

        <br />

        {/* {basketItem && basketItem.quantity >= 1
          ? <CallToAction>{basketItem.quantity === 1 ? "This preorder item is already" : `There are ${basketItem.quantity} preorder items`} in your basket.</CallToAction>
          : null
        } */}

        <AddToBasket
          id={id}
          defaultQuantity={basketItem ? basketItem.quantity : 1}
          orderItemId={basketItem ? basketItem.order_item_id : null}
          callback={handleSubmit}
          nomargin={true}
        >
          Continue
        </AddToBasket>
        {/* 
        {!basketItem
          ?
          <AddToBasket
            id={id}
            defaultQuantity={basketItem ? basketItem.quantity : 1}
            forceQuantityChange={true}
            // oneItem={true}
            callback={handleSubmit}
            nomargin={true}
          >
            Continue
          </AddToBasket>
          : <>
            {basketItem.quantity >= 1
              ? <CallToAction>This preorder item is already in your basket.</CallToAction>
              : null
            }
            <CheckoutButton
              view="product"
            >
              {basketItem.quantity >= 1 ? 'Checkout' : 'Continue'}
            </CheckoutButton>
          </>
        } */}


      </Grid>

      <Grid description>
        <Description>
          <ReactMarkdown linkTarget="_blank">
            {description}
          </ReactMarkdown>
        </Description>
      </Grid>

      <Grid map>
        {meta?.preorder_map
          ? <>
            <MapTitle>Map of received pre-orders</MapTitle>
            <MapImage
              src={meta?.preorder_map.original}
              alt="Pre-order map"
            />
          </>
          : null}
      </Grid>
    </Grid>
  )
}

export default ProductPreOrder;
