import styled, { css } from "styled-components";

export const Layout = styled.div`
  height: 100vh;
  display: grid;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  grid-template-areas: 
    'header'
    'main'
    'footer';

  ${props => props.layout === "empty" && css`
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-template-areas:
      'main';
  `}

  ${props => props.layout === "sidebar" && css`
    grid-template-columns: auto 204px 740px auto;
    grid-gap: 0 16px;
    grid-template-rows: auto 32px 1fr auto;
    grid-template-areas: 
      'header header header header'
      '. . . .'
      ' . sidebar main . '
      'footer footer footer footer';
  `}

  ${props => props.layout === "simple" && css`

    
    grid-template-columns: auto 960px auto;
    grid-template-rows: auto 1fr auto;
    grid-template-areas: 
      'header header header'
      ' . main . '
      'footer footer footer';

      ${props => props.compact && css`
        grid-template-columns: auto 480px auto;
      `}

    @media only screen and (max-width: 980px){
      grid-template-columns: auto 1fr auto;
    }
    @media ${({ theme }) => theme.device.mobile}{
      grid-template-columns: auto 1fr auto;
    }
  `}

  ${props => props.layout === "basket" && css`;
    grid-template-columns: auto 960px auto;
    grid-gap: 0 16px;
    grid-template-rows: auto auto auto 1fr auto;
    grid-template-areas: 
      'header header header'
      ' . progress . '
      ' . main . '
      ' . . . '
      'footer footer  footer';

    @media only screen and (max-width: 1000px){
      grid-template-columns: auto 1fr auto;
    }

    @media ${({ theme }) => theme.device.mobile}{
      grid-template-columns: auto 1fr auto;
    }
  `}

  ${props => props.layout === "success" && css`;
    grid-template-columns: auto 242px 460px 242px auto;
    grid-gap: 0 16px;
    grid-template-rows: auto auto auto 1fr auto;
    grid-template-areas: 
      'header header header header header'
      ' . progress progress progress . '
      ' . . main . . '
      ' . . . . . '
      'footer footer footer footer footer';

    @media only screen and (max-width: 1000px){
      grid-template-columns: auto 1fr auto;
    }

    @media ${({ theme }) => theme.device.mobile}{
      grid-template-columns: auto 1fr auto;
    }
  `}

  ${props => props.layout === "progress-summary" && css`;
    grid-template-columns: auto 640px 304px auto;
    grid-gap: 0 16px;
    grid-template-rows: auto auto auto 1fr auto;
    grid-template-areas: 
      'header header header header'
      ' . progress progress . '
      ' . main summary . '
      ' . . . . '
      'footer footer footer footer';


    @media only screen and (max-width: 1000px){
      grid-template-columns: auto 608px 304px auto;
    }

    @media ${({ theme }) => theme.device.tablet}{
      grid-template-columns: auto 500px 292px auto;
    }

    @media ${({ theme }) => theme.device.mobile}{
      grid-template-columns: auto 1fr auto;
      
      grid-template-areas: 
        'header header header'
        ' . progress . '
        ' . main . '
        ' . . . '
        'footer footer footer';
    }

  `}
`;

export const LayoutHeader = styled.header`
  grid-area: header;
`;

export const LayoutMain = styled.main`
  grid-area: main;
  padding-bottom: 24px;
  
  
`;

export const LayoutProgress = styled.div`
  grid-area: progress;
`

export const LayoutSummary = styled.div`
  grid-area: summary;

  @media ${({ theme }) => theme.device.mobile}{
    display: none;
  }
`

export const LayoutSidebar = styled.div`
  grid-area: sidebar;
`

export const LayoutFooter = styled.footer`
  grid-area: footer;
`;


