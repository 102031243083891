export const appActionType = {
  REQUEST: 'APP_REQUEST',
  REQUEST_CLEAR: 'APP_REQUEST_CLEAR',  

  MODAL_ADD_MESSAGE: 'APP_MODAL_ADD_MESSAGE',
  MODAL_REMOVE_MESSAGE: 'APP_MODAL_REMOVE_MESSAGE',

  POPUP_SHOW: 'APP_POPUP_SHOW',
  POPUP_HIDE: 'APP_POPUP_HIDE',

  SIDEBAR_SHOW: 'APP_SIDEBAR_SHOW',
  SIDEBAR_HIDE: 'APP_SIDEBAR_HIDE',
}

export default appActionType;
