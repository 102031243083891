import React from 'react';
import {
  Footer,
  Layout,
  LayoutMain,
  LayoutFooter,
} from '../../../ui-components';
import {
  ColumnContainer,
} from '../components/components';
import UserPurchaseAgreementContainer from '../containers/user-purchase-agreement.container';


const UserPurchaseAgreementView = (props) => (
  <Layout id="layout" layout="simple">
    <LayoutMain>
      <ColumnContainer container>
        <ColumnContainer head>

        </ColumnContainer>
        <ColumnContainer details left>
          <UserPurchaseAgreementContainer />

        </ColumnContainer>
      </ColumnContainer>
    </LayoutMain>
    <LayoutFooter>
      <Footer {...props} />
    </LayoutFooter>
  </Layout>
);

export default UserPurchaseAgreementView;
