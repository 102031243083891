import ecommerceActionType from './ecommerce.types';
import { appActions } from '../../app/redux';
import { BeTritonAPI } from '../../../utils';


const setOrderData = ({ uuid, state, currency, total_items, total_price, items, user, shipping, meta, comment }) => ({
  type: ecommerceActionType.ORDER_DATA,
  uuid,
  state,
  currency,
  total_price,
  total_items,
  items,
  user,
  shipping,
  meta,
  comment,
});

const resetOrderTimeout = () => ({
  type: ecommerceActionType.ORDER_RESET_TIMEOUT,
  timeout: (new Date()),
})

const clearOrderTimeout = () => ({
  type: ecommerceActionType.ORDER_CLEAR_TIMEOUT,
})

const clearOrder = () => {
  return (dispatch) => {
    dispatch({ type: ecommerceActionType.ORDER_CLEAR })
    dispatch(clearOrderTimeout());
  };
}


const getOrder = (uuid) => {
  return async (dispatch) => {
    dispatch(appActions.request({
      domain: "ecommerce",
      loading: true,
      error: null,
    }))

    try {
      const res = await BeTritonAPI.get(`v1/ecommerce/order/${uuid}/`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        })
      dispatch(setOrderData(res.data));
      dispatch(appActions.requestClear({ domain: "ecommerce" }))
    } catch (err) {
      dispatch(appActions.request({
        domain: "ecommerce",
        loading: false,
        error: err,
      }))
    }
  };
};

const reloadOrder = () => {
  return (dispatch, getStore) => {
    let { uuid } = getStore().ecommerce;
    dispatch(getOrder(uuid));
  }
}


const completeOrder = () => {
  return async (dispatch, getStore) => {
    let { uuid } = getStore().ecommerce;
    dispatch(appActions.request({
      domain: "ecommerce",
      loading: true,
      error: null,
    }))

    try {
      const res = await BeTritonAPI.post(`v1/ecommerce/order/${uuid}/complete/`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        })
      dispatch(appActions.requestClear({ domain: "ecommerce" }));
      dispatch(setOrderData(res.data));
    } catch (err) {
      dispatch(appActions.request({
        domain: "ecommerce",
        loading: false,
        error: err,
      }))
    }
  };
};


const addOrderItem = (priceId, quantity, meta, maxQuantity = null) => {
  return async (dispatch, getStore) => {
    let { uuid } = getStore().ecommerce;
    dispatch(resetOrderTimeout());
    dispatch(appActions.request({
      domain: "ecommerce_item",
      loading: true,
      error: null,
    }))

    try {
      const res = await BeTritonAPI.post(`v1/ecommerce/order/`,
        {
          price_id: priceId,
          quantity: quantity,
          uuid: uuid,
          meta: meta,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        })
      dispatch(setOrderData(res.data));
      const successString = `${quantity} item` + (quantity === 1 ? '' : 's') + ` added`;
      dispatch(appActions.modal.addMessage("green", successString))

      dispatch(appActions.requestClear({ domain: "ecommerce_item" }))
    } catch (err) {
      dispatch(appActions.request({
        domain: "ecommerce_item",
        loading: false,
        error: err,
      }))
    }
  }
}


const changeOrderItem = (orderItemId, priceId, quantity, meta) => {
  return async (dispatch, getStore) => {
    let { uuid } = getStore().ecommerce;
    dispatch(resetOrderTimeout());
    dispatch(appActions.request({
      domain: "ecommerce_item",
      loading: true,
      error: null,
    }))

    try {
      const res = await BeTritonAPI.put(`v1/ecommerce/order/${uuid}/item/${orderItemId}/`,
        {
          price_id: priceId,
          quantity,
          meta,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        })
      dispatch(setOrderData(res.data));
      dispatch(appActions.requestClear({ domain: "ecommerce_item" }))
    } catch (err) {
      dispatch(appActions.request({
        domain: "ecommerce_item",
        loading: false,
        error: err,
      }))
    }
  };
};


const setUserAndAddress = (payload) => {
  return async (dispatch, getStore) => {
    let { uuid } = getStore().ecommerce;
    dispatch(resetOrderTimeout());

    dispatch(appActions.request({
      domain: "ecommerce_user",
      loading: true,
      error: null,
    }))

    try {
      const res = await BeTritonAPI.put(`v1/ecommerce/order/${uuid}/user/`,
        { ...payload },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        })
      dispatch(setOrderData(res.data));
      dispatch(appActions.requestClear({ domain: "ecommerce_user" }))
    } catch (err) {
      dispatch(appActions.request({
        domain: "ecommerce_user",
        loading: false,
        error: err,
      }))

    }
  };
};

const getClientSecret = () => {
  return async (dispatch, getStore) => {
    let { uuid } = getStore().ecommerce;

    dispatch(appActions.request({
      domain: "ecommerce_user",
      loading: true,
      error: null,
    }))

    try {
      const res = await BeTritonAPI.post(`v1/ecommerce/order/${uuid}/payment/`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        })

      // getting client secret from Payment Intent
      dispatch({
        type: ecommerceActionType.ORDER_CLIENT_SERCRET,
        client_secret: res.data.client_secret,
      });
      // console.log("from source", res.data.client_secret)

      dispatch(appActions.requestClear({ domain: "ecommerce_user" }))
    } catch (err) {
      dispatch(appActions.request({
        domain: "ecommerce_user",
        loading: false,
        error: err,
      }))

    }
  };
}


export const ecommerceActions = {
  order: {
    get: getOrder,
    reload: reloadOrder,
    clear: clearOrder,
    complete: completeOrder,
    timeout: {
      reset: resetOrderTimeout,
      clear: clearOrderTimeout,
    }
  },
  item: {
    addItem: addOrderItem,
    changeItem: changeOrderItem,
  },
  user: {
    setUserAndAddress,
    getClientSecret,
  }

};

export default ecommerceActions;