import authActionType from './auth.types';

const token = localStorage.getItem('token');

const initialState = {
  token: token ? token : null,
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case authActionType.SET_TOKEN:
      localStorage.setItem('token', action.token);
      return {
        ...state,
        token: action.token,
      };
    case authActionType.CLEAR_TOKEN:
      localStorage.removeItem('token');
      return {
        token: null,
      }

    case authActionType.RESET:
      return {
        token: token ? token : null,
      }


    case authActionType.REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case authActionType.SUCCESS:
      return {
        ...state,
        token: action.token,
        loading: false,
        error: null,
      };
    case authActionType.FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    // Emails are verified in auth cause, it's possible to verify them WITHOUT logged in user
    case authActionType.EMAIL_VERIFIED:
      return {
        ...state,
        email_verified: true,
        email: action.email,
      }
    case authActionType.EMAIL_RECOVERY:
      return {
        ...state,
      }
    case authActionType.LOGOUT:
      return {
        ...initialState
      };
    case authActionType.ERROR_CLEAR:
      return {
        ...state,
        // error: false,
      }
    default:
      return state;
  }
};

export default authReducer;