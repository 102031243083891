import React from 'react';
import { connect } from 'react-redux';
import { authActions } from '../redux/';
import { appActions } from '../../app/redux';
import {
  BeTritonAPI,
} from '../../../utils';

import styled, { css } from "styled-components";


const Subscribe = styled.div`
  margin: 0 auto 8px;
  display: grid;
  max-width: 240px;
  grid-template-rows: auto auto;
  grid-template-columns: auto auto;
  grid-template-areas: 
    'label label'
    'input button';
  padding: 4px;
`;


const SubscribeLabel = styled.label`
  grid-area: label;
  font-size: 12px;
  line-height: 15px;
  padding: 2px 4px 4px;
  text-align: left;
  font-family: 'Quantico', sans-serif;
  text-transform: uppercase;
  color: ${({ theme }) => theme.text.white};

  ${({ hasError }) => hasError && css`
    font-family: Roboto, sans-serif;
    text-transform: none;
    span{
      padding: 3px;
      margin-left: -3px;
      background: ${({ theme }) => theme.error.main};
    }
  `}
`;

const SubscribeInput = styled.input`
  border: 1px solid ${({ theme }) => theme.primary.main};
  grid-area: input;
  width: 100%;
  font-size: 13px;
  line-height: 17px;
  padding: 4px 8px;
  color: ${({ theme }) => theme.text.primary};

  &:focus{
    border: 1px solid ${({ theme }) => theme.primary.dark};
    outline: none;
  }

  &::placeholder{
    color: ${({ theme }) => theme.text.disabled};
  }

  ${({ hasError }) => hasError && css`
    border: 1px solid ${({ theme }) => theme.error.main};
  `}
`;

const SubscribeButton = styled.button`
  border: 0;
  background: ${({ theme }) => theme.primary.main};
  font-family: 'Quantico', sans-serif;
  text-transform: uppercase;
  padding: 4px 8px;
  font-size: 13px;
  color: ${({ theme }) => theme.text.white};
  grid-area: button;

  &:hover{
    background: ${({ theme }) => theme.primary.light};
    color: ${({ theme }) => theme.text.primary};
  }
`;

const cutSize = 4;
const SubscribeSuccess = styled.div`
  position: relative;
  text-align: center;
  margin: 0 auto 0;
  max-width: 240px;
  background:  ${({ theme }) => theme.background.paper};
  padding: 16px 8px;
  font-size: 14px;
  line-height: 18px;
  color: ${({ theme }) => theme.text.secondary};

  strong{
    font-weight: normal;
    font-family: 'Quantico', sans-serif;
    text-transform: uppercase;
    margin-bottom: 4px;
    display: inline-block;
    color: ${({ theme }) => theme.secondary.dark};
  }

  &::before {
    content: ' ';
    position: absolute;
    top: 0;
    right: -${cutSize}px;
    bottom: 0;
    border-top: ${cutSize}px solid transparent;
    border-left: ${cutSize}px solid ${({ theme }) => theme.background.paper};
    border-bottom: ${cutSize}px solid transparent;
  }

  &::after {
    content: ' ';
    position: absolute;
    top: 0;
    left: -${cutSize}px;
    bottom: 0;
    border-top: ${cutSize}px solid transparent;
    border-right: ${cutSize}px solid ${({ theme }) => theme.background.paper};
    border-bottom: ${cutSize}px solid transparent;
  }
`;



class AuthSubscribeContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cool: '',
      name: '',
      subsribed: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  subscribe = async () => {
    this.props.appRequest({
      domain: "subscribe",
      loading: true,
      error: null,
    })

    try {
      await BeTritonAPI.post(`v1/marketing/subscription/`, {
        email: this.state.cool,
        name: this.state.name,
      }, {
        headers: {
          'Content-Type': 'application/json',
        }
      })

      this.props.appRequestClear({ domain: "subscribe" })
      this.setState({
        subsribed: true,
      })


    } catch (err) {
      this.props.appRequest({
        domain: "subscribe",
        loading: false,
        error: err,
      })
    }
  }


  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    })
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.subscribe();
  }

  render = () => {
    const error = this.props.error || {}

    const hasEmailError = !!error['email'];

    if (this.state.subsribed) {

      return <SubscribeSuccess>
        <strong>Success!</strong><br />
        Please check your inbox and verify your email address.
      </SubscribeSuccess>
    }


    return (
      <Subscribe>
        <SubscribeLabel hasError={hasEmailError}>
          <span>{hasEmailError ? error['email'] : 'Subscribe to news'}</span>
        </SubscribeLabel>
        <SubscribeInput
          type="text"
          name="cool"
          placeholder="email@address.com"
          hasError={hasEmailError}
          value={this.state.cool}
          onChange={this.handleChange}
        />
        <SubscribeButton
          type="button"
          onClick={this.handleSubmit}
        >
          Subsribe
        </SubscribeButton>
      </Subscribe>
    )
  }

}

const mapStateToProps = state => ({
  error: state.app.error?.subscribe || null,
  loading: state.app.loading?.subscribe || false,
})

const mapDispatchToProps = (dispatch) => ({
  setToken: (token) => dispatch(authActions.setToken(token)),
  appRequest: (request) => dispatch(appActions.request(request)),
  appRequestClear: (request) => dispatch(appActions.requestClear(request)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AuthSubscribeContainer);
