import React from 'react';
import {
  LoadingSpinner,
  ZWrapper,
  ErrorText,
} from "../../../../ui-components";
import styled, { css } from "styled-components";


const Container = styled.div`
  padding: 8px;
`;

export const Title = styled.div`
  font-family: 'Quantico', sans-serif;
  text-transform: uppercase;
  color: ${({ theme }) => theme.primary.main};
  margin-bottom: 24px;
`;


export const Footer = styled.div`
  ${props => props.container && css`
      display: flex;
  `}

  ${props => props.left && css`
      flex: 1;
      align-self: flex-start;
      justify-self: flex-start;
  `}

  ${props => props.right && css`
      /* flex: 1; */
      /* text-align: right; */
      /* align-self: flex-end;
      justify-self: flex-end; */
  `}
`;

export const Form = styled.div`

  ${props => props.container && css`
    margin: 16px 0 24px;
    border: 1px solid transparent;
  `}
  
  ${props => props.row && css`
    display: flex;
    flex-wrap: wrap;
  `}

  ${props => props.col && css`
    flex-grow: ${(props) => props.size || 1};
    padding-right: 24px;
    flex: 1;

    &:last-child{
      padding-right: 0;
    }
  `}
`

export const Tos = styled.label`
  font-size: 14px;
  line-height: 20px;
  /* margin: 16px 0; */
  display: flex;
  padding: 8px 0 ;
  border-radius: 4px;
  color: ${({ theme }) => theme.text.primary};

  ${(props) => props.error && css`
    color: ${({ theme }) => theme.error.main};
    background: ${({ theme }) => theme.error.main}66;
    padding: 8px;

    a{ 
      font-weight: bold;
      color: ${({ theme }) => theme.error.dark};
      text-decoration: underline;
    }
  `};
  cursor: pointer;
`;

export const TosContent = styled.div`
  ${(props) => props.checkbox && css`
      margin-right: 16px;
      align-self: center;

    @media ${({ theme }) => theme.device.mobile}{
      padding-left: 16px;
    }
  `}
  ${(props) => props.text && css`
    @media ${({ theme }) => theme.device.mobile}{
      padding-left: 16px;
    }
  `}
`;




export const CheckoutWrapper = ({ children, loading, error }) => {
  const errorMessage = error || {}

  if (loading)
    return (
      <ZWrapper color="white" size="normal">
        <Container>
          <LoadingSpinner />
        </Container>
      </ZWrapper>
    )

  if (errorMessage.detail !== undefined)
    return (

      <ZWrapper color="white" size="normal">
        <Container>
          <ErrorText>Order Not Found.</ErrorText>
        </Container>
      </ZWrapper>
    )


  return (
    <ZWrapper color="white" size="normal">
      <Container>
        {children}
      </Container>
    </ZWrapper>
  )
}

export default CheckoutWrapper;


