import React from 'react';
import { useDispatch } from "react-redux";
import { appActions } from '../../app/redux';
import { ecommerceActions } from '../../ecommerce/redux';
import {
  PopupModal,
} from '../../../ui-components';
import EcommerceProductContainer from '../containers/ecommerce-product.container';


const EcommerceProductPopup = () => {
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(appActions.popup.hide());
  }
  const handleSuccess = () => {
    dispatch(appActions.popup.hide());
    dispatch(ecommerceActions.order.reload());
  }

  return (
    <PopupModal
      size="large"
      closeAction={() => handleClose()}
    >
      <EcommerceProductContainer
        onClose={() => handleClose()}
        onSuccess={() => handleSuccess()}
      />
    </PopupModal>
  )
}

export default EcommerceProductPopup;
