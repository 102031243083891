import React from 'react';
import {
  Grid,
  Title,
  Label,
  Description,
  Location,
  MetaTable,
} from './product.styled';
import ProductGallery from './product-gallery';
import {
  ZWrapper
} from '../../../../ui-components';
import ReactMarkdown from 'react-markdown'
import EcommerceRentalCalendarContainer from '../../containers/ecommerce-rental-calendar.container';


export const ProductRental = ({ id, slug, unit, name, price, currency, description, image_primary, image_gallery, meta }) => {
  // const { loading } = useSelector(state => ({
  //   loading: state.app.loading?.ecommerce || false,
  // }));




  const gallery = image_primary ? [image_primary, ...image_gallery || []] : [];

  return (
    <Grid rental_container>
      <Grid gallery>
        <ProductGallery
          name={name}
          images={gallery}
          thumbnails="bottom"
        />
      </Grid>

      <Grid text>
        <Title to={`/p/${slug}` || '/'}>{name}</Title>
        <Label unit="rental">Rental</Label>

        <br />

        {meta?.location ?
          (<Location container>
            <Location text>
              Rent location:
            </Location>
            <Location url>
              <a
                href={meta.location}
                target="_blank"
                rel="noreferrer"
              >MAP</a>
            </Location>
          </Location>
          )
          : null}

        <ZWrapper color="background" size="normal">
          <MetaTable container>
            <MetaTable row>1 day: <strong>{price} {currency}</strong></MetaTable>
            <MetaTable row>2 days (including 1 night): <strong>{(price * 2).toFixed(2)} {currency}</strong></MetaTable>
            {meta?.full_price
              ? <MetaTable row>Full Price: <strong>{meta.full_price}</strong></MetaTable>
              : null
            }
            {meta?.first_deliveries
              ? <MetaTable row>First deliveries: <strong>{meta.first_deliveries}</strong></MetaTable>
              : null
            }
            {meta?.opening_hours
              ? <MetaTable row>1 day rental is from <strong>{meta.opening_hours}</strong></MetaTable>
              : null
            }
            {meta?.people
              ? <MetaTable row>The product fits <strong>{meta.people}</strong></MetaTable>
              : null
            }
            {meta?.max_period
              ? <MetaTable row>Max period of rental - <strong>{meta.max_period}</strong></MetaTable>
              : null
            }
          </MetaTable>
        </ZWrapper>
      </Grid>

      <Grid description>
        <Description>
          <ReactMarkdown linkTarget="_blank">
            {description}
          </ReactMarkdown>
        </Description>

      </Grid>

      <Grid calendar>
        {/* <BasketSnippet id={id} /> */}
        <EcommerceRentalCalendarContainer
          id={id}
          price={price}
          currency={currency}
          slug={slug}
        />

        {/* <AddToBasket id={id}>Add to Basket</AddToBasket> */}
      </Grid>
    </Grid>
  )
}

export default ProductRental;