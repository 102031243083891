import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import { appActions } from './redux';
import { ecommerceActions } from '../ecommerce/redux';
import { ModalPlacement, } from './components/modal/modal.styled';
import EcommerceProductPopup from '../ecommerce/views/ecommerce-product.popup';
import { ShadowBox } from './components/shadow-box/shadow-box';
import { Sidebar } from './components/sidebar/sidebar';
import Modal from './components/modal/modal';
import { authActions } from '../auth/redux';
import { history } from '../../utils';



const PopupContent = ({ name, props }) => {
  switch (name) {
    case "product":
      return <EcommerceProductPopup {...props} />
    default:
      return `No popup under ${name}`;
  }
}


class AppContainer extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.escFunction = this.escFunction.bind(this);
  }
  componentDidMount = () => {
    // console.log("app container")
    // this.handlePipelineLoad();
    // this.handlePipelineContent();
    if (this.props.popup !== null) {
      this.openPopup(this.props.popup);
    } else {
      this.closePopup(this.props.popup, this.props.popupParams)
    }

    if (this.props.orderUUID && this.props.orderUUID !== '') {
      if (this.props.timeout !== '') {
        // let today = new Date();
        // let timeoutCheck = new Date(this.props.timeout);
        // timeoutCheck.setDate(timeoutCheck.getDate() + 1);

        // console.log(timeoutCheck)

        // if (today > timeoutCheck) {
        //   alert("order cleared: " + timeoutCheck)
        //   this.props.clearOrder();
        // }
      }
      // console.log("loading order data");
      this.props.getOrder(this.props.orderUUID);
    }
    this.handleEcommerce();
    this.handleLocationActions(this.props.history.location);
    this.handleErrors();
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.popup !== this.props.popup) {
      if (this.props.popup !== null) {
        this.openPopup(this.props.popup);
      } else {
        this.closePopup(prevProps.popup, prevProps.popupParams)
      }
    }


    if (this.props.location && this.props.location !== prevProps.location) {
      this.closePopup();
      this.handleEcommerce();
    }

    if (this.props.orderState && this.props.orderState !== prevProps.orderState) {
      this.handleEcommerce();
    }

    this.handleLocationActions(this.props.history.location);
    this.handleErrors();

    document.addEventListener("click", this.handleClick, false);
    document.addEventListener("touchstart", this.handleClick, false);
    document.addEventListener("keydown", this.escFunction, false);
  }

  componentWillUnmount = () => {
    document.removeEventListener("click", this.handleClick, false);
    document.removeEventListener("touchstart", this.handleClick, false);
    document.removeEventListener("keydown", this.escFunction, false);
  }

  handleErrors = () => {
    if (this.props.error && typeof (this.props.error) === "object") {
      for (const element of Object.values(this.props.error)) {
        if (element?.detail && element?.detail === "Invalid token.") {
          this.props.addMessage("red", "Invalid auth token. You have been logged out.")
          this.props.authClear();
        }
      }
    }
  }

  handleEcommerce = () => {
    if (this.props.orderState === "rejected") {
      history.push('/checkout/failure')
    }

    if (this.props.orderState === "completed" && this.props.location.pathname !== "/checkout/success") {
      this.props.clearOrder();
      console.log(this.props.location.pathname)
    }

    // /checkout/success

  }

  handleLocationActions = ({ pathname }) => {
    // if (!this.props.popup && !this.props.registrationCompleted && ['/', '/pipeline'].includes(pathname) && this.props.userLoggedIn === true) {
    //   this.props.showPopup('training_welcome', { size: 'video' }, true);
    // }
  }

  handleClick = (event) => {
    const specifiedElement = document.getElementById("popup-container");
    if (specifiedElement && this.props.popup !== '') {
      const isClickInside = specifiedElement.contains(event.target);
      if (!isClickInside) {
        // TODO must be delayed otherwise it registeres click before popup is visible
        // console.log("click outside")
      }
    }
  }

  escFunction = (event) => {
    if (event.keyCode === 27) {
      this.props.hidePopup();
    }
  }

  openPopup = (popup) => {
    if (popup === "product" && this.props.popupParams?.product_slug) {
      // const productSlug = this.props.popupParams.product_slug;
      // window.history.pushState("product", `Product Slug: ${productSlug}`, `/p/${productSlug}`);
    }
  }

  closePopup = (popup, params) => {
    this.props.hidePopup();

    if (popup === "product") {
      // history.goBack();
    }

  }
  // 

  render() {
    const { popup, sidebar } = this.props
    return (
      <>
        <ModalPlacement>
          <Modal {...this.props} />
        </ModalPlacement>
        {popup
          ? <ShadowBox><PopupContent name={popup} /></ShadowBox>
          : null
        }
        {sidebar
          ? <Sidebar />
          : null
        }
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  error: state.app.error,
  loading: state.app.loading,
  popup: state.app.popup.name,
  sidebar: state.app.sidebar,
  popupParams: state.app.popup.params,
  orderUUID: state.ecommerce.uuid,
  orderState: state.ecommerce.state,
  timeout: state.ecommerce.timeout,
})

const mapDispatchToProps = (dispatch) => ({
  authClear: () => dispatch(authActions.clearToken()),
  hidePopup: () => dispatch(appActions.popup.hide()),
  addMessage: (color, text) => dispatch(appActions.modal.addMessage(color, text)),
  showPopup: (name, params, fixed) => dispatch(appActions.popup.show(name, params, fixed)),
  getOrder: (uuid) => dispatch(ecommerceActions.order.get(uuid)),
  clearOrder: () => dispatch(ecommerceActions.order.clear()),
  clearTimeout: () => dispatch(ecommerceActions.order.timeout.clear()),
  // showSidebar: () => dispatch(appActions.sidebar.show({ sidebar: "support" })),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppContainer));
