import React from 'react';
import { connect } from 'react-redux';
import { appActions } from '../../app/redux';
import { LoadingSpinner } from '../../../ui-components';
import * as ProductDetails from '../components/product-details';
import {
  BeTritonAPI,
} from '../../../utils';


const ProductDetailsView = ({ nature, data, loading }) => {
  switch (nature) {
    default:
    case 'product':
      return <ProductDetails.Product loading={loading} {...data} />
    case 'pre-order':
      return <ProductDetails.PreOrder loading={loading} {...data} />
    case 'rental':
      return <ProductDetails.Rental loading={loading} {...data} />
  }
}

class EcommerceProductContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
    };
  }

  componentDidMount = () => {
    if (this.props.match?.params)
      this.getProduct(this.props.match.params.product_slug);

    if(this.props.productSlug)
      this.getProduct(this.props.productSlug);
  }

  getProduct = async (slug) => {
    this.props.appRequest({
      domain: "product",
      loading: true,
      error: null,
    })
    try {
      const res = await BeTritonAPI.get(`v1/ecommerce/product/${slug}/`, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      this.props.appRequestClear({ domain: "product" })
      this.setState({
        data: { ...res.data },
      })


    } catch (err) {
      this.props.appRequest({
        domain: "product",
        loading: false,
        error: err,
      })
    }
  };

  render() {
    const product = this.state.data || {}

    if (this.props.loading)
      return <LoadingSpinner size="medium" />

    return <ProductDetailsView nature={product.nature} data={product} loading={this.props.loading} />
  }
}

const mapStateToProps = (state) => ({
  error: state.app.error?.product || null,
  loading: state.app.loading?.product || false,
  productSlug: state.app.popup.params?.product_slug || undefined,
})

const mapDispatchToProps = (dispatch) => ({
  appRequest: (request) => dispatch(appActions.request(request)),
  appRequestClear: (request) => dispatch(appActions.requestClear(request)),
})

export default connect(mapStateToProps, mapDispatchToProps)(EcommerceProductContainer);
