import styled, { css } from "styled-components";


export const MenuItemList = styled.div`
  ${props => props.container && css`
    position: relative;
    width: 100%;
    flex-direction: column;
    align-items: stretch;
    text-align: left;
    margin: -1px 0 -2px -2px;
  `}

  ${props => props.social && css`
    position: relative;
    width: 100%;
    flex-direction: column;
    align-items: stretch;
    text-align: left;
    margin: -1px 0 -2px -2px;
  `}

  ${props => props.item && css`
    position: relative;
    margin-right: -4px;
    margin-bottom: 1px;
    z-index: 2;
    display: none;

    ${props => props.appearance === "always" && css`
      display: block;    
    `}
  
    ${props => props.appearance === "mobile" && css`
      @media ${({ theme }) => theme.device.mobile}{
      display: block;
      }
    `}
  `}
`;

export const CustomButtonSpan = styled.span`
  i {
    color: ${({ theme }) => theme.thirdiary.main};
  }
  small {
    text-transform: lowercase !important;
  }
`;

export const ToogleWrapperText = styled.div`
  margin-right: 6px;
  padding-left: 4px;
  font-family: 'Quantico', sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.primary.main};

  @media ${({ theme }) => theme.device.mobile}{
    display: none;
  }
`



const cutSizeWrap = 5;

export const ToogleWrapper = styled.div`
  position: relative;
  display: flex;
  padding: 6px 8px;
  align-items: center;

  margin-left: 2px; // for [Rental] [Preorder] buttons spacing match

  svg{
    position: relative;
    fill: ${({ theme }) => theme.primary.main};
  }

  @media ${({ theme }) => theme.device.desktop}{
    &:hover{
      ${ToogleWrapperText}{
        color: ${({ theme }) => theme.primary.dark};
      }
      svg{
        fill: ${({ theme }) => theme.primary.dark};
      }
    }
  }

  ${props => props.active && css`
    background: ${({ theme }) => theme.primary.main};
    ${ToogleWrapperText}{
      color: ${({ theme }) => theme.text.white};
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: -${cutSizeWrap}px;
      bottom: 0;
      border-top: ${cutSizeWrap}px solid transparent;
      border-left: ${cutSizeWrap}px solid ${({ theme }) => theme.primary.main};
    }
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: -${cutSizeWrap}px;
      bottom: 0;
      border-top: ${cutSizeWrap}px solid transparent;
      border-right: ${cutSizeWrap}px solid ${({ theme }) => theme.primary.main};
    }

    svg{
      fill: ${({ theme }) => theme.text.white};
    }

    @media ${({ theme }) => theme.device.desktop}{
      &:hover{
        ${ToogleWrapperText}{
          color: ${({ theme }) => theme.text.white};
        }
        background: ${({ theme }) => theme.primary.dark};
        &::before { border-left: ${cutSizeWrap}px solid ${({ theme }) => theme.primary.dark};}
        &::after { border-right: ${cutSizeWrap}px solid ${({ theme }) => theme.primary.dark};}
        svg{
          fill: ${({ theme }) => theme.text.white};
        }
      }
    }

    @media ${({ theme }) => theme.device.mobile}{
      top: 8px;
      padding: 12px 8px 22px;
    }
  `}
`;


export const SocialWrapper = styled.div`
  position: relative;
  z-index: 2;
  margin: 3px -5px 1px -2px;
  display: none;
  @media ${({ theme }) => theme.device.mobile}{
    display: block;
  }
  `

export const SocialGrid = styled.div`
  
  ${props => props.container && css`
    display: flex;
    justify-content: center;
    align-items: center;
  `}

  ${props => props.item && css`
    position: relative;
    flex: 1;
    text-align: center;
    padding: 0 1px 0 0;
    &:first-child{
      padding-left: 4px;
    }
    &:last-child{
      padding-right: 0;
    }
  `}

`
