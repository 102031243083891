import React from 'react';
import {
  TextField,
  Select,
  SelectOption,
  Checkbox,
  ZWrapper,
  ZTitle,
  // PageTitle,
  PageHTML,
  Button,
} from "../../../../ui-components";
import {
  Container,
  Text,
  Disclaimer,
  Response,
  CheckboxLabel,
  SurveyDescription,
} from './survey-question.styled';
import { beLandingURL } from '../../../../utils';


const ResponseInput = ({ label, name, nature, value, choices, onChange, error, required, multiple_max }) => {
  let multipleValues = value.length === 0 ? [] : [...value.split(",")];
  let disabled = false;

  if (nature === "multiple" && multiple_max > 0) {
    const multiple_now = value === '' ? 0 : value.split(",").length
    if (multiple_now >= multiple_max) {
      disabled = true;
    }
  }

  const handleMultipleChange = (event) => {
    const { name: targetName, type } = event.target;

    if (multipleValues.includes(targetName)) {
      const index = multipleValues.indexOf(targetName);
      multipleValues.splice(index, 1);
    } else {
      multipleValues.push(targetName)
    }

    onChange({
      target: {
        name,
        type,
        value: multipleValues.join(","),
      }
    })
  }

  switch (nature) {
    default:
    case "single":
      return (
        <Select
          id={name}
          name={name}
          label={label}
          onChange={onChange}
          hasError={error}
          value={value}
          required={required}
        >
          <SelectOption value=""></SelectOption>
          {choices.map((data, index) =>
            <SelectOption value={data.key} key={index}>{data.text}</SelectOption>
          )}
        </Select>
      )
    case "multiple":
      return (<>
        <Text>{label}</Text>
        {choices.map((data, index) =>
          <CheckboxLabel key={index} disabled={disabled && !value.split(",").includes(data.key)}>
            <Checkbox
              id={data.key}
              name={data.key}
              checked={multipleValues.includes(data.key)}
              disabled={disabled && !value.split(",").includes(data.key)}
              onChange={handleMultipleChange}
            />
            &nbsp;{data.text}
          </CheckboxLabel>
        )}
        <br />
      </>)
    case "free_text":
      return (
        <TextField
          id={name}
          name={name}
          label={label}
          type="text"
          value={value}
          hasError={error}
          onChange={onChange}
          required={required}
        />)
    case "email":
      return (
        <TextField
          id={name}
          name={name}
          label={label}
          type="text"
          value={value}
          hasError={error}
          onChange={onChange}
          required={required}
        />)
  }
}

export const Question = ({ id, text, disclaimer, nature, multiple_max, required, error, choices, onChange, value, layout }) => (
  <Container layout={layout}>
    <Response>
      <ResponseInput
        label={text}
        name={id}
        nature={nature}
        value={value}
        choices={choices}
        onChange={onChange}
        multiple_max={multiple_max}
        required={required}
        error={error}
      />
    {disclaimer && <Disclaimer>{disclaimer}</Disclaimer>}
    </Response>
  </Container>
)




const FormQuestionWrapper = ({ question, error, response, layout, onChange }) => {
  const hasError = error && error[question.id]

  if (layout === "full") {
    return (
      <ZWrapper color="white" size="normal" padding style={{ marginBottom: "8px" }}>
        <Question
          value={response[question.id] || ""}
          layout={layout}
          error={hasError}
          onChange={onChange}
          {...question}
        />
      </ZWrapper>
    )
  }
  return (
    <Question
      value={response[question.id] || ""}
      layout={layout}
      error={hasError}
      onChange={onChange}
      {...question}
    />
  )

}

export const FormBuilder = ({ response, loading, error, questions, layout, onChange, onSubmit, hasUser = false }) => {
  let output = []
  questions.forEach((question, index) => {
    output.push(
      <FormQuestionWrapper
        key={index}
        question={question}
        layout={layout}
        error={error}
        response={response}
        onChange={onChange}
        {...question}
      />
    )
  })

  if (!hasUser) {
    output.push(
      <FormQuestionWrapper
        key="user_key"
        question={{
          id: "email",
          text: "Email address",
          disclaimer: "Please enter your email address",
          nature: "free_text",
          required: true,
          choices: [],
        }}
        layout={layout}
        error={error}
        response={response}
        onChange={onChange}
      />)
  }


  output.push(
    <Button
      key="button_key"
      type="button"
      onClick={onSubmit}
      color="secondary"
      size="large"
      disabled={loading}
      inline
    >{loading ? "Loading..." : "Submit"}</Button>
  )

  return output;
};

export const LayoutBuildFull = ({ children, structure }) => (
  <>
    {/* <PageTitle>{structure.title}</PageTitle> */}
    {structure.description && <PageHTML center dangerouslySetInnerHTML={{ __html: `<p>${structure.description}</p>` }} />}
    {children}
  </>
)


export const LayoutBuildEmbeded = ({ children, structure }) => (
  <ZWrapper color="white" size="normal" padding>
    <ZTitle align="left" size="large">{structure.title}</ZTitle>
    {structure.description && <SurveyDescription>{structure.description}</SurveyDescription>}
    {children}
  </ZWrapper>
)

export const LayouSuccess = ({ title, description }) => (
  <ZWrapper color="white" size="normal" padding>
    <ZTitle align="left" size="large">{title}</ZTitle>
    {description && <SurveyDescription>{description}</SurveyDescription>}

    <Button
      type="anchor"
      target="_self"
      color="secondary"
      size="normal"
      to={beLandingURL}
    >
      Go to home page
    </Button>
    {/* <Button
      type="link"
      to="/shop"
      color="secondary"
      size="normal"
      inline
    >Back to the Store</Button> */}
  </ZWrapper>
)