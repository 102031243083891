import styled, { css } from 'styled-components';

export const Container = styled.div`
  margin: 16px 0;

  ${props => props.nomargin && css`
    margin: 0 0;
  `}
`;


export const BasketButton = styled.div`
  
  display: inline-flex;
  margin: 16px 0 16px;


  /* ${props => props.nomargin && css`
    margin: 8px 0;
  `} */
`



export const Quantity = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 54px;
  margin-right: 4px;
`;

export const QuantityLabel = styled.div`
  position: absolute;
  top: -12px;
  left: 0;
  right: 0;
  font-family: 'Quantico', sans-serif;
  font-size: 10px;
  line-height: 15px;
  text-transform: uppercase;
  text-align: center;
  color: ${({ theme }) => theme.text.secondary};
`;

export const QuantityInput = styled.input`
  display: inline-block;
  margin: 6px 0;
  text-align: center;
  padding: 5px;
  line-height: 18px;
  font-size: 16px;
  background: transparent;
  color: ${({ theme }) => theme.text.primary};
  border: 1px solid ${({ theme }) => theme.shades.line};

  &:focus{
    outline: none;
  }
`;

