import React from 'react';
import { connect } from 'react-redux';
import { appActions } from '../../app/redux';
import { userActions } from '../../user/redux';
import {
  history,
} from '../../../utils';



class AuthContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sequence: []
    };
  }

  appendSequence = (newSequence) => {
    // console.log(newSequence)
    this.setState({
      sequence: [
        ...this.state.sequence,
        newSequence,
      ]
    })
  }

  componentDidMount = async () => {
    if (this.props.token === null) {
      history.push('/auth/login')
    } else {
      try {
        // await this.appendSequence("Manage invitation")

        await this.appendSequence("Loading user...")
        await this.props.getUser()

        this.handleRedirect();
      } catch (e) {
        console.log(e)
      }
    }
  }

  handleRedirect = () => {
    history.push("/user")
  }


  render() {
    return <>
      {this.state.sequence.map((data, index) => (
        <div key={index} style={{ margin: "8px 0" }}>{data}...</div>
      ))}
    </>;
  }
}

const mapStateToProps = state => ({
  token: state.auth.token,
  user: state.user || {},
})

const mapDispatchToProps = (dispatch) => ({
  appRequest: (request) => dispatch(appActions.request(request)),
  appRequestClear: (request) => dispatch(appActions.requestClear(request)),
  appMessage: (color, msg, timout) => dispatch(appActions.modal.addMessage(color, msg, timout)),
  getUser: () => dispatch(userActions.get()),
  // getCompanyList: () => dispatch(userActions.company.getList()),
  // selectCompany: (id) => dispatch(companyActions.details.select(id)),
  // getPipelineList: () => dispatch(companyActions.pipeline.getList()),
  // selecPipeline: (id) => dispatch(pipelineActions.details.select(id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AuthContainer);
