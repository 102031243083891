import React from 'react';
import {
  Header,
  Footer,
  Layout,
  LayoutHeader,
  LayoutMain,
  LayoutProgress,
  LayoutSummary,
  LayoutFooter,
} from '../../../ui-components';
import Summary from '../components/summary/summary';
import StepProgress from '../components/step-progress/step-progress';
import EcommercePaymentContainer from '../containers/ecommerce-payment.container';


const EcommercePaymentView = (props) => (
  <Layout id="layout" layout="progress-summary">
    <LayoutHeader>
      <Header checkout={true} />
    </LayoutHeader>
    <LayoutProgress>
      <StepProgress match={props.match} />
    </LayoutProgress>
    <LayoutSummary>
      <Summary />
    </LayoutSummary>
    <LayoutMain>
      <EcommercePaymentContainer />
    </LayoutMain>
    <LayoutFooter>
      <Footer />
    </LayoutFooter>
  </Layout>
);

export default EcommercePaymentView;
