import React from 'react';
import { connect } from 'react-redux';
import { appActions } from '../../app/redux';
import {
  LoadingSpinner,
  ZWrapper,
} from '../../../ui-components';
import ProductList from '../components/product-list/product-list';
import {
  ListContainer,
  ListTitle,
  ListProducts,
  ListEmptyWrapper,
} from '../components/product-list/product-list.styled';
import {
  BeTritonAPI,
} from '../../../utils';


const BLOCK_COUNT = 3;

const ProductBlock = ({ title, nature, list, clickOpens = "popup" }) => {
  const extraBlocks = BLOCK_COUNT - list.length % BLOCK_COUNT;

  const extraBlockComponents = []
  for (let i = 0; i < extraBlocks; i++) {
    extraBlockComponents.push(
      <ListEmptyWrapper key={i}>
        <ZWrapper
          color="background-dark"
          style={{ width: "100%", opacity: '0.5', }}
        >
        </ZWrapper>
      </ListEmptyWrapper>
    )
  }

  return (
    <ListContainer>
      {title
        ? <ListTitle nature={nature}>{title}</ListTitle>
        : null}
      <ZWrapper
        color="background-dark-2"
        size="small"
        style={{ width: "100%", padding: "3px 0px" }}
      >
        <ListProducts>

          {list.map((product, index) => (
            <ProductList
              key={index}
              clickOpens={clickOpens}
              {...product}
            />
          ))}
          {/* {extraBlockComponents} */}
        </ListProducts>
      </ZWrapper>
    </ListContainer>
  )
}


class EcommerceProductListContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      collection: {},
      nature: "all",
    };
  }

  componentDidMount = () => {
    const nature = this.props.nature || "all"
    this.getProductList(nature);
  }

  componentWillUnmount = () => {
  }

  componentDidUpdate = (prevProps) => {
  }

  getProductList = async (nature = "all") => {
    this.props.appRequest({
      domain: "product_list",
      loading: true,
      error: null,
    })
    try {
      const res = await BeTritonAPI.get(`v1/ecommerce/product/list/`, {
        headers: {
          'Content-Type': 'application/json',
        },
        params: {
          nature,
        }
      })
      this.props.appRequestClear({ domain: "product_list" })

      if (nature === "all") {
        this.setState({
          collection: res.data,
          nature,
        })
      } else {
        this.setState({
          list: res.data,
          nature,
        })
      }

    } catch (err) {
      this.props.appRequest({
        domain: "product_list",
        loading: false,
        error: err,
      })
    }
  };

  render() {
    const { collection, nature, list } = this.state;

    if (this.props.loading)
      return <LoadingSpinner size="medium" />


    if (nature === "all") {

      const preorders = collection?.preorder || [];
      const rentals = collection?.rental || [];
      const products = collection?.product || [];

      return (
        <>
          {preorders.length > 0
            ? <ProductBlock
              title="BeTRITON"
              nature="pre-order"
              list={preorders || []}
              clickOpens={this.props.clickOpens}
            />
            : null}

          {rentals.length > 0
            ? < ProductBlock
              title="Rentals"
              nature="rental"
              list={rentals || []}
              clickOpens={this.props.clickOpens}
            />
            : null
          }
          {products.length > 0
            ? <ProductBlock
              title="Accessories"
              // title="Clothing &amp; Accessories"
              nature="product"
              list={products || []}
              clickOpens={this.props.clickOpens}
            />
            : null}
        </>
      )
    }

    if (['pre-order', 'rental', 'product'].includes(nature)) {
      return (
        <ProductBlock
          nature="pre-order"
          list={list || []}
          clickOpens={this.props.clickOpens}
        />
      )
    }

    return null
  }
}

const mapStateToProps = (state) => ({
  error: state.app.error?.product_list || null,
  loading: state.app.loading?.product_list || false,
})

const mapDispatchToProps = (dispatch) => ({
  appRequest: (request) => dispatch(appActions.request(request)),
  appRequestClear: (request) => dispatch(appActions.requestClear(request)),
})

export default connect(mapStateToProps, mapDispatchToProps)(EcommerceProductListContainer);
