import React from 'react';
import {
  Header,
  Footer,
  Layout,
  LayoutHeader,
  LayoutProgress,
  LayoutMain,
  LayoutFooter,
} from '../../../ui-components';
import StepSuccess from '../components/step-progress/step-success';
import EcommerceSuccessContainer from '../containers/ecommerce-success.container';
import MarketingSurveyContainer from '../../marketing/containers/marketing-survey.container';


const EcommerceSuccessView = (props) => (
  <Layout id="layout" layout="success">
    <LayoutHeader>
      <Header />
    </LayoutHeader>
    <LayoutProgress>
      <StepSuccess />
    </LayoutProgress>
    <LayoutMain>
      <EcommerceSuccessContainer />
      <br />
      <MarketingSurveyContainer slug="community-profile" layout="embeded" />
    </LayoutMain>
    <LayoutFooter>
      <Footer />
    </LayoutFooter>
  </Layout>
);

export default EcommerceSuccessView;
