import React from 'react';
import { connect } from 'react-redux';
import { appActions } from '../../app/redux';
import { ecommerceActions } from '../redux';
import {
  CheckoutWrapper,
  Title,
  Footer,
  Form,
  Tos,
  TosContent,
} from '../components/checkout-wrapper/checkout-wrapper';
import {
  Button,
  TextField,
  Select,
  SelectOption,
  Checkbox,
} from "../../../ui-components";
import {
  beLandingURL,
  countries,
  history,
} from '../../../utils';


class EcommerceUserContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      address: '',
      address_2: '',
      city: '',
      state: '',
      country: '',
      postal_code: '',

      tos: {
        pre_order: false,
        rental: false,
        product: false,
      },
      tosRequired: {
        pre_order: false,
        rental: false,
        product: false,
      }

      // tos: false,
      // tosChecked: false,



    };
  }

  componentDidMount = () => {
    this.handleData();
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.error && this.props.error !== prevProps.error) {
      this.props.addMessage("red", "Please fill required fields", 2000);
    }

    if ((JSON.stringify(this.props.user) !== JSON.stringify(prevProps.user) && JSON.stringify(this.props.user) !== "{}") ||
      (JSON.stringify(this.props.shipping) !== JSON.stringify(prevProps.shipping) && JSON.stringify(this.props.shipping) !== "{}") ||
      (this.props.orderState !== prevProps.orderState)) {
      this.handleData();
    }
  }

  handleData = () => {
    let hasTosPreOrder = false;
    let hasTosRental = false;
    let hasTosProduct = false;

    this.props.items.forEach((element) => {
      if (element.nature === "pre-order" && !hasTosPreOrder) {
        hasTosPreOrder = true;
      }
      if (element.nature === "rental" && !hasTosRental) {
        hasTosRental = true;
      }
      if (element.nature === "product" && !hasTosProduct) {
        hasTosProduct = true;
      }
    })

    let data = {
      tos: {
        pre_order: false,
        rental: false,
        product: false,
      },
      tosRequired: {
        pre_order: hasTosPreOrder,
        rental: hasTosRental,
        product: hasTosProduct,
      }
    }

    if (this.props.user.id) {
      const user = this.props.user;
      data = {
        ...data,
        name: user.name,
        email: user.email,
      }
    }
    if (this.props.shipping.address) {
      const shipping = this.props.shipping;
      data = {
        ...data,
        address: shipping.address,
        address_2: shipping.address_2 || '',
        city: shipping.city,
        state: shipping.state || '',
        country: shipping.country,
        postal_code: shipping.postal_code,
      }
    }
    this.setState({
      ...data,
    })
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    })
  }

  handleTosChange = (area) => {
    this.setState({
      tos: {
        ...this.state.tos,
        [area]: !this.state.tos[area],
      }
    })
  }


  handlePrev = (e) => {
    if (this.props.items.length === 1) {
      const item = this.props.items[0]
      history.push(`/p/${item.slug}`);

    } else {
      history.push("/checkout/basket");
    }
    window.scroll({ top: 0 });
  }

  handleNext = (e) => {
    history.push("/checkout/payment");
    window.scroll({ top: 0 });
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    let payload = {
      name: this.state.name,
      email: this.state.email,
      address: this.state.address,
      address_2: this.state.address_2,
      city: this.state.city,
      state: this.state.state,
      country: this.state.country,
      postal_code: this.state.postal_code,
    }

    if (this.state.tosRequired.pre_order)
      payload.tos_preorder = this.state.tos.pre_order;

    if (this.state.tosRequired.rental)
      payload.tos_rental = this.state.tos.rental;

    if (this.state.tosRequired.product)
      payload.tos_product = this.state.tos.product;


    await this.props.setUserAndAddress(payload);

    this.setState({
      tos: {
        pre_order: false,
        rental: false,
        product: false,
      },
    })
    if (!this.props.error) {
      this.handleNext();
    }
  }

  render() {
    // const loading = this.props.loading;
    const error = this.props.error || {};
    const errorOrder = this.props.errorOrder || {};

    return (
      <CheckoutWrapper error={errorOrder}>
        <Title>User information</Title>

        <Form container>
          <TextField
            id="name"
            type="text"
            label="Full Name"
            name="name"
            hasError={error['name']}
            value={this.state.name}
            onChange={this.handleChange}
            required
          />
          <TextField
            id="email"
            label="Email Address"
            name="email"
            type="email"
            hasError={error['email']}
            value={this.state.email}
            onChange={this.handleChange}
            required
          />
        </Form>

        <Title>Your address</Title>

        <Form container>
          <TextField
            id="address"
            label="Address"
            name="address"
            type="text"
            value={this.state.address}
            hasError={error['address']}
            onChange={this.handleChange}
            required
          />
          <TextField
            id="address_2"
            label="Address Line 2"
            name="address_2"
            type="text"
            value={this.state.address_2}
            onChange={this.handleChange}
          />
          <TextField
            id="city"
            label="City"
            name="city"
            type="text"
            value={this.state.city}
            hasError={error['city']}
            onChange={this.handleChange}
            required
          />

          <Select
            id="country"
            label="Country"
            name="country"
            onChange={this.handleChange}
            hasError={error['country']}
            value={this.state.country}
            required
          >
            <SelectOption value=""></SelectOption>
            {countries.map((data, index) =>
              <SelectOption value={data.code} key={index}>{data.name}</SelectOption>
            )}
          </Select>

          <Form row>
            <Form col>
              <TextField
                id="state"
                label="State"
                name="state"
                type="text"
                value={this.state.state}
                onChange={this.handleChange}
              />
            </Form>
            <Form col>
              <TextField
                id="postal_code"
                label="Postal Code"
                name="postal_code"
                type="text"
                onChange={this.handleChange}
                hasError={error['postal_code']}
                value={this.state.postal_code}
                required
              />
            </Form>
          </Form>

          {this.state.tosRequired.pre_order
            ? (
              <Tos container error={!this.state.tos.pre_order && error['tos_preorder']}>
                <TosContent checkbox>
                  <Checkbox
                    checked={this.state.tos.pre_order}
                    onChange={() => this.handleTosChange("pre_order")}
                  />
                </TosContent>
                <TosContent text>
                  I have read and accept the
                  <a href={`${beLandingURL}/terms/`} target="_blank" rel="noreferrer"> pre-order Terms &amp; Conditions </a>
                  and
                  <a href={`${beLandingURL}/privacy/`} target="_blank" rel="noreferrer"> privacy policy </a>.
                </TosContent>
              </Tos>
            )
            : null}

          {this.state.tosRequired.rental
            ? (
              <Tos container error={!this.state.tos.rental && error['tos_rental']}>
                <TosContent checkbox>
                  <Checkbox
                    checked={this.state.tos.rental}
                    onChange={() => this.handleTosChange("rental")}
                  />
                </TosContent>
                <TosContent text>
                  I have read and accept the
                  <a href={`${beLandingURL}/terms-rental/`} target="_blank" rel="noreferrer"> rental Terms &amp; Conditions </a>
                  and
                  <a href={`${beLandingURL}/privacy/`} target="_blank" rel="noreferrer"> privacy policy </a>.
                </TosContent>
              </Tos>
            )
            : null}

          {this.state.tosRequired.product
            ? (
              <Tos container error={!this.state.tos.product && error['tos_product']}>
                <TosContent checkbox>
                  <Checkbox
                    checked={this.state.tos.product}
                    onChange={() => this.handleTosChange("product")}
                  />
                </TosContent>
                <TosContent text>
                  I have read and accept the
                  <a href={`${beLandingURL}/terms/`} target="_blank" rel="noreferrer"> product Terms &amp; Conditions </a>
                  and
                  <a href={`${beLandingURL}/privacy/`} target="_blank" rel="noreferrer"> privacy policy </a>.
                </TosContent>
              </Tos>
            )
            : null}


          {/* <Tos container error={!this.state.tosChecked && error['tos']}>
            <TosContent checkbox>
              <Checkbox
                checked={this.state.tos}
                onChange={this.handleTosChange}
              />
            </TosContent>
            <TosContent text>
              I have read and accept the
              <a href={`${beLandingURL}/terms/`} target="_blank" rel="noreferrer"> pre-order Terms &amp; Conditions </a>
              and
              <a href={`${beLandingURL}/privacy/`} target="_blank" rel="noreferrer"> privacy policy </a>.
            </TosContent>
          </Tos> */}


        </Form>

        <Footer container>
          <Footer left>
            <Button
              type="button"
              size="large"
              color="white"
              onClick={this.handlePrev}
              inline
            >
              Back
            </Button>

          </Footer>
          <Footer right>
            <Button
              type="button"
              size="large"
              color="secondary"
              onClick={this.handleSubmit}
              inline
            >
              Continue
            </Button>
          </Footer>
        </Footer>

      </CheckoutWrapper >
    )
  }
}

const mapStateToProps = (state) => ({
  errorOrder: state.app.error?.ecommerce || null,
  error: state.app.error?.ecommerce_user || null,
  loading: state.app.loading?.ecommerce_user || false,
  orderUUID: state.ecommerce.uuid,
  orderState: state.ecommerce.state,
  user: state.ecommerce.user || {},
  shipping: state.ecommerce.shipping || {},
  items: state.ecommerce.items || [],
})

const mapDispatchToProps = (dispatch) => ({
  appRequest: (request) => dispatch(appActions.request(request)),
  appRequestClear: (request) => dispatch(appActions.requestClear(request)),
  setUserAndAddress: (payload) => dispatch(ecommerceActions.user.setUserAndAddress(payload)),
  addMessage: (color, text, timeout) => dispatch(appActions.modal.addMessage(color, text, timeout)),
})

export default connect(mapStateToProps, mapDispatchToProps)(EcommerceUserContainer);
