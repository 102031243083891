import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import { appActions } from '../../app/redux';
import {
  BeTritonAPI,
} from '../../../utils';
import {
  LayoutBuildEmbeded,
  LayoutBuildFull,
  LayouSuccess,
  FormBuilder,
} from "../components/survey-question/survey-question";


class MarketingSurveyContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
      slug: '',
      structure: {},
      response: {},
    };
  }

  componentDidMount = () => {
    if (this.props.slug) {
      this.init(this.props.slug);
    } else if (this.props?.match.params.slug) {
      this.init(this.props.match.params.slug);
    }
  }

  init = async (slug) => {
    this.props.appRequest({
      domain: "marketing_survey",
      loading: true,
      error: null,
    })


    try {
      const res = await BeTritonAPI.get(`v1/marketing/survey/${slug}/`, {
        headers: {
          'Content-Type': 'application/json',
        }
      })
      const response = {}
      res.data.questions.forEach(q => {
        response[q.id] = "";
      })

      this.setState({
        slug,
        structure: res.data,
        response,
      })
      this.props.appRequestClear({ domain: "marketing_survey" })
    } catch (err) {
      this.props.appRequest({
        domain: "marketing_survey",
        loading: false,
        error: err,
      })
    }
  }

  submit = async (slug, payload, user_kwargs) => {
    this.props.appRequest({
      domain: "marketing_survey",
      loading: true,
      error: null,
    })

    const ignoreEmailQuery = this.props.ignoreEmail ? "?ignore_email=true" : "" 
    try {
      await BeTritonAPI.post(`v1/marketing/survey/${slug}/${ignoreEmailQuery}`, {
        ...user_kwargs,
        responses: [...payload],
      }
        , {
          headers: {
            'Content-Type': 'application/json',
          }
        })
      this.setState({
        submitted: true,
      })
      this.props.appRequestClear({ domain: "marketing_survey" })
    } catch (err) {
      this.props.appRequest({
        domain: "marketing_survey",
        loading: false,
        error: err,
      })
    }
  }


  handleChange = (event) => {
    const { name, value } = event.target;
    const { response } = this.state;
    this.setState({
      response: {
        ...response,
        [name]: value,
      }
    })
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const { response, slug } = this.state;
    const payload = [];
    const email = this.props.email || this.props.ecommerceEmail
    let user_kwargs = {}

    if (this.props.email || this.props.ecommerceEmail) {
      user_kwargs = {
        "email": email,
      }
    }

    for (const key in response) {
      if (key === "email") {
        user_kwargs = {
          "email": response[key],
        }
      } else {
        payload.push({
          "question_id": key,
          "data": response[key],
        })
      }
    }

    this.submit(slug, payload, user_kwargs);
  }

  render = () => {
    const { structure, response, submitted } = this.state;
    const { loading, ecommerceEmail, email, error, ignoreEmail =null, layout = "embeded" } = this.props;
    const questions = structure.questions || []
    const hasUser = (email || ecommerceEmail || ignoreEmail) !== null;


    if (submitted) {
      return (
        <LayouSuccess
          title="Thank you for your feedback"
          description="Your responses have been successfully submitted!"
        />
      )
    }

    if (layout === "full") {
      return (
        <LayoutBuildFull structure={structure}>
          <FormBuilder
            loading={loading}
            error={error}
            layout={layout}
            questions={questions}
            response={response}
            hasUser={hasUser}
            onChange={this.handleChange}
            onSubmit={this.handleSubmit}
          />
        </LayoutBuildFull>
      )
    }

    return (
      <LayoutBuildEmbeded structure={structure}>
        <FormBuilder
          loading={loading}
          error={error}
          layout={layout}
          questions={questions}
          response={response}
          hasUser={hasUser}

          onChange={this.handleChange}
          onSubmit={this.handleSubmit}
        />
      </LayoutBuildEmbeded>
    )
  }
}

const mapStateToProps = state => ({
  error: state.app.error?.marketing_survey || null,
  ecommerceEmail: state.ecommerce?.user?.email || null,
  loading: state.app.loading?.marketing_survey || false,
})

const mapDispatchToProps = (dispatch) => ({
  appRequest: (request) => dispatch(appActions.request(request)),
  appRequestClear: (request) => dispatch(appActions.requestClear(request)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MarketingSurveyContainer));
