import { createGlobalStyle } from 'styled-components';
import Normalize from './normalize';

import RobotoRegular from '../fonts/Roboto-Regular.ttf';
import RobotoMedium from '../fonts/Roboto-Medium.ttf';
import RobotoBold from '../fonts/Roboto-Bold.ttf';
import QuanticoRegular from '../fonts/Quantico-Regular.ttf';
import QuanticoBold from '../fonts/Quantico-Bold.ttf';

// 
const GlobalStyle = createGlobalStyle`
  ${Normalize}

  @font-face {
    font-family: Roboto;
    src: url(${RobotoRegular}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: RobotoMedium;
    src: url(${RobotoMedium}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: RobotoBold;
    src: url(${RobotoBold}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: Quantico;
    src: url(${QuanticoRegular}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: QuanticoBold;
    src: url(${QuanticoBold}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html, body {
    height: 100%;
  }

  body{
    color: ${({ theme }) => theme.text.primary};
    background: ${({ theme }) => theme.background.default};
    font-family: Roboto, Arial, Helvetica, sans-serif;
    font-size: 16px;
  }

  a{
    text-decoration: none;
    color: ${({ theme }) => theme.thirdiary.main};
    
    &:hover{
      text-decoration: underline;
    }
  }

  h1 {
    font-size: 37px;
    line-height: 45px;
    margin-top: 16px;
    margin-bottom: 16px;
    color: ${({ theme }) => theme.primary.main};
  }

  h2 {
    font-size: 24px;
    line-height: 28px;
    margin-top: 16px;
    margin-bottom: 16px;
    color: ${({ theme }) => theme.primary.main};
  }

  h3 {
    font-size: 21px;
    line-height: 25px;
    margin-top: 16px;
    margin-bottom: 16px;
    color: ${({ theme }) => theme.primary.main};
  }

  p {
    font-size: 16px;
    line-height: 25px;
    margin-top: 16px;
    margin-bottom: 16px;
  }
`;



export default GlobalStyle;
