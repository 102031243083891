import React from 'react';
import { connect } from 'react-redux';
import { ecommerceActions } from '../redux';
import {
  ZWrapper,
  ZTitle,
  // Button,
} from "../../../ui-components";
import {
  Description,
} from '../components/product-details/product.styled.js';


class EcommerceSuccessContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
    };
  }

  componentDidMount = () => {
    this.handleData();
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.orderState !== prevProps.orderState) {
      this.handleData();
    }
  }

  handleData = () => {

    this.setState({
      data: { ...this.props.order },
    })
    if (this.props.orderState === "completed") {
      // this.props.clearOrderSoft();
    }

    if (this.props.orderState === "") {
      // alert("must redirect")
    }

    // console.log(this.props.orderState)
  }

  render() {
    const items = this.state.data?.items || [];

    return (
      <ZWrapper color="white" size="normal" padding>
        <ZTitle align="left" size="large">Congratulations!</ZTitle>
        <Description noLine>
          {items.map((item, index) => {
            if (item.nature === "pre-order") {
              return (
                <p key={index}>
                  You have successfully reserved your <strong>{item.name} version</strong>.
                  Your BeTRITON reference number is: <strong>BT{item?.meta["pre-order"]?.unit_id || ''}</strong>.
                </p>
              )
            }

            if (item.nature === "rental") {
              const rental = item?.meta?.rental || {};

              let rentPeriod = <></>;
              if (rental.date_till === "") {
                rentPeriod = <>on <strong>{rental.date_from}</strong></>;
              } else {
                rentPeriod = <>from <strong>{rental.date_from}</strong> till <strong>{rental.date_till}</strong></>;
              }

              return (
                <p key={index}>
                  You have successfully booked your <strong> BeTRITON Rental - {item.name}</strong> {rentPeriod}.
                  Your rental reference number is: <strong>Rent-{rental.ticket_id}</strong>.
                </p>
              )
            }


            if (item.nature === "product") {
              return (
                <p key={index}>
                  You have successfully ordered <strong>{item.quantity}x {item.name}</strong>.
                </p>
              )
            }

            return null;
          })
          }

          <p>A confirmation e-mail of your order has been sent to you.</p>

        </Description>

        {/* <Button
          type="link"
          to="/shop"
          color="primary"
          size="normal"
          inline
        >Back to the Store</Button> */}

      </ZWrapper>
    )
  }
}

const mapStateToProps = (state) => ({
  orderUUID: state.ecommerce.uuid,
  errorDeal: state.app.error?.ecommerce || null,
  loadingDeal: state.app.loading?.ecommerce || false,
  order: state.ecommerce || {},
  orderState: state.ecommerce.state,
  items: state.ecommerce.items || [],
})

const mapDispatchToProps = (dispatch) => ({
  clearOrderSoft: () => dispatch(ecommerceActions.order.clear()),
})

export default connect(mapStateToProps, mapDispatchToProps)(EcommerceSuccessContainer);
