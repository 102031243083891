import styled, { css } from 'styled-components';
import {
  ZWrapper,
  ZTitle,
  Button,
} from "../../../ui-components";

const Container = styled.div`
  ${props => props.container && css`
    display: flex;
    flex-direction: row;

    @media ${({ theme }) => theme.device.mobile}{
      flex-direction: column;
    }
  `}

  ${props => props.image_place && css`
  `}

  ${props => props.content_place && css`
    flex: 1;
    padding: 8px;
  `}
`;

const Img = styled.img`
  max-width: 320px;
  @media ${({ theme }) => theme.device.mobile}{
    max-width: 100%;
  }
`;


const BeTritonIMG = "https://elasticbeanstalk-eu-north-1-924701075681.s3.eu-north-1.amazonaws.com/media/public/images/cad575e2-48eb-4554-b67c-72a01d4c8edb_landscape_large.jpg"
const TechnicalAnchorURL = "https://betriton.com/BeTRITON_Brochure_single.pdf";

export const PreOrderProduct = () => {
  return (
    <ZWrapper size="normal" color="default">
      <Container container>
        <Container image_place>
          <Img
            src={BeTritonIMG}
            alt="BeTriton Ready-Made"
          />
        </Container>
        <Container content_place>
          <ZTitle>BeTRITON - Ready Made</ZTitle>

          Price offer goes here....


          <br />

          <br />
          <Button
            color="thirdiary"
            size="small"
            type="anchor"
            to={TechnicalAnchorURL}
            tagret="_blank"
            noMargin={true}
          >
            Technical Brochure
          </Button>




        </Container>
      </Container>
    </ZWrapper>
  )
}