export const beLandingURL = process.env.NODE_ENV === "production" ? 'https://betriton.com' : 'https://betriton.com';
export const beAppURL = process.env.NODE_ENV === "production" ? 'https://my.betriton.com' : 'https://my.betriton.com';

const currentDomain = "app"


//  TODO, create auto url generation, based where header is used, if link goes to app and header is in app, use link otherwise anchor
export const beLinkType = (domain) => {
  let nature = "anchor"

  if (domain === "app" && currentDomain === "app") {
    nature = "link"
  }

  if (domain === "landing" && currentDomain === "landing") {
    nature = "anchor"
  }

  return nature;
}


export const beLink = (domain, path) => {
  let url = ""

  if (domain === "landing" && currentDomain !== "landing") {
    url += beLandingURL
  }

  if (domain === "app" && currentDomain !== "app") {
    url += beAppURL
  }

  url += path;

  return url;
}