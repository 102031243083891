import React from 'react';
import { useSelector } from 'react-redux';
import {
  Button,
} from '../../../../ui-components';


const getButtonParams = (view) => {
  switch (view) {
    default:
    case "product":
      return {
        size: "large",
        fullwidth: false,
      }
    case "header":
      return {
        size: "normal",
        fullwidth: true,
      }
  }
}


export const CheckoutButton = ({ view, children }) => {
  const { items } = useSelector(state => ({
    items: state.ecommerce.items || [],
  }));

  const { size, fullwidth } = getButtonParams(view);
  const url = items.length > 1 ? "/checkout/basket" : "/checkout/user"

  return (
    <Button
      type="link"
      to={url}
      color="secondary"
      size={size}
      fullwidth={fullwidth}
      onClick={() => {
        window.scroll({ top: 0 });
      }}
    >
      {children}
    </Button>
  )
}

export default CheckoutButton;
