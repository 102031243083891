import styled, { css } from "styled-components";


export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  min-height: 100vh;
  overflow-y: scroll;
  z-index: 1;
  /* background: ${({ theme }) => theme.background.dark}aa; */
  background-color: rgba(0, 0, 0, 0.5);
`;

export const Anchor = styled.div`
  ${props => props.table && css`
    display: table;
    width: 100%;
    height: 100%;
    line-height: 0;
  `}
  ${props => props.cell && css`
    display: table-cell;
    vertical-align: top;
    text-align: center;
    line-height: 0;
    padding: 20px;
  `}

  ${props => props.place && css`
    position: relative;
    margin: 0 auto;
    margin-top: 70px;
    max-width: 320px;
  `}
  
`;

export const Container = styled.div`
  padding: 16px;
  font-size: 16px;
  line-height: 21px;
`;

export const Close = styled.div`
  position: absolute;
  top: 1px;
  right: -2px;
`;

const containerCut = 4;

export const ButtonPlacement = styled.button`
  position: fixed;
  bottom: 16px;
  right: 16px;
  z-index: 1000;
  padding: 10px 6px 8px;
  margin: 0;
  border: 0;
  background: ${({ theme }) => theme.background.paper};
  /* box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.3); */
  display: none;

  @media ${({ theme }) => theme.device.mobile}{
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
    display: block;
  }

  svg{
    fill: ${({ theme }) => theme.primary.main};
  }

  &::before {
      content: '';
      position: absolute;
      top: 0;
      right: -${containerCut}px;
      bottom: 0;

      border-top: ${containerCut}px solid transparent;
      border-left: ${containerCut}px solid ${({ theme }) => theme.background.paper};
      border-bottom: ${containerCut}px solid transparent;
    }
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: -${containerCut}px;
      bottom: 0;
      
      border-top: ${containerCut}px solid transparent;
      border-right: ${containerCut}px solid ${({ theme }) => theme.background.paper};
      border-bottom: ${containerCut}px solid transparent;
      z-index: 1;
    } 
`
