import {
  createStore,
  compose,
  applyMiddleware,
  combineReducers,
} from 'redux';
import thunk from 'redux-thunk';
import { appReducer } from './components/app/redux';
import { authReducer } from './components/auth/redux';
import { userReducer } from './components/user/redux';
import { ecommerceReducer } from './components/ecommerce/redux';


const composeEnhances = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const middleware = [thunk];

const rootReducer = combineReducers({
  app: appReducer,
  auth: authReducer,
  user: userReducer,
  ecommerce: ecommerceReducer,
});

const initialState = {

};

const store = createStore(
  rootReducer,
  initialState,
  composeEnhances(
    applyMiddleware(...middleware),
  ),
);

export default store;
