import styled, { css } from "styled-components";

export const Stepper = styled.div`
  padding: 32px 16px;
  display: flex;
  flex-direction: row;
`;



export const StepDivide = styled.div`
  flex-basis: auto;
  flex-shrink: 1;
  flex-grow: 1;
  position: relative;
  
  &::after{
    position: absolute;
    content: ' ';
    border-top: 1px solid rgba(0, 0, 0, 0.08);
    top: 50%;
    left: 5px;
    right: 5px;
  }
`;

export const StepIcon = styled.div`
  display: inline-block;
  width: 22px;
  height: 22px;
  text-align: center;
  line-height: 22px;
  border-radius: 50%;
  background: ${({ theme }) => theme.background.paper};
  font-size: 12px;
  font-family: 'QuanticoBold', sans-serif;
  color: ${({ theme }) => theme.primary.main};

  ${props => props.state === 'completed' && css`
  `}
`;

export const StepLabel = styled.div`
  display: inline-block;
  text-transform: uppercase;
  padding: 0 4px;
  font-size: 14px;
  font-family: 'Quantico', sans-serif;
  color: ${({ theme }) => theme.text.disabled};

  @media ${({ theme }) => theme.device.phone}{
    display: none;
  }
`;



export const Step = styled.button`
  display: flex;
  align-items: center;
  border: 0;
  margin: 0;
  padding: 0;
  background: transparent;

  &:hover{
    /* ${StepIcon} {
      background-color: ${({ theme }) => theme.thirdiary.main};
    }
    ${StepLabel} {
      color: ${({ theme }) => theme.text.primary};
    } */
  }

  ${props => props.state === "active" && css`
    ${StepIcon}{
      background-color: ${({ theme }) => theme.primary.main};
      color: white;
    }

    ${StepLabel}{
      color: ${({ theme }) => theme.text.secondary};
    }
  `}

  ${props => props.state === "completed" && css`
    
    ${StepIcon}{
      background-color: ${({ theme }) => theme.primary.main};
      color: white;
    }

    ${StepLabel}{
      color: ${({ theme }) => theme.text.secondary};
    }
  `}

  ${props => props.state === "default" && css`
    /* border: 1px solid blue; */
    
    ${StepLabel}{
      color: ${({ theme }) => theme.primary.main};
    }
  `}

  ${props => props.state === "inactive" && css`
    cursor: default;
    ${StepIcon}{
      background-color: ${({ theme }) => theme.shades.line};
      color: white;
    }

    ${StepLabel}{
      color: ${({ theme }) => theme.text.disabled};
    }
  `}
`;