import React from 'react';
import {
  Stepper,
  Step,
  StepDivide,
  StepIcon,
  StepLabel,
} from './step-progress.styled';
import {
  STEPS
} from './step-progress';



const StepSuccess = () => {
  const list = [];
  const listIcon = [];
  let nr = 1;

  const stepCount = Object.keys(STEPS).length;

  Object.entries(STEPS).forEach((element, index) => {
    list.push(element[1]);
    listIcon.push(nr);
    nr++;

    if (index < stepCount - 1) {
      list.push('--');
      listIcon.push('--');
    }
  });

  return (
    <Stepper>
      {list.map((element, key) => (
        (element === '--')
          ? <StepDivide key={key} />
          : (
            <Step
              key={element}
              state="completed"
            >
              <StepIcon state="completed">
                {listIcon[key]}
              </StepIcon>
              <StepLabel state="completed">{element}</StepLabel>
            </Step>
          )
      ))}
    </Stepper>
  );
}

export default StepSuccess;
